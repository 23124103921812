import React, { Component } from 'react';

//Custom compoonents
import { Chat } from './Chat';
import { ModalDialog } from '../Utilities/ModalDialog'
import { TextInput } from '../Utilities/TextInput';
import { UserAvatar } from '../Utilities/UserAvatar';
import { Input } from '../Utilities/Input';

//Material UI
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';
import AvatarGroup from '@mui/material/AvatarGroup';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemButton from '@mui/material/ListItemButton';
import DeleteIcon from '@mui/icons-material/Delete';
import PeopleIcon from '@mui/icons-material/People';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import Badge from '@mui/material/Badge';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

const arrayToList = (array) => {
    return array
        .join(", ")
        .replace(/, ((?:.(?!, ))+)$/, ' and $1');
}

export class ChatDrawer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            threads : [],
            avatars: [],
            loading: true,
            title: this.props.title ?? "Conversations",
            selectedThread: null,
            showConfirmDeleteThread: false,
            searchResults: [],
            newMessageRecipients: []
        }   
    }

    componentDidMount() {
        this.getThreads();
    }
    getThreads = async () => {
        const response = await fetch('messenger/getuserthreads');
        if (response.status !== 200) {
            this.props.setToken('');
            this.setState({ threads: [], loading: false });
        }
        else {
            const data = await response.json();
            this.setState({ threads: data, loading: false });
        }
    }

    //event handlers
    handleDeleteThread = (item, idx) => {
        this.setState({
            selectedThreadID: item.id,
            selectedThreadIDX: idx,
            showConfirmDeleteThread: true
        })
    }
    handleConfirmDeleteThread = async () => {

        this.setState(
            state => {
                state.threads.splice(this.state.selectedThreadIDX, 1);
                return state;
            }
        );

        this.setState({
            selectedThreadID: null,
            selectedThreadIDX: null,
            showConfirmDeleteThread: false
        });

        const req = { ThreadID: this.state.selectedThreadID };
        await fetch('messenger/removethread', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(req)
        });


    }
    handleSelectThread = (item, names) => {
        this.setState({
            selectedThread: item.id,
            title: "Chat with " + names
        });
    }
    handleUnSelectThread = () => {
        this.setState({
            selectedThread: null,
            title: "Conversations"
        });
    }
    validateMember = async (name) => {
        if (!this.state.validating) {
            this.setState({ validating: true });
            if (name) {
                const response = await fetch('messenger/searchusersbyname?name=' + name)
                const searchResults = await response.json();
                if (searchResults && searchResults.length > 0) {
                    this.setState({ searchResults: searchResults });
                    for (var i = 0; i < searchResults.length; i++) {
                        let avatar = await this.getUserInfo(searchResults[i].userID);
                        if (avatar) {
                            try {
                                //this.setState(
                                //    state => {
                                //        state.searchResults[i].avatarB64 = avatar;
                                //        return state;
                                //    }
                                //);
                            }
                            catch {
                                console.log("error setting avatar image");
                            }
                        }

                    }
                }
                else {
                    this.setState({ searchResults: [] });
                }
            }
            else {
                this.setState({ searchResults: [] });
            }
            this.setState({ validating: false });
        }

    }
    handleKeyPress2 = async (event) => {
        if (event.keyCode === 9) {
            event.preventDefault();
        }
        if (event.key === 'Enter' || event.keyCode === 9) {
            const name = event.target.value;
            if (this.validateEmail(name)) {
                const user = await this.checkIfUserExists(name);
                if (user) {
                    this.addExistingUser(user);
                }

            }
        }
    }
    addExistingUser = (user) => {
        this.setState(
            state => {
                const newMessageRecipients = state.newMessageRecipients.concat(user);
                return { newMessageRecipients, searchResults: [] };
            }
        );
        document.getElementById("fieldStart typing name...").value = "";
        document.getElementById("fieldStart typing name...").focus();
    }
    deleteNewRecipientHandler = (idx) => {
        this.setState(state => {
            const newMessageRecipients = state.newMessageRecipients.filter((item, j) => idx !== j);
            return { newMessageRecipients, };
        });
    }
    getUserInfo = async (id) => {
        if (id) {
            const response = await fetch('home/getuseravatar?userid=' + id);
            const userinfo = await response.json();
            if (userinfo) {
                return userinfo.avatarB64;
            }
        }
        return null;
    }
    handleSelectMessageRecipient = async (idx) => {
        const user = this.state.searchResults[idx];
        this.addExistingUser(user);
    }
    handleStartThread = async () => {
        const req = { Title: "New Conversation" };
        const response = await fetch('messenger/startthread', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(req)
        });
        if (response.status === 200) {
            const threadID = await response.json();
            const newThread = { id: threadID, createDate: new Date(), lastMessageDate: "Just now", members: this.state.newMessageRecipients, messages: [] };
            this.setState(
                state => {
                    state.threads.unshift(newThread);
                    return state;
                }
            );



            let members = [];
            if (this.state.newMessageRecipients && this.state.newMessageRecipients.length > 0) {
                for (var i = 0; i < this.state.newMessageRecipients.length; i++) {
                    members.push(this.state.newMessageRecipients[i].userID);
                }
                const req2 = { ThreadID: threadID, Users: members };
                const response2 = await fetch('messenger/addthreadusers', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(req2)
                });
                if (response2.status === 200) {
                    this.setState({
                        newMessageRecipients: [],
                        selectedThread: threadID,
                        title: "New Conversation"
                    });
                }
            }



        }
    }

    //render funcs
    title = () => {
        return (
            <Toolbar disableGutters variant="dense" sx={{ background: (theme) => this.props.dark ? "#0a0a0a" : theme.palette.secondary.main}}>
                {this.state.selectedThread &&
                    <Tooltip title="All Conversations">
                        <IconButton sx={{ color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.dimwhite, margin: "0px" }} aria-label="back" onClick={this.handleUnSelectThread} >
                            <ArrowBackIosNewIcon />
                        </IconButton>
                    </Tooltip>
                }
                <Box sx={{ display: { xs: 'flex' }, padding: "0px 16px" }} >
                    <Typography variant="h6" sx={{
                        fontWeight: 400,
                        padding: "8px 0px",
                        color: theme => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.white,
                        display: "inline-block"
                    }}>{this.state.title}</Typography>
                </Box>
                <Box sx={{ flexGrow: 1 }} />

                <Tooltip title="Hide Chat">
                    <IconButton size="small" sx={{ color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.white, margin: "0px" }} aria-label="back" onClick={this.props.toggleOpen} >
                        <CloseIcon />
                    </IconButton>
                </Tooltip>
            </Toolbar>
        )
    }
    avatar = (user, itemid, idx) => {
        return (
            /* <Avatar key={"u" + user.userID + itemid + idx} className="avatarImageSmall" alt={user.firstName} src={"data:image/png;base64, " + user.avatarB64} />*/
            <UserAvatar
                key={itemid + user.userID}
                id={"u" + user.userID + itemid + idx}
                user={user}
                dark={this.props.dark}
                loaded={true}
                activeUser={this.props.user}
                appIdentifier={this.props.appIdentifier}
                hideName
                small
            />
        )
    }
    thread = (item, index) => {
        let nameArray = [];
        for (var i = 0; i < item.members.length; i++) {
            nameArray.push(item.members[i].firstName);
        }
        const names = arrayToList(nameArray);

        return (
            <ListItem
                key={"m" + index}
                alignItems="flex-start"
                disableGutters={this.props.width <= 600 ? true : false }
                secondaryAction={
                    <Tooltip title="Delete">
                        <IconButton
                            size="small"
                            edge="end"
                            sx={{
                                fontSize: "1.3rem",
                                color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.dimblack,
                                mr:1
                            }}
                            aria-label="delete"
                            onClick={() => this.handleDeleteThread(item, index)}
                        >
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                }
            >
                <ListItemButton onClick={() => this.handleSelectThread(item, names)}>
                    <ListItemAvatar sx={{ mr:.5} }>
                        <Badge anchorOrigin={{ horizontal: 'left', vertical: 'top' }} color="secondary" variant="dot" invisible={!item.newMessages}>
                            <AvatarGroup max={3} sx={{ minWidth: "50px", justifyContent: "flex-end" }}>
                                {item.members && item.members.map((user, index) => { return this.avatar(user, item.id, index) })}
                            </AvatarGroup>
                        </Badge>
                    </ListItemAvatar>
                    <ListItemText
                        primary={names}
                        secondary={
                            <React.Fragment>
                                <Typography
                                    sx={{ fontSize: this.props.width <= 600 ? ".7rem" : ".8rem", paddingRight: "16px", display: 'inline', color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.dimblack }}
                                    component="span"
                                    variant="body2"
                                >
                                    {item.lastMessageDate ? item.lastMessageDate : new Date(item.createDate).toLocaleDateString()}
                                </Typography>
                                <Typography
                                    sx={{ fontSize: this.props.width <= 600 ? ".8rem" : ".9rem", fontWeight:600,display: 'inline', color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.dimblack }}
                                    component="span"
                                    variant="body2"
                                >
                                    {(item.snippet !== "null" && item.snippet !== null) ? item.snippet : "..."}
                                </Typography>
                            </React.Fragment>
                        }
                    />
                </ListItemButton>

            </ListItem>
        )
    }
    threads = () => {
        if (this.state.loading) {
            return (
                <Box sx={{ display: 'flex', padding: "16px" }}>
                    <CircularProgress size={24} color="secondary" />
                    <Typography variant="body2" sx={{
                        fontWeight: 400,
                        padding: "0px 0px",
                        marginLeft: "12px",
                        color: theme => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.dimblack,
                    }}>Loading Conversations...</Typography>
                </Box>
            )
        }
        else {
            if (this.state.threads && this.state.threads.length > 0) {
                return (
                    <Box
                        className="prettyScroll"
                        sx={{
                            textAlign: 'left',
                            color: theme => this.props.dark ? theme.palette.common.white : theme.palette.common.black,
                            backgroundColor: "transparent",
                            height: this.props.scrollHeight ?? { xs: "calc(100vh - 241px)", sm: "calc(100vh - 249px)", lg: "calc(100vh - 193px)" },
                            width: { xs: "100%", md: "400px", lg: "500px" },
                        }}
                    >
                        <List>
                            {this.state.threads.map((item, index) => { return this.thread(item, index) })}
                        </List>
                    </Box>
                )
            }
            return null;
        }
    }
    chatWindow = () => {
        return (

                <Chat {...this.props} setToken={this.props.setToken} dark={this.props.dark} sub={true} handleClose={this.props.toggleOpen} threadID={this.state.selectedThread} />
        )
    }
    threadDeleteConfirmation = () => {
        return (
            <ModalDialog
                dark={this.props.dark}
                show={this.state.showConfirmDeleteThread}
                title="Confirm Deletion"
                subTitle={"Message thread will be deleted.  Proceed?"}
                handleClose={() => this.setState({ showConfirmDeleteThread: false })}
                handleSave={this.handleConfirmDeleteThread}
                fullWidth={false}
                maxWidth="sm"
                saveLabel="Delete"
            />
        )
    }
    invitee = (user, idx) => {
        return (
            <Box sx={{ marginRight: "6px", marginTop: "6px" }}>
                <Tooltip title={user.firstName + " " + user.lastName}>
                    <>
                        <Box sx={{ display: { xs: "none", md: "block" } }}>
                            <Chip
                                style={{ marginBottom: "auto", marginTop: "auto" }}
                                color="secondary"
                                label={user.firstName}
                                onDelete={() => this.deleteNewRecipientHandler(idx)}
                                avatar={
                                    <UserAvatar
                                        id={"inv" + user.userID + idx}
                                        user={user}
                                        dark={this.props.dark}
                                        loaded={true}
                                        activeUser={this.props.user}
                                        appIdentifier={this.props.appIdentifier}
                                        hideName
                                        tiny
                                    />
                                }
                            />
                        </Box>
                        <Box sx={{ display: { xs: "block", md: "none" } }}>
                            <Chip
                                style={{ marginBottom: "auto", marginTop: "auto" }}
                                color="secondary"
                                size="small"
                                label={user.firstName + ' ' + user.lastName}
                                onDelete={() => this.deleteNewRecipientHandler(idx)}
                            />
                        </Box>
                    </>

                </Tooltip>
            </Box>
        )
    }
    inviteeList = () => {
        return (
            <Box display="flex">
                <Box display="flex" style={{ maxWidth: "100%", flexWrap: "wrap", p: 1 }}>
                    {this.state.newMessageRecipients.map((user, index) => {
                        return this.invitee(user, index)
                    })}
                </Box>
                <Box sx={{ flexGrow: 1 }} />
                {(this.state.newMessageRecipients && this.state.newMessageRecipients.length > 0) &&
                    <Button size="small" onClick={this.handleStartThread} sx={{ ml: 1, color: (theme) => this.props.dark ? theme.palette.common.white : theme.palette.common.dimblack }}>Start Chat</Button>                   
                }
            </Box>
        )
    }
    recipientSelector = () => {
        return (
            <Box
                key="composer"
                sx={{ background: (theme) => this.props.dark ? theme.palette.component.dark : theme.palette.component.light, p:1, width: { xs: "100%", md: "500px", lg: "600px" }, borderBottom: this.props.dark ? "solid 1px #333" : "solid 1px #bbb",  }}
            >
                <Box display="flex" sx={{ height:"64px",width: "100%", alignItems: "center" }}>
                    <PeopleIcon sx={{ color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.dimblack, marginRight: "16px" }} />
                    {/*<TextInput {...this.props}*/}
                    {/*    cKey="CnewThreadMember"*/}
                    {/*    cid="CnewThreadMember"*/}
                    {/*    id="newThreadMember"*/}
                    {/*    label="Start typing name..."*/}
                    {/*    defaultValue=""*/}
                    {/*    onChange={(e) => this.validateMember(e.target.value)}*/}
                    {/*    onKeyPress={this.handleKeyPress2}*/}
                    {/*/>*/}
                    <Input
                        key="CnewThreadMember"
                        type="text"
                        dark={this.props.dark}
                        inputID="newThreadMember"
                        label="Start typing name..."
                        value=""
                        defaultValue=""
                        onChange={(e) => this.validateMember(e)}
                        onKeyPress={this.handleKeyPress2}
                    />
                </Box>
                {(this.state.newMessageRecipients && this.state.newMessageRecipients.length > 0) && this.inviteeList()}
            </Box>)
    }
    searchResults = () => {
        if (this.state.searchResults && this.state.searchResults.length > 0) {
            return (
                <Box sx={{
                    background: (theme) => this.props.dark ? theme.palette.component.dark : theme.palette.component.light,
                    color: (theme) => this.props.dark ? theme.palette.common.white : theme.palette.common.black,
                    padding: "6px, 16px",
                    maxHeight: "400px",
                    borderBottom: !this.props.dark && "1px solid #ccc",
                    boxShadow: !this.props.dark && 8
                }}
                    className="prettyScroll" >
                    <List>
                        {this.state.searchResults.map((user, index) => {
                            return (
                                <ListItem
                                    key={user.userID}
                                >
                                    <ListItemButton onClick={() => this.handleSelectMessageRecipient(index)} >
                                        <ListItemAvatar>
                                            <UserAvatar
                                                id={"search" + user.userID + index}
                                                user={user}
                                                dark={this.props.dark}
                                                loaded={true}
                                                activeUser={this.props.user}
                                                appIdentifier={this.props.appIdentifier}
                                                hideName
                                                small
                                            />
                                        </ListItemAvatar>
                                        <ListItemText primary={user.firstName + " " + user.lastName} />
                                    </ListItemButton>
                                </ListItem>
                            )
                        })}
                    </List>
                </Box>
            )
        }
        return null;
    }
    content = () => {
        return (
            <Drawer
                open={this.props.show}
                transitionDuration={500}
                anchor="right"
                variant="persistent"
                onClose={this.props.toggleOpen}
                ModalProps={{ keepMounted: true, }}
                PaperProps={{
                    elevation:this.props.dark ? 0 : 8,
                    sx: {
                        width: { xs: "100%", md: "400px", lg: "500px" },
                        background:  this.props.dark ? "#090909" : "#fff" ,
                        color: (theme) => this.props.dark ? theme.palette.common.white : theme.palette.common.black, overflowX: "hidden",
                        border: "none"
                    },
           
                }}


            >
                <Toolbar />
                {this.title()}
                {!this.state.selectedThread
                    ?
                    <>
                        {this.recipientSelector()}
                        {this.searchResults()}
                        {this.threads()}
                    </>

                    :
                    this.chatWindow()
                }
                {this.threadDeleteConfirmation()}
            </Drawer>
        );
    }

    //main compontent render
    render() {
        return this.content();
    }
}
