import React, { Component } from 'react';

//Custom Components
import { ClientDocuments } from './ClientDocuments';
import { SiteImageUploader } from '../Families/SiteImageUploader';
import { Spin } from '../Utilities/Spin'
import { Section } from '../Utilities/Section';
import { SectionItem } from '../Utilities/SectionItem';
import { TextInput } from '../Utilities/TextInput';
import { SectionFieldGroup } from '../Utilities/SectionFieldGroup';
import { Invitation } from '../Invitations/Invitation';
import { ModalDialog } from '../Utilities/ModalDialog'
import { SelectList } from '../Utilities/SelectList';
import { DocumentViewer } from '../Content/Documents/DocumentViewer';
import { UserAvatar } from '../Utilities/UserAvatar'
import { ContactFormData, contactFormData } from './ContactFormData';

//MaterialUI
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Typography from '@mui/material/Typography';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import AddIcon from '@mui/icons-material/Add';
import Tooltip from '@mui/material/Tooltip';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { DataGrid, GridToolbar, GridToolbarContainer } from '@mui/x-data-grid';

//Database Services
import { getBusiness, editBusiness, getClients, getClientUsers, getClientUserInvites, getAdminUserInvites, newClient, editClient, deleteClient, getAllClientDocuments, getAdminUsers, deleteAdminUser } from '../../DataServices/BusinessService'
import { formatPhoneNumberInline, handleNumbersOnly, isValidURL } from '../../DataServices/Utility'
import { removeInvitation } from '../../DataServices/OrganizationService'
import { getDocument } from '../../DataServices/DocumentService'
import { getContactFormData, getContactNotificationRecipients, addContactNotificationRecipient, removeContactNotificationRecipient } from '../../DataServices/WebPageService'
//Helper functions
const a11yProps = (index, id) => {
    return {
        id: `simple-tab-${index}-${id}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            style={{ minWidth: "130px" }}
            {...other}
        >
            {value === index && children}
        </div>
    );
}
function remove(item, array) {
    var new_array = [];
    new_array = array.filter((ar) => ar != item);
    return new_array;
}

export class BusinessEditor extends Component {

  constructor(props) {
      super(props);
      this.state = {
          anchorEl: null,
          businessID: this.props.businessID,
          business: null,
          showNewClient: false,
          showInvitation: false,
          showEditProps: false,
          loading: true,
          loadingMessage: this.props.businessName ? `Loading data for ${this.props.businessName}. This make take a moment...` : "Loading Business Data.  This may take a moment...",
          showProfileImageUploader: false,
          showCoverImageUploader: false,
          showConfirmDeleteMember: false,
          showConfirmDeleteInvited: false,
          showBusinessMenu: false,
          selectedClient: null,
          showClientUserInvitation: false,
          tabValue: 0,
          tabValueClient: 0,
          tabPanelState: { docs: 1 },
          showDocument: false,
          showConfirmDeleteClient: false,
          selectedDocument: null,
          clientDocs: [],
          showAdminUserInvitation: false,
          showConfirmDeleteAdminUser: false,
          showConfirmDeleteInvitedAdminUser: false,
          showConfirmDeleteNotificationRecipient: false,
          showNewNotificationRecipient: false
      };
  }

    componentDidMount() {
        this.getData();
        this.getAdminUsers();
        this.getContactFormData();
        this.updateAdminUserInviteList();
    }

    getData = async () => {
        const data = await getBusiness(this.props.businessID);
        if (data && data.business) {
            if (!data.business.clients) {
                data.business.clients = [];
            }

            this.setState({
                business: data.business,
                typeValue: data.business.businessTypeID,
                stateValue: data.business.state && this.props.dropDownLists.states.find((x) => x.label === data.business.state).value,
            });

            this.getClients();
            await this.getClientDocs(data.business.appID);
            await this.getContactNotificationRecipients(data.business.appID);
            this.props.editorLoaded();
        }
        else {
            this.setState({
                business: null,
                loading: false,
                typeValue: null,
                stateValue: null
            });
        }


    }
    getContactFormData = async(appID) => {
        const contactFormData = await getContactFormData(appID, 0);
        if (contactFormData) {
            this.setState({ contactFormData });
        }
    }
    getContactNotificationRecipients = async (appID) => {
        const notificationRecipients = await getContactNotificationRecipients(appID);
        if (notificationRecipients) {
            this.setState({ notificationRecipients });
        }
    }
    getContactNotificationRecipients
    getClients = async () => {
        const clients = await getClients(this.props.businessID);
        if (clients) {
            this.setState(
                state => {
                    let business = state.business;
                    const clts = business.clients.concat(clients);
                    business.clients = clts;
                    return {
                        business,
                    };
                }
            );

            this.setState({ loading: false });
        }

    }
    getClientDocs = async (appID) => {

        const clientDocs = await getAllClientDocuments(this.props.businessID, appID);
        if (clientDocs) {
            this.setState({ clientDocs });

        }
        this.setState({ loading: false });
    }
    getAdminUsers = async () => {
        const adminUsers = await getAdminUsers(this.props.businessID);
        if (adminUsers) {
            this.setState({ adminUsers });
        }
    }

    //Event Handlers-----------------
    handleTabChange = (event, newValue) => {
        this.setState({ tabValue: newValue })
    }
    handleTabChangeClient = (event, newValue) => {
        this.setState({ tabValueClient: newValue })
    }
    handleShowInvitation = () =>  {
        this.setState({ showInvitation: true });
    }
    handleSaveEditProperties = async (business) => {  }
    handleEditMember = () => { }
    handleShowConfirmDeleteMember = (id, idx) => {
        this.setState({
            selectedMemberID: id,
            selectedMemberIDX: idx,
            showConfirmDeleteMember: true
        });
    }
    handleEditInvitedMember = () => { }
    handleShowConfirmDeleteInvitedMember = (id, idx) => {
        this.setState({
            selectedInvitedID: id,
            selectedInvitedIDX: idx,
            showConfirmDeleteInvited: true
        });
    }
    handleShowConfirmDeleteNotificationRecipient = (id, idx) => {
        this.setState({
            selectedRecipientID: id,
            selectedRecipientIDX: idx,
            showConfirmDeleteNotificationRecipient: true
        });
    }
    handleUpdateProfileImage = () => { }
    handleUpdateCoverImage = () => { }

    //Business Edit
    handleNameBlur = (e) => {
        const val = e.target.value;
        let business = this.state.business;
        business.businessName = val;
        this.setState({ business });
        this.handleEditBusiness(business);
    }
    handleDescBlur = (e) => {
        const val = e.target.value;
        let business = this.state.business;
        business.description = val;
        this.setState({ business });
        this.handleEditBusiness(business);
    }
    handleTypeChange = (e) => {
        let business = this.state.business;
        business.businessTypeID = e.target.value;
        this.setState({ business });
        this.handleEditBusiness(business);
    }
    handleWebsiteBlur = (e) => {
        const val = e.target.value;
        if (val) {
            if (!isValidURL(val)) {
                this.setState({ InputURLError: true });
            }
            else {
                this.setState({ InputURLError: false });
                let business = this.state.business;
                business.website = val;
                this.setState({ business });
                this.handleEditBusiness(business);
            }
        }
        else {
            this.setState({ InputURLError: false });
            let business = this.state.business;
            business.website = val;
            this.setState({ business });
            this.handleEditBusiness(business);
        }
    }
    handlePhoneChange = (e) => {
        const formattedVal = formatPhoneNumberInline(e.target.value);
        e.target.value = formattedVal;
        this.setState(
            state => {
                let business = state.business;
                business.mainPhone = formattedVal.replace(/\D/g, '');;
                return {
                    business,
                };
            }
        );
    }
    handlePhoneBlur = (e) => {
        this.handleEditBusiness(this.state.business);
    }
    handleStreetBlur = (e) => {
        const val = e.target.value;
        let business = this.state.business;
        business.streetAddress = val;
        this.setState({ business });
        this.handleEditBusiness(business);
    }
    handleCityBlur = (e) => {
        const val = e.target.value;
        let business = this.state.business;
        business.city = val;
        this.setState({ business });
        this.handleEditBusiness(business);
    }
    handleStateChange = (e) => {
        const item = this.props.dropDownLists.states.find((x) => x.value === e.target.value);
        if (item) {
            const st = item.label;
            let business = this.state.business;
            business.state = st;
            this.setState({ business });
            this.handleEditBusiness(business);
        }
    }
    handleZipBlur = (e) => {
        const val = e.target.value;
        let business = this.state.business;
        business.zip = val;
        this.setState({ business });
        this.handleEditBusiness(business);
    }
    handleEditBusiness = async (biz) => {
        if(!biz)
            biz = this.state.business;
        console.log(biz);

        await editBusiness(
            biz.id,
            biz.businessName,
            biz.description,
            biz.businessTypeID,
            biz.streetAddress,
            biz.city,
            biz.state,
            biz.zip,
            biz.mainPhone,
            biz.website
        );
    }
    handleShowEditClient = () => {
        const id = this.state.selectedClientID;

        this.setState({
            anchorEl: null,
            showNewClient: true,
            mode: "edit",
            nameValue: this.state.business.clients.find((x) => x.id === id).name,
            altIdValue: this.state.business.clients.find((x) => x.id === id).clientAltID,
        });
    }
    handleShowNewClient = () => {
        this.setState({ showNewClient: true, mode: "new" });

    }
    handleSaveNewClient = async () => {
        const ele = document.getElementById("fldClientName");
        if (!ele)
            return;
        const name = ele.value;
        ele.value = "";
        const ele2 = document.getElementById("fldClientAltID");
        if (!ele2)
            return;
        const altid = ele2.value;
        ele2.value = "";

        const newClt = await newClient(this.state.business.id, name, altid);
        if (newClt) {
            this.setState(
                state => {
                    let business = state.business;
                    const clients = business.clients.concat(newClt);
                    business.clients = clients;

                    return {
                        business,
                        showNewClient: false
                    };
                }
            );
        }
    }
    handleSaveNotificationRecipient = async () => {
        const ele = document.getElementById("fldNewRecipient");
        if (!ele)
            return;
        const email = ele.value;
        ele.value = "";

        const newClt = await addContactNotificationRecipient(this.state.business.appID, email);
        if (newClt) {
            const notificationRecipients = [...this.state.notificationRecipients];
            notificationRecipients.push({ id: newClt, appID: this.state.business.appID, email: email });
            this.setState({ notificationRecipients, showNewNotificationRecipient: false });
        }
    }
    handleSaveEditClient = async () => {
        const ele = document.getElementById("fldClientName");
        if (!ele)
            return;
        const name = ele.value;
        ele.value = "";
        const ele2 = document.getElementById("fldClientAltID");
        if (!ele2)
            return;
        const altid = ele2.value;
        ele2.value = "";

        const newClt = await editClient(this.state.selectedClientID, name, altid);
        if (newClt) {
            this.setState(
                state => {
                    let business = state.business;
                    business.clients.find((x) => x.id === this.state.selectedClientID).name = name;
                    business.clients.find((x) => x.id === this.state.selectedClientID).clientAltID = altid;
                    return {
                        business,
                        showNewClient: false
                    };
                }
            );
        }
    }
    handleDeleteClient = async () => {
        const id = this.state.selectedClientID;
        this.setState({ showConfirmDeleteClient: false });
        await deleteClient(id);
        this.setState(
            state => {
                let business = state.business;
                let clients = business.clients;
                const client = clients.find((x) => x.id === this.state.selectedClientID);
                clients = remove(client, clients);
                business.clients = clients;

                return {
                    business,
                };
            }
        );

    }
    handleDeleteNotificationRecipient = async () => {
        const id = this.state.selectedRecipientID;
        this.setState({ showConfirmDeleteNotificationRecipient: false });
        await removeContactNotificationRecipient(id);
        const notificationRecipients = [...this.state.notificationRecipients];
        notificationRecipients.splice(this.state.selectedRecipientIDX, 1);
        this.setState({ notificationRecipients });

    }
    handleSelection = (selection) => {

        this.setState({
            activeSection: selection,
            showBusinessMenu: false
        });

    }
    handleEditClientUser = (id) => {

    }
    handleShowNewClientContact = () => {
        this.setState({ showClientUserInvitation: true });
    }
    handleShowConfirmDeleteClientUser = (id, idx) => {

    }
    updateClientUserInviteList = async () => {
        this.setState({ showClientUserInvitation: false });
        const inv = await getClientUserInvites(this.state.selectedClient.id);
        this.setState(state => {
            let selectedClient = state.selectedClient;
            selectedClient.invitations = inv;
            return { selectedClient };

        })

    }
    handleClientUsers = async  (item, idx) => {

        this.setState({
            selectedClient: item,
            selectedClientIDX: idx,
            selectedClientItem: "users"
        });

        const inv = await getClientUserInvites(item.id);

        this.setState(state => {
            let selectedClient = state.selectedClient;
            selectedClient.invitations = inv;
            return { selectedClient };

        })

        const usrs = await getClientUsers(item.id);
        this.setState(state => {
            let selectedClient = state.selectedClient;
            selectedClient.users = usrs;
            return { selectedClient };

        })
    }
    handleClientFiles = (item, idx) => {
        this.setState({
            selectedClient: item,
            selectedClientIDX: idx,
            selectedClientItem: "files"
        })
    }
    handleClientNotes = (item, idx) => {
        this.setState({
            selectedClient: item,
            selectedClientIDX: idx,
            selectedClientItem: "notes"
        })
    }
    handleClientChat = (item, idx) => {
        this.setState({
            selectedClient: item,
            selectedClientIDX: idx,
            selectedClientItem: "chat"
        })
    }
    handleSelectClient = async (e) => {
        console.log(e);
        const inv = await getClientUserInvites(e.id);
        const usrs = await getClientUsers(e.id);
        const client = this.state.business.clients.find((x) => x.id === e.id);
        setTimeout(
            this.setState(state => {
                let selectedClient = client;
                selectedClient.invitations = inv;
                selectedClient.users = usrs;
                return { selectedClient };
            }), 100);
    }
    handleBackToClientList = () => {
        this.setState({ selectedClient: null });
    }
    handleShowConfirmDeleteClientUser = (id, idx) => {

    }
    handleEditInvitedClientUser = () => {

    }
    handleSelectClientDoc = async (e) => {
        this.setState({ loading: true, loadingMessage: e.row.documentName ? "Loading " + e.row.documentName :  "Loading Document..." });
        const doc = await getDocument(e.id);
        if (doc && doc.eFiles && doc.eFiles.length > 0 && doc.eFiles[0].fileContent) {
            this.setState({
                selectedDocumentName: doc.documentName,
                selectedDocumentContent: doc.eFiles[0].fileContent,
                showDocument: true,
                loading: false
            });
        }
    }
    handleShowConfirmDeleteClient = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({  anchorEl: null, showConfirmDeleteClient: true });
    }
    handleShowClientMenu = (e, params) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({  selectedClientID: params.id, anchorEl: e.currentTarget });
    }
    handleCloseClientMenu = (e) => {
        this.setState({ anchorEl: null });
    }
    handleShowNewAdminUser = () => {
        this.setState({ showAdminUserInvitation: true });
    }
    handleShowNewRecipient = () => {
        this.setState({ showNewNotificationRecipient: true });
    }
    handleShowConfirmDeleteAdminUser = (id, index) => {
        this.setState({
            selectedAdminUserID: id,
            selectedAdminUserIDX: index,
            showConfirmDeleteAdminUser: true
        })
    }
    handleDeleteAdminUser = async () => {
        const adminUsers = [...this.state.adminUsers];
        adminUsers.splice(this.state.selectedAdminUserIDX, 1);
        this.setState({ adminUsers, showConfirmDeleteAdminUser: false });

        await deleteAdminUser(this.state.selectedAdminUserID);
    }
    handleDeleteInvitedAdminUser = async () => {
        const invitedAdminUsers = [...this.state.invitedAdminUsers];
        invitedAdminUsers.splice(this.state.selectedInvitedAdminUserIDX, 1);
        this.setState({ invitedAdminUsers, showConfirmDeleteInvitedAdminUser: false });

        await removeInvitation(this.state.selectedInvitedAdminUserID);
    }
    handleShowConfirmDeleteInvitedAdminUser = (id, index) => {
        this.setState({
            selectedInvitedAdminUserID: id,
            selectedInvitedAdminUserIDX: index,
            showConfirmDeleteInvitedAdminUser: true
        })
    }
    updateAdminUserInviteList = async () => {
        this.setState({ showAdminUserInvitation: false });
        const invitedAdminUsers = await getAdminUserInvites(this.props.businessID);
        if (invitedAdminUsers) {
            this.setState({ invitedAdminUsers });
        }
    }

    //Render Funcs
    CustomToolbarClientList(ctx) {
        return (
            <GridToolbarContainer color="secondary">
                <Tooltip title="Create new client">
                    <IconButton size="small" color="secondary" key="newclient" onClick={ctx.handleShowNewClient} aria-label="newclient" sx={{ color: "white", marginLeft: "auto", margin: "8px" }}>
                        <AddIcon sx={{ color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.dimblack }} />
                    </IconButton>
                </Tooltip>
                <Box sx={{ flexGrow: 1 }} />
                <GridToolbar />
            </GridToolbarContainer>
        );
    }
    CustomToolbarClientDocList() {
        return (
            <GridToolbarContainer color="secondary">
                <Box sx={{ flexGrow: 1 }} />
                <GridToolbar />
            </GridToolbarContainer>
        );
    }
    TabContainer = () => {
        return (
            <Box sx={{ width: '100%', }} className={this.props.dark && "dark"} >
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs variant="scrollable" scrollButtons="auto" sx={{ minHeight: "36px", maxWidth: "fit-content", mx: "auto" }} allowScrollButtonsMobile indicatorColor="secondary" value={this.state.tabValue} onChange={this.handleTabChange} aria-label="content tabs">
                        <Tab label="Clients" {...a11yProps(0)} sx={{  fontSize: ".8rem", color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.dimblack }} />
                        <Tab label="All Client Documents" {...a11yProps(1)} sx={{  fontSize: ".8rem", color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.dimblack }} />
                        <Tab label="Business Setup" {...a11yProps(3)} sx={{ fontSize: ".8rem", color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.dimblack }} />
                        <Tab label="User Management" {...a11yProps(4)} sx={{ fontSize: ".8rem", color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.dimblack }} />
                        <Tab label="Website Messages" {...a11yProps(5)} sx={{ fontSize: ".8rem", color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.dimblack }} />
                    </Tabs>
                </Box>
                <TabPanel style={{ display: "contents" }} value={this.state.tabValue} index={0}>
                    <Box sx={{ width:"100%", display: { xs: "block", md: "none" } }}>
                        <Typography sx={{ m: "auto", px: 3, py: 1, color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.dimblack }}>Use larger device to manage clients</Typography>
                    </Box>
                    <Box sx={{ display: { xs: "none", md: "flex" } }}>
                        {this.state.selectedClient ? this.clientManager() : this.clientList()}
                    </Box>

                </TabPanel>
                <TabPanel style={{ display: "contents" }} value={this.state.tabValue} index={1}>
                    <Box sx={{ width: "100%", display: { xs: "block", md: "none" } }}>
                        <Typography sx={{ m: "auto", px: 3, py: 1, color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.dimblack }}>Use larger device to manage client documents</Typography>
                    </Box>
                    <Box sx={{ display: { xs: "none", md: "flex" } }}>
                        {this.clientDocList()}
                    </Box>
                </TabPanel>
                <TabPanel style={{ display: "contents" }} value={this.state.tabValue} index={2}>
                    {this.mainSettings()}
                    {this.secondarySettings()}
                    {this.notificationSettings() }
                </TabPanel>
                <TabPanel style={{ display: "contents" }} value={this.state.tabValue} index={3}>
                    {this.adminUsers()}
                </TabPanel>
                <TabPanel style={{ display: "contents" }} value={this.state.tabValue} index={4}>
                    {this.contactFormData()}
                </TabPanel>
            </Box>
        )
    }
    websiteMessages = () => {
        return (
            <Box sx={{ padding: "16px" }}>
                <SectionFieldGroup dark={this.props.dark}>

                </SectionFieldGroup>
            </Box>
        )
    }
    messageRow = (item, idx) => {
        return { id: item.id, Sent: new Date(item.createDate).toLocaleDateString(), Name: item.firstName + " " + item.lastName, email: item.email, comment: item.comment }
    }
    contactFormData = () => {
      
        if (!this.state.loading && this.state.business && this.state.business.appID) {

            return (
                <ContactFormData
                    {...this.props}
                    appID={this.state.business.appID}
                />

            )
        }
        return null;
    }
    mainSettings = () => {
        return (
            <Box sx={{ padding: "16px" }}>
                <SectionFieldGroup dark={this.props.dark}>
                    <TextInput
                        {...this.props}
                        cKey="CeditBusinessName"
                        cid="CeditBusinessName"
                        id="fldBusinessName"
                        label="Business Name"
                        defaultValue={this.state.business ? this.state.business.businessName : ""}
                        onBlur={this.handleNameBlur}
                    />
                    <TextInput
                        {...this.props}
                        cKey="CnewBusinessDesc"
                        cid="CnewBusinessDesc"
                        id="fldDesc"
                        label="Business Description"
                        defaultValue={this.state.business ? this.state.business.description : ""}
                        onBlur={this.handleDescBlur}
                    />
                    <SelectList
                        dark={this.props.dark}
                        ckey="etype"
                        label="Business Type"
                        value={this.state.business ? this.state.business.businessTypeID : -1}
                        onChange={this.handleTypeChange}
                        listItems={this.props.dropDownLists.businessTypes}
                    />
                    <TextInput
                        {...this.props}
                        cKey="CnewWebsite"
                        cid="CnewWebsite"
                        id="fldWebsite"
                        label="Website"
                        defaultValue={this.state.business ? this.state.business.website : ""}
                        onBlur={this.handleWebsiteBlur}
                    />
                    {this.state.InputURLError &&
                        <Alert severity="error">Not a valid web address</Alert>
                    }
                    <TextInput
                        dark={this.props.dark}
                        cKey="phoneNumber"
                        cid="phoneNumberF"
                        id="fldPhone"
                        label="Main Phone"
                        defaultValue={this.state.business ? formatPhoneNumberInline(this.state.business.mainPhone) : ""}
                        onChange={this.handlePhoneChange}
                        onBlur={this.handlePhoneBlur}
                    />
                </SectionFieldGroup>
            </Box>
        )
    }
    secondarySettings = () => {
        return (
            <Box sx={{ padding: "16px" }}>
                <SectionFieldGroup dark={this.props.dark}>
                    <TextInput
                        dark={this.props.dark}
                        cKey="street"
                        cid="streetF"
                        id="fldStreet"
                        label="Street Address"
                        defaultValue={this.state.business ? this.state.business.streetAddress : ""}
                        onBlur={this.handleStreetBlur}
                    />
                    <TextInput
                        dark={this.props.dark}
                        cKey="city"
                        cid="cityF"
                        id="fldCity"
                        label="City"
                        defaultValue={this.state.business ? this.state.business.city : ""}
                        onBlur={this.handleCityBlur}
                    />
                    <SelectList
                        dark={this.props.dark}
                        ckey="eState"
                        label="State"
                        value={this.props.dropDownLists.states.find((x) => x.label === this.state.business.state) ? this.props.dropDownLists.states.find((x) => x.label === this.state.business.state).value : -1 }
                        onChange={this.handleStateChange}
                        listItems={this.props.dropDownLists.states}
                    />
                    <TextInput
                        dark={this.props.dark}
                        cKey="zip"
                        cid="zipF"
                        id="fldZip"
                        label="Zip"
                        defaultValue={this.state.business ? this.state.business.zip : ""}
                        inputProps={{ id: "newZip", maxLength: 5, pattern: "[0-9]+" }}
                        onChange={handleNumbersOnly}
                        onBlur={this.handleZipBlur}
                    />
                </SectionFieldGroup>

            </Box>)
    }
    notificationSettings = () => {
        return (
            <Section key="notificationusers"

                dark={this.props.dark}
                title="Contact Form Notification Recipients"
                handleAdd={this.handleShowNewRecipient}>
                <Paper
                    sx={{
                        display: 'flex',
                        justifyContent: 'left',
                        flexWrap: 'wrap',
                        listStyle: 'none',
                        boxShadow: "none",
                        background: "transparent",
                        p: 0.5,
                        m: 0,
                    }}
                    component="div"
                >
                    {(this.state.notificationRecipients && this.state.notificationRecipients.length > 0) && this.state.notificationRecipients.map((item, index) => {
                        return this.notificationRecipient(item, index)
                    })}
                </Paper>
            </Section>
        )
    }
    notificationRecipient = (item, index) => {
        return (
            <SectionItem
                key={"nr" + index}
                itemID={item.id}
                index={index}
                dark={this.props.dark}
                chipClickEdit={false}
                handleDelete={() => this.handleShowConfirmDeleteNotificationRecipient(item.id, index)}
            >
                <Box display="flex" sx={{ flexGrow: 1 }}>
                    <Typography variant="body2" color="secondary">
                        {item.email}
                    </Typography>
                </Box>
            </SectionItem>
        )
    }
    notificationRecipientAdder = () => {
        return (
            <ModalDialog
                {...this.props}
                show={this.state.showNewNotificationRecipient}
                title="New Notification Recipient"
                handleClose={() => this.setState({ showNewNotificationRecipient: false })}
                handleSave={this.handleSaveNotificationRecipient}
                fullWidth={true}
                maxWidth="sm"
                closeLabel="Close"

            >
                <TextInput
                    {...this.props}
                    cKey="CnewRecipient"
                    cid="CnewRecipient"
                    id="fldNewRecipient"
                    label="Email"
                    defaultValue=""
                />
            </ModalDialog>
        )
    }  
    clientRow = (item, idx) => {
        return { id: item.id, clientName: item.name, status: item.status, altID: item.clientAltID }
    }
    clientList = () => {
        const columns = [
            { field: 'id', headerName: 'ID', width: 0, hide: true, filterable: false },
            { field: 'clientName', headerName: 'Client Name', width: 200, flex: 2 },
            { field: 'altID', headerName: 'Alt ID', width: 200, flex: 2 },
            { field: 'status', headerName: 'Status', width: 130, flex: 1 },
            { field: 'actions', filterable: false, headerName: '', renderCell: (params) => (
                    <>
                        <IconButton size="small" onClick={(e) => this.handleShowClientMenu(e, params)}>
                        <MoreHorizIcon sx={{ color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.dimblack }} />
                        </IconButton>
                        <Menu                      
                            open={this.state.anchorEl !== null}
                            onClose={this.handleCloseClientMenu}
                            anchorEl={this.state.anchorEl}
                            PaperProps={{ elevation: 1, sx: { backgroundColor: (theme) => this.props.dark ? theme.palette.backgroundModal.dark : theme.palette.backgroundModal.light, color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.dimblack } }}
                            MenuListProps={{ sx: { backgroundColor: (theme) => this.props.dark ? theme.palette.backgroundModal.dark : theme.palette.backgroundModal.light, color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.dimblack } }}
                        >
                            <MenuItem onClick={this.handleShowEditClient}>Edit</MenuItem>
                            <MenuItem onClick={this.handleShowConfirmDeleteClient}>Delete</MenuItem>
                        </Menu>
                    </>
                ),
            },
        ];
        if (this.state.business && this.state.business.clients && !this.state.loading) {
            const rows = this.state.business.clients.map((item, index) => {
                return this.clientRow(item, index)
            })

            return (
                <Stack style={{ width: "100%" }} className={this.props.dark && "dark"} >
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        columnVisibilityModel={{
                            ID: false,
                        }}
                        density="compact"
                        pageSize={20}
                        onRowClick={this.handleSelectClient}
                        components={{ Toolbar: () => this.CustomToolbarClientList(this) }}
                    />
                </Stack>
            )
        }
        return null;
    }
    clientManager = () => {
        return (
            <Stack sx={{ height: "calc(100vh - 88px)", width:"100%" }}>
                <Box display="flex">
                    <IconButton key="backbtn" aria-label="backbtn" onClick={this.handleBackToClientList} sx={{ color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.dimblack }}>
                        <ChevronLeftIcon />
                    </IconButton>
                    <Typography variant="subtitle1" color={(theme) => this.props.dark ? theme.palette.common.white : theme.palette.common.black} sx={{ marginTop: "auto", marginBottom: "auto", padding: "6px", fontSize: ".9rem" }}>
                        {this.state.selectedClient.name}
                    </Typography>
                </Box>

                <Box display="block" sx={{ padding: "0px", width: "100%", height: "100%", display: 'flex', flexGrow: 1, borderTop: 1, borderColor: 'divider' }} className="prettyScroll">
                    <Tabs indicatorColor="success" value={this.state.tabValueClient} onChange={this.handleTabChangeClient} aria-label="content tabs" orientation="vertical"
                        variant="scrollable" sx={{ borderRight: 1, borderColor: 'divider' }}>
                        <Tab label="Users" {...a11yProps(0)} sx={{ fontSize: ".8rem", color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.dimblack }} />
                        <Tab label="Content" {...a11yProps(1)} sx={{ fontSize: ".8rem", color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.dimblack }} />
                    </Tabs>
                    <TabPanel style={{ display: "contents", height: "calc(100vh - 139px)" }} value={this.state.tabValueClient} index={0}>
                        {this.clientUsers()}
                    </TabPanel>
                    <TabPanel style={{ display: "contents" }} value={this.state.tabValueClient} index={1}>
                        {this.docs()}
                    </TabPanel>
                </Box>
            </Stack>

        )
    }
    clientUser = (item, index) => {
        return (
            <SectionItem
                key={"si" + index }
                itemID={item.id}
                index={index}
                dark={this.props.dark}
                chipClickEdit={false}
                handleDelete={() => this.handleShowConfirmDeleteClientUser(item.id, index)}
            >
                <Box display="flex" sx={{ flexGrow: 1 }}>
                    <UserAvatar
                        id={item.user.userID}
                        user={item.user}
                        dark={this.props.dark}
                        loaded={true}
                        activeUser={this.props.user}
                        appIdentifier={this.props.appIdentifier}
                        hideName={false}
                        small
                    />
                </Box>
            </SectionItem>
        )
    }
    clientUsers = () => {
        if (this.state.selectedClient) {
            return (
                <Section
                    title="Client Users"
                    key="cliusers"    
                    fullWidth
                    dark={this.props.dark}
                    handleAdd={this.handleShowNewClientContact}>
                    <Paper
                        sx={{
                            display: 'flex',
                            justifyContent: 'left',
                            flexWrap: 'wrap',
                            listStyle: 'none',
                            boxShadow: "none",
                            background: "transparent",
                            p: 0.5,
                            m: 0,
                            width:"100%"
                        }}
                        component="div"
                    >
                        {(this.state.selectedClient.invitations && this.state.selectedClient.invitations.length > 0 && this.state.selectedClient.invitations !== "error") && this.state.selectedClient.invitations.map((item, index) => {
                            return this.invitedClientUser(item, index)
                        })}
                        {(this.state.selectedClient && this.state.selectedClient.users) && this.state.selectedClient.users.map((item, index) => {
                            return this.clientUser(item, index)
                        })}
                    </Paper>
                </Section>
            )
        }
        return null
    }
    invitedClientUser = (item, index) => {
        return (
            <SectionItem
                key={"inivted" + index}
                itemID={item.id}
                index={index}
                dark={this.props.dark}
                chipClickEdit={false}
                handleDelete={() => this.handleShowConfirmDeleteInvitedClientUser(item.id, index)}
            >
                <Box display="flex" sx={{ flexGrow: 1 }}>
                    <UserAvatar
                        id={item.user.userID}
                        user={item.user}
                        dark={this.props.dark}
                        loaded={true}
                        activeUser={this.props.user}
                        appIdentifier={this.props.appIdentifier}
                        hideName={false}
                        small
                    />
                    <Typography variant="body2" color="secondary" sx={{ ml: 5 }}>
                        {"Invited on " + new Date(item.dateSent).toLocaleDateString()}
                    </Typography>
                </Box>
            </SectionItem>
        )
    }
    clientUserInvitation = () => {
        if (this.state.showClientUserInvitation) {
            return (
                <ModalDialog
                    {...this.props}
                    show={this.state.showClientUserInvitation}
                    title="Add new users"
                    subTitle="Find users to add by entering their name or email address."
                    fullWidth={true}
                    maxWidth="sm"
                    disableButtons={true}
                >
                    <Invitation
                        user={this.props.user}
                        dark={this.props.dark}
                        appIdentifier={this.props.appIdentifier}
                        updateInviteList={this.updateClientUserInviteList}
                        groupID={this.state.selectedClient ? this.state.selectedClient.id : ""}
                        businessID={this.state.business ? this.state.business.id : ""}
                        groupName={this.state.selectedClient ? this.state.selectedClient.name : ""}
                        groupType="Client"
                        parentName={this.state.business.businessName}
                        handleCancel={() => this.setState({ showClientUserInvitation: false })}
                    />
                </ModalDialog>)
        }
        return null;
    } 
    adminUsers = () => {
        return (
                <Section key="adminusers"
                    
                    dark={this.props.dark}
                    title="Admin Users"
                    handleAdd={this.handleShowNewAdminUser}>
                    <Paper
                        sx={{
                            display: 'flex',
                            justifyContent: 'left',
                            flexWrap: 'wrap',
                            listStyle: 'none',
                            boxShadow: "none",
                            background: "transparent",
                            p: 0.5,
                            m: 0,
                        }}
                        component="div"
                    >
                        {(this.state.adminUsers && this.state.adminUsers.length > 0) && this.state.adminUsers.map((item, index) => {
                            return this.adminUser(item, index)
                        })}
                        {(this.state.invitedAdminUsers && this.state.invitedAdminUsers.length > 0) && this.state.invitedAdminUsers.map((item, index) => {
                            return this.invitedAdminUser(item, index)
                        })}
                    </Paper>
                </Section>
        )
    }
    adminUser = (item, index) => {
        return (
            <SectionItem
                key={"au" + index}
                itemID={item.id}
                index={index}
                dark={this.props.dark}
                chipClickEdit={false}
                handleDelete={() => this.handleShowConfirmDeleteAdminUser(item.id, index)}
            >
                <Box display="flex" sx={{ flexGrow: 1 }}>
                    <UserAvatar
                        id={item.user.userID}
                        user={item.user}
                        dark={this.props.dark}
                        loaded={true}
                        activeUser={this.props.user}
                        appIdentifier={this.props.appIdentifier}
                        hideName={false}
                        small
                    />
                </Box>
            </SectionItem>
        )
    }
    invitedAdminUser = (item, index) => {
        if (!item.user.lastName) {
            item.user.lastName = "";
        }
        return (
            <SectionItem
                key={"iau" + index}
                itemID={item.id}
                index={index}
                dark={this.props.dark}
                chipClickEdit={false}
                handleDelete={() => this.handleShowConfirmDeleteInvitedAdminUser(item.id, index)}
            >
                <Box display="flex" sx={{ flexGrow: 1 }}>
                    <UserAvatar
                        id={item.user.userID}
                        user={item.user}
                        dark={this.props.dark}
                        loaded={true}
                        activeUser={this.props.user}
                        appIdentifier={this.props.appIdentifier}
                        hideName={false}
                        small
                    />
                    <Typography variant="body2" color="secondary" sx={{ ml:5 }}>
                        {"Invited on " + new Date(item.dateSent).toLocaleDateString()}
                    </Typography>
                </Box>
            </SectionItem>
        )
    }
    adminUserInvitation = () => {
        if (this.state.showAdminUserInvitation) {
            return (
                <ModalDialog
                    {...this.props}
                    show={this.state.showAdminUserInvitation}
                    title="Add new admin users"
                    subTitle="Find users to add by entering their name or email address."
                    fullWidth={true}
                    maxWidth="sm"
                    disableButtons={true}
                >
                    <Invitation
                        user={this.props.user}
                        dark={this.props.dark}
                        appIdentifier={this.props.appIdentifier}
                        updateInviteList={this.updateAdminUserInviteList}
                        groupID={this.state.business ? this.state.business.id : ""}
                        businessID={this.state.business ? this.state.business.id : ""}
                        groupName={this.state.selectedClient ? this.state.selectedClient.name : ""}
                        groupType="Admin"
                        parentName={this.state.business.businessName}
                        handleCancel={() => this.setState({ showAdminUserInvitation: false })}
                        inviteType={0}
                    />
                </ModalDialog>)
        }
        return null;
    } 
    docs = () => {
        if (this.state.selectedClient) {
            return (
                <ClientDocuments
                    dark={this.props.dark}
                    user={this.props.user}
                    clientID={this.state.selectedClient.id}
                    appIdentifier={this.props.appIdentifier}
                    profile={this.props.profile}
                    userList={this.props.userList}
                />
            )
        }
        return null;
    }
    clientDocRow = (item, idx) => {
        return { id: item.documentID, clientName: item.clientName, altID: item.clientAltID, documentName: item.documentName, folderName: item.folderName, ext: item.extension, createDate: new Date(item.createDate).toLocaleDateString() + " " + new Date(item.createDate).toLocaleTimeString() }
    }
    clientDocList = () => {
        const columns = [
            { field: 'id', headerName: 'ID', width: 0, hide: true },
            { field: 'clientName', headerName: 'Client Name', width: 200, flex: 1 },
            { field: 'altID', headerName: 'Client ID', width: 130, flex: 1 },
            { field: 'folderName', headerName: 'Folder', width: 130, flex: 1 },
            { field: 'documentName', headerName: 'Document Name', width: 200, flex: 1 },
            { field: 'ext', headerName: 'Type', width: 130, flex: 1 },
            { field: 'createDate', headerName: 'Create Date', width: 130, flex: 1 },
        ];
        if (this.state.clientDocs && this.state.clientDocs.length > 0 && this.state.clientDocs !== "error") {
            const rows = this.state.clientDocs.map((item, index) => {
                return this.clientDocRow(item, index)
            })

            return (
                <Stack style={{ width: "100%" }} >
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        columnVisibilityModel={{
                            ID: false,
                        }}
                        density="compact"
                        pageSize={20}
                        rowsPerPageOptions={[5]}
                        onRowClick={this.handleSelectClientDoc}
                        onCon
                        components={{ Toolbar: this.CustomToolbarClientDocList }}
                    />
                </Stack>
            )
        }
        return null;
    }
    docPreview = () => {
        if (this.state.selectedDocumentContent && this.state.showDocument) {
            return (
                <ModalDialog
                    dark={this.props.dark}
                    show={this.state.showDocument}
                    handleClose={() => this.setState({ showDocument: false })}
                    fullWidth={true}
                    disableButtons
                    hideScroll={true}
                    draggable={true}
                    noUpperClose
                    maxWidth="xl"
                    fullScreen={false}
                    closeLabel="Close"
                    noBodyPad
                    fullHeight
                >
                    <DocumentViewer
                        dark={this.props.dark}
                        fileName={this.state.selectedDocumentName}
                        document={this.state.selectedDocumentContent}
                        scale={1.6}
                        handleClose={() => this.setState({ showDocument: false })}
                        fullHeight={true}
                    />
                </ModalDialog>
            )
        }
        return null;
    }
    profilePic = () => {
        return (
            <Section dark={this.props.dark} title="Primary Image/Logo" handleEdit={() => this.setState({ showProfileImageUploader: true })}>
                {(this.state.business && this.state.business.profilePhoto && this.state.business.profilePhoto.fullPhoto) &&
                    <img src={"data:image/png;base64, " + this.state.business.profilePhoto.fullPhoto} style={{ height: "auto", width: "auto", maxHeight: "250px", objectFit: "contain" }} />
                }
            </Section>
        )
    }
    coverPic = () => {
        return (
            <Section dark={this.props.dark} title="Cover Image" handleEdit={() => this.setState({ showCoverImageUploader: true })}>
                {(this.state.business && this.state.business.coverPhoto && this.state.businessy.coverPhoto.fullPhoto) &&
                    <img src={"data:image/png;base64, " + this.state.business.coverPhoto.fullPhoto} style={{ height: "auto", width: "auto", maxHeight: "250px", objectFit: "contain" }} />
                }
            </Section>
        )
    }  
    profileImageUploader = () => {
        return (
            <SiteImageUploader dark={this.props.dark}
                open={this.state.showProfileImageUploader}
                handleSave={this.saveNewPhoto}
                handleClose={() => this.setState({ showProfileImageUploader: false })}
                title="Upload Site Primary Image/Logo"
                type={0} />
        )
    }
    coverImageUploader = () => {
        return (
            <SiteImageUploader dark={this.props.dark}
                open={this.state.showCoverImageUploader}
                handleSave={this.saveNewPhoto}
                handleClose={() => this.setState({ showCoverImageUploader: false })}
                title="Upload Site Cover Image"
                type={1} />
        )
    }
    clientAdder = () => {
        return (
            <ModalDialog
                {...this.props}
                show={this.state.showNewClient}
                title={this.state.mode === "new" ? "New Client" : "Edit Client"}
                handleClose={() => this.setState({ showNewClient: false })}
                handleSave={this.state.mode === "new" ? this.handleSaveNewClient : this.handleSaveEditClient}
                fullWidth={true}
                maxWidth="sm"
                closeLabel="Close"

            >
                <TextInput
                    {...this.props}
                    cKey="CnewClientName"
                    cid="CnewClientName"
                    id="fldClientName"
                    label="Client Name"
                    defaultValue={this.state.nameValue}
                />
                <TextInput
                    {...this.props}
                    cKey="CnewClientAltID"
                    cid="CnewClientAltID"
                    id="fldClientAltID"
                    label="Alt ID"
                    defaultValue={this.state.altIdValue}
                />
            </ModalDialog>
        )
    }  
    clientDeleter = () => {
        return (
            <ModalDialog
                dark={this.props.dark}
                show={this.state.showConfirmDeleteClient}
                title="Confirm Deletion"
                subTitle={this.state.selectedClientID ? `Client ${this.state.business.clients.find((x)=> x.id === this.state.selectedClientID).name} and all associated data and documents will be deleted.  Proceed?` : "Client and all associated data and documents will be deleted.  Proceed?"}
                handleClose={() => this.setState({ showConfirmDeleteClient: false })}
                handleDelete={this.handleDeleteClient}
                fullWidth={false}
                maxWidth="sm" />
        )
    }
    adminUserDeleter = () => {
        return (
            <ModalDialog
                dark={this.props.dark}
                show={this.state.showConfirmDeleteAdminUser}
                title="Confirm Deletion"
                subTitle={"Selected admin user will be deleted.  Proceed?"}
                handleClose={() => this.setState({ showConfirmDeleteAdminUser: false })}
                handleDelete={this.handleDeleteAdminUser}
                fullWidth={false}
                maxWidth="sm" />
        )
    }
    invitedAdminUserDeleter = () => {
        return (
            <ModalDialog
                dark={this.props.dark}
                show={this.state.showConfirmDeleteInvitedAdminUser}
                title="Confirm Deletion"
                subTitle={"Selected admin user will be deleted.  Proceed?"}
                handleClose={() => this.setState({ showConfirmDeleteInvitedAdminUser: false })}
                handleDelete={this.handleDeleteInvitedAdminUser}
                fullWidth={false}
                maxWidth="sm" />
        )
    }
    notificationRecipientDeleter = () => {
        return (
            <ModalDialog
                dark={this.props.dark}
                show={this.state.showConfirmDeleteNotificationRecipient}
                title="Confirm Deletion"
                subTitle={"Selected recipient will be deleted.  Proceed?"}
                handleClose={() => this.setState({ showConfirmDeleteNotificationRecipient: false })}
                handleDelete={this.handleDeleteNotificationRecipient}
                fullWidth={false}
                maxWidth="sm" />
        )
    }
    content = () => {
        return (
            <Box
                sx={{ background: (theme) => this.props.dark ? theme.palette.backgroundSecondaryAlt.dark : theme.palette.backgroundSecondary.light, height: { xs: "calc(100vh - 112px)", lg:"calc(100vh - 48px)" }, }}
                className="prettyScroll"
            >
                {this.state.loading
                    ?
                    <Box sx={{ p:2}}>
                        <Spin dark={this.props.dark} open={this.state.loading} message={this.state.loadingMessage} />
                    </Box>

                    :
                    this.TabContainer()
                }
                {this.profileImageUploader()}
                {this.coverImageUploader()}
                {this.clientAdder()}
                {this.notificationRecipientAdder()}
                {this.clientUserInvitation()}
                {this.adminUserInvitation()}
                {this.docPreview()}
                {this.state.showConfirmDeleteClient && this.clientDeleter()}
                {this.state.showConfirmDeleteAdminUser && this.adminUserDeleter()}
                {this.state.showConfirmDeleteInvitedAdminUser && this.invitedAdminUserDeleter()}
                {this.state.showConfirmDeleteNotificationRecipient && this.notificationRecipientDeleter()}
            </Box>
        );
    }

    //main component render
    render() {
        return this.content();
    }
}