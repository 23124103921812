import React, { Component } from 'react';

//Custom Components
import { ModalDialog } from '../../Utilities/ModalDialog'
import { DropUploader } from '../../Utilities/DropUploader'

//Material UI
import { Typography } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { newDocument } from '../../../DataServices/DocumentService'


export class DocumentUploader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            itemCount: 0,
            processing:false
        }
    }

    //handlers
    handleSaveDocument = async (file) => {
        if (this.props.business && this.props.handleSave) {
            this.props.handleSave(file);
        }
        else {
            const doc = await newDocument(file, this.props.library.id, this.props.appIdentifier);
            if (doc) {
                this.props.updateParent(doc);
            }
        }
        this.setState({ itemCount: this.state.itemCount + 1 });

    }

    handleClose = () => {
        this.setState({
            currentUpload: "",
            itemCount: 0
        })
        this.props.handleClose();
    }
    toggleProcessing = (status) => {
        if (status != null) {
            this.setState({ processing: status });
        }
        else {
            this.setState({ processing: !this.state.processing });
        }
        if (status === false) {
            this.props.handleClose();
        }
    }

    //render
    summaryText = (count) => {
        if (count === 1) {
            return "Uploaded 1 document";
        }
        else {
            return "Uploaded " + count + " documents";
        }
    }
    uploadingMessage = () => {
        return (
            <Box sx={{ display: "flex", width: "100%", my: "auto", minHeight: { md: 300 }, minWidth: { sm: 500 }, textAlign: "center", background: "transparent" }}>
                <Box sx={{ m: "auto" }}>
                    <Typography sx={{ color: theme => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.dimblack, mb: 2, fontWeight: 600 }}>
                        Uploading
                    </Typography>
                    <CircularProgress color="secondary" />
                </Box>
            </Box>
        )
    }
    uploader = () => {
        return (
            <DropUploader
                dark={this.props.dark}
                type={0}
                multi={this.props.multi}
                label="File Uploader"
                fileType="file"
                onDrop={this.updateStateForNewDocument}
                handleSave={this.handleSaveDocument}
                height={250}
                width={"100%"}
                toggleProcessing={this.toggleProcessing}
            />
        )
    }
    alerts = () => {
        return (
            <Box sx={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                mt: this.state.itemCount > 0 || this.state.processing ? 2 : 0,
                px: this.props.width < 600 ? 1 : 2,
            }}>
                <Stack>
                    <Box sx={{ display: "flex", width: "100%", justifyContent: "center", }}>
                        {(!this.state.processing && this.state.itemCount > 0) &&
                            <Typography sx={{ display: "inline-block", mr: 2, color: theme => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.dimblack }}>
                                {this.summaryText(this.state.itemCount)}
                            </Typography>}
                        {(!this.state.processing && this.state.itemCount > 0) && <CheckCircleOutlineIcon color="success" />}
                        {this.state.processing &&
                            <Box sx={{ m: "auto", display: "flex" }}>
                                <Typography sx={{ mr: 2, my: "auto", color: theme => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.dimblack }}>
                                    Uploading
                                </Typography>
                                <CircularProgress color="secondary" />
                            </Box>
                        }

                    </Box>
                </Stack>
            </Box>
        )
    }

    render() {
        return (
            <ModalDialog
                dark={this.props.dark}
                fullWidth={true}
                maxWidth={"xs"}
                fullScreen={this.props.width < 600}
                closeLabel="Close"
                handleClose={this.handleClose}
                show={this.props.open}
                title="Upload New Documents"
            >
                <Stack sx={{ pt: { sm: 1 }, pb: { sm: 2 } }} >
                    {this.uploader()}
                    {this.alerts()}
                </Stack>
            </ModalDialog>
        );
    }
}
