import React, { useState } from 'react';
import { Redirect } from 'react-router';


//Custom
import { Spin } from "../Common/Utilities/Spin"

//MUI
import PropTypes from 'prop-types';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';

//Data Services
import { getPasswordResetRequest, setForgottenPassword } from '../DataServices/UserService'


async function loginUser(credentials) {
    return fetch('home/login', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
    })
        .then(data =>  data.json() )
}
async function registerUser(credentials) {
    return fetch('home/register', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
    })
        .then(data => data.json())
}
async function addEmail(email) {

    const response = await fetch('home/addemail?type=' + 0 + '&email=' + email + '&primary=' + true, { method: 'POST' })
    const id = await response.text();
    return id;
}
function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright � '}
            <Link color="inherit" href="http://simplebluetech.com">
                LiveSphere
      </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

export default function ChangePassword({ match, request, setRequest, setToken }) {
    const [username, setUserName] = useState();
    const [password, setPassword] = useState();
    const [confirmPassword, setConfirmPassword] = useState();
    const [complete, setComplete] = useState(false);
    const [changing, setChanging] = useState(false);

    const [showInvalidUsernameAlert, setInvalidUsernameAlert] = useState(false);
    const [showPasswordMatchAlert, setPasswordMatchAlert] = useState(false);
    const [showRequiredAlert, setRequiredAlert] = useState(false);
    const requestID = match.params.id;




    const getData = async () => {
        const request = await getPasswordResetRequest(requestID);
        setRequest(request);
    }
    const handleChangeSubmit = e => {
        e.preventDefault();
        setChanging(true);
        ChangePassword();

    }
    const ChangePassword = async () => {
        if (!username || !password || !confirmPassword) {
            setRequiredAlert(true);
            setChanging(false);
            return;
        }
        if (username !== request.email) {
            setInvalidUsernameAlert(true);
            setChanging(false);
            return;
        }

        if (password !== confirmPassword) {
            setPasswordMatchAlert(true);
            setChanging(false);
            return;
        }

        const res = await setForgottenPassword(requestID, password);
        setChanging(false);
        if (res) {
            setToken('');
            setComplete(true);
        }
        
    }


    const handleUserNameChange = async e => {
        e.preventDefault();
        setUserName(e.target.value);
        setInvalidUsernameAlert(false);
        setRequiredAlert(false);
    }
    const handlePasswordChange = async e => {
        e.preventDefault();
        setPassword(e.target.value);
        setPasswordMatchAlert(false);
        setRequiredAlert(false);
    }
    const handleConfirmPasswordChange = async e => {
        e.preventDefault();
        setConfirmPassword(e.target.value);
        setPasswordMatchAlert(false);
        setRequiredAlert(false);
    }

    const qt = <span>"And in the end it's not the years in your life that count. It's the life in your years."<br /><b>-Abraham Lincoln</b></span>;
    if(!request)
        getData();

    let redirect =
        <Redirect to="../" />

    let contents = 
 
        <Box sx={{
            width: "100%",
            backgroundImage: 'url(/Images/SphereLeft.jpeg)',
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
                t.palette.mode === 'light' ? t.palette.grey[900] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',

        }}>
            <Box

                sx={{
                    background: "rgba(255,255,255,.05)",
                    padding: "24px",
                    borderRadius: "16px",
                    position: "fixed",
                    left: "100px",
                    bottom: "100px",
                    maxWidth: "400px",
                    color: "#ccc",
                    display: { xs: "none", md: "flex" }
                }}>
                <Typography component="h6" sx={{ color: "white", textAlign: "center" }}>
                    {qt}
                </Typography>

            </Box>
            <Grid container component="main" sx={{ height: '100vh' }}>
                <CssBaseline />
                <Grid
                    item
                    xs={false}
                    sm={4}
                    md={7}
                >
                </Grid>
                <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square sx={{ background: "rgba(255,255,255,0.1)" }}>
                    <Box
                        sx={{
                            my: 8,
                            mx: 4,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center', }}
                    >
                        <Box sx={{ paddingTop: "10px", paddingRight: "20px", paddingLeft: "5px", paddingBottom: "5px", borderRadius: "30px", marginBottom: "20px", maxWidth: "calc(100% - 20px)" }} >
                            <img src='/Images/LiveSphereDark.png' style={{ maxWidth: "100%", maxHeight: "100px" }} alt="" />
                        </Box>

                            <Box
                                component="form"
                                noValidate
                                onSubmit={handleChangeSubmit}
                                sx={{
                                    mt: 3,
                                    background: "rgba(0,0,0,.5)",
                                    padding: "24px",
                                    borderRadius: "16px"
                                }}>
                                <Avatar sx={{ m: 1, bgcolor: 'secondary.main', marginLeft: "auto", marginRight: "auto" }}>
                                    <LockOutlinedIcon />
                                </Avatar>
                                <Typography component="h1" variant="h5" sx={{ color: "white", textAlign: "center" }}>
                                    Update Password
                                </Typography>
                                <TextField
                                    margin="normal"
                                    variant="standard"
                                    required
                                    fullWidth
                                    size="small"
                                    id="email"
                                    label="Email Address"
                                    placeholder="Email Address"
                                    name="email"
                                    autoComplete="email"
                                autoFocus
                  
                                    onChange={handleUserNameChange}
                                    InputProps={{ sx: { color: "#ccc" } }}
                                    InputLabelProps={{ style: { color: "#ddd" } }}
                                />
                                <TextField
                                    margin="normal"
                                    required
                                    variant="standard"
                                    fullWidth
                                    size="small"
                                    name="password"
                                    label="New Password"
                                    placeholder="Password"
                                    type="password"
                                    id="password"
                          
                                    autoComplete="new-password"
                                    onChange={handlePasswordChange}
                                    InputProps={{ sx: { color: "#ccc"} }}
                                    InputLabelProps={{ style: { color: "#ddd" } }}
                            />
                            <TextField
                                margin="normal"
                                required
                                variant="standard"
                                fullWidth
                                size="small"
                                name="password"
                                label="Confirm Password"
                                placeholder="Password"
                                type="password"
                                id="confirmpassword"
                                autoComplete="new-password"
                                onChange={handleConfirmPasswordChange}
                                InputProps={{ sx: { color: "#ccc" } }}
                                InputLabelProps={{ style: { color: "#ddd" } }}
                            />
                            {showRequiredAlert && <Alert severity="error">All fields are required.</Alert>}
                            {showInvalidUsernameAlert && <Alert severity="error">Email is not associated to this password change request.</Alert>}
                            {showPasswordMatchAlert && <Alert severity="error">Passwords must match.</Alert>}
                                <Button
                                    type="submit"
                                    fullWidth
                                    color="secondary"
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2, boxShadow:"none" }}
                                >
                                    Submit
                                </Button>
                               
                                <Copyright sx={{ mt: 5, color: "white" }} />
                            </Box>

                    </Box>
                </Grid>
            </Grid>
        </Box>

    return (
        <div>
            {!complete ? contents : redirect}
            <Spin open={changing} />
        </div>
    );
}

