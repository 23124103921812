import * as React from 'react';
import { Headlines } from './Headlines';
import { styled, alpha } from '@mui/material/styles';
import { Menu } from '../Common/Utilities/Menu'
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Avatar from '@material-ui/core/Avatar';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
//import Menu from '@mui/material/Menu';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import NotificationsIcon from '@mui/icons-material/Notifications';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import WidgetsIcon from '@mui/icons-material/Widgets';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import GroupsIcon from '@mui/icons-material/Groups';
import MoreIcon from '@mui/icons-material/MoreVert';
import TimelineIcon from '@mui/icons-material/Timeline';
import LogoutIcon from '@mui/icons-material/Logout';
import PropTypes from 'prop-types';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import SmsIcon from '@mui/icons-material/Sms';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';
import { Divider } from '@mui/material';

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: '#ccc',
    '& .MuiInputBase-input': {
        padding: theme.spacing(.5, .5, .5, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
}));
PrimarySearchAppBar.propTypes = {
    user: PropTypes.object,
    setToken: PropTypes.func.isRequired
};

export default function PrimarySearchAppBar({
    user,
    setToken,
    dark,
    setDark,
    handleChatOpen,
    searchCallback,
    handleProfileOpen,
    handleAffiliationsOpen,
    handleActivityOpen,
    handleNotificationsOpen,
    notificationCount,
    handleAccountOpen,
    invitationCount,
    handleInvitationsOpen,
    searchTerm,
    setTopic,
    navIndex,
    appOptions
    })
{

    //Constants
    const menuId = 'primary-search-account-menu';
    const mobileMenuId = 'primary-search-account-menu-mobile';

    //State variables
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    //event handlers
    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };
    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };
    const logoutUser = async () => {
        return fetch('home/logout', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
    const handleLogout = async () => {
        handleMenuClose();
        await logoutUser();
        setToken('');
        localStorage.clear();
    }
    const handleChat = () => {
        handleMenuClose();
        handleMobileMenuClose();
        handleChatOpen();
    }
    const handleProfile= () => {
        handleMenuClose();
        handleMobileMenuClose();
        handleProfileOpen();
    }
    const handleAffiliations = () => {
        handleMenuClose();
        handleMobileMenuClose();
        handleAffiliationsOpen();
    }
    const handleActivity = () => {
        handleMenuClose();
        handleMobileMenuClose();
        handleActivityOpen();
    }
    const handleAccount = () => {
        handleMenuClose();
        handleMobileMenuClose();
        handleAccountOpen();
    }
    const handleNotification = () => {
        handleMenuClose();
        handleMobileMenuClose();
        handleNotificationsOpen();
    }
    const handleInvitation = () => {
        handleMenuClose();
        handleMobileMenuClose();
        handleInvitationsOpen();
    }
    const handleSetDarkMode = () => {
        handleMenuClose();
        handleMobileMenuClose();
        setDark(!dark);
    }
    const handleSearch = async (e) => {
        var term = e.target.value;
        searchCallback(term);

    }
    const onSearchKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSearch(event);
        }
    }
    const handleSetTopic = () => {
        handleMenuClose();
        setTopic("content");
    }
    //render funcs
    const Search = styled('div')(({ theme }) => ({
        position: 'relative',
        borderRadius: theme.shape.borderRadiusSm,
        transition: "1s",
        backgroundColor: dark ? "#000" : "#000",
        border: dark ? "none" : "none",
        color: dark ? theme.palette.common.dimwhite : theme.palette.common.dimwhite,
        opactity: .9,
        '&:hover': {
            backgroundColor: dark ? "#111" : "#111",
            opactity: .8,
        },
        marginRight: { xs: 0, sm: 2 },
        marginLeft: 8,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
    }));
    const renderMenu = () => {
        return (
            <Menu
                dark={dark}
                width={1000}
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={menuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMenuOpen}
            onClose={handleMenuClose}

        >
            <MenuItem>
                <FormControlLabel control={<Switch
                    checked={dark}
                    color="secondary"
                    onChange={handleSetDarkMode}
                    size="small"
                    inputProps={{ 'aria-label': 'controlled' }}

                />
                }
                    label={<Typography style={{  paddingLeft: "24px" }}>Dark Mode</Typography>}
                />
            </MenuItem>
            <Divider />
            {notificationCount > 0 &&
                <MenuItem onClick={handleNotification} sx={{ display: { xs: "none", lg: "flex" } }}>
                    <ListItemIcon>
                        <Badge badgeContent={notificationCount ?? null} color="error">
                            <NotificationsIcon />
                        </Badge>
                    </ListItemIcon>
                    <Typography  variant="inherit">{notificationCount === 0 ? "No Notifications" : notificationCount + " Notifications"}</Typography>


                </MenuItem>
            }
            {invitationCount > 0 &&
                <MenuItem onClick={handleInvitation}>
                    <ListItemIcon>
                        <Badge badgeContent={invitationCount ?? null} color="error">
                            <GroupAddIcon />
                        </Badge>
                    </ListItemIcon>
                <Typography  variant="inherit">{invitationCount === 0 ? "No Invitations" : (invitationCount === 1 ? invitationCount + " Invitation" : invitationCount + " Invitations")}</Typography>
                </MenuItem>
            }
            {(user) &&
                <MenuItem onClick={handleChat} sx={{ display: { xs: "none", lg: "flex" } }}>
                    <ListItemIcon>
                        <Badge invisible={!user.hasNewMessages} color="info" variant="dot">
                            <SmsIcon fontSize="small" />
                        </Badge>
                    </ListItemIcon>
                    <Typography variant="inherit">{user.hasNewMessages ? "New Messages" : "Conversations"}</Typography>
                </MenuItem>
            }
            {navIndex !== 1 &&
                <MenuItem onClick={handleSetTopic} sx={{ display: { xs: "none", lg: "flex" } }}>
                    <ListItemIcon>
                        <WidgetsIcon fontSize="small" />
                    </ListItemIcon>
                    <Typography variant="inherit">My Content</Typography>
                </MenuItem>
            }
            <MenuItem onClick={handleProfile} sx={{ display: { xs: "none", lg: "flex" } }} >
                <ListItemIcon>
                    <AccountCircleIcon fontSize="small" />
                </ListItemIcon>
                <Typography  variant="inherit">Profile</Typography>
            </MenuItem>
            <MenuItem onClick={handleAffiliations}>
                <ListItemIcon>
                    <GroupsIcon fontSize="small" />
                </ListItemIcon>
                <Typography  variant="inherit">Affiliations</Typography>
            </MenuItem>
            <MenuItem onClick={handleActivity}>
                <ListItemIcon>
                    <TimelineIcon fontSize="small" />
                </ListItemIcon>
                <Typography  variant="inherit">Activity</Typography>
            </MenuItem>
            <MenuItem onClick={handleAccount}>
                <ListItemIcon>
                    <ManageAccountsIcon fontSize="small" />
                </ListItemIcon>
                <Typography  variant="inherit">Account</Typography>
            </MenuItem>
            <MenuItem onClick={handleLogout}>
                <ListItemIcon>
                    <LogoutIcon fontSize="small" />
                </ListItemIcon>
                <Typography variant="inherit">Sign Out</Typography>
            </MenuItem>
        </Menu>)
    } ;
    const renderMobileMenu = () => {
        return (
            <Menu
                dark={dark}
                width={500}
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >

            <MenuItem>
                <FormControlLabel
                    control={
                        <Switch
                            checked={dark}
                            color="secondary"
                            onChange={handleSetDarkMode}
                            size="small"
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                    }
                    label=" Dark Mode"
                />
            </MenuItem>
            {(notificationCount > 0) &&
                <MenuItem onClick={handleNotification} sx={{ display: { xs: "none", lg: "flex" } }}>
                    <ListItemIcon>
                        <Badge badgeContent={notificationCount ?? null} color="error">
                            <NotificationsIcon />
                        </Badge>
                    </ListItemIcon>
                    <Typography  variant="inherit">{notificationCount === 0 ? "No Notifications" : notificationCount + " Notifications"}</Typography>


                </MenuItem>
            }
            {invitationCount > 0 &&
                <MenuItem onClick={handleInvitation}>
                    <ListItemIcon>
                        <Badge badgeContent={invitationCount ?? null} color="error">
                            <GroupAddIcon />
                        </Badge>
                    </ListItemIcon>
                <Typography  variant="inherit">{invitationCount === 0 ? "No Invitations" : (invitationCount === 1 ? invitationCount + " Invitation" : invitationCount + " Invitations")}</Typography>
                </MenuItem>
            }
            {(user) &&
                <MenuItem onClick={handleChat} sx={{ display: { xs: "none", lg: "flex" } }}>
                    <ListItemIcon>
                        <Badge invisible={!user.hasNewMessages} color="secondary" variant="dot">
                            <SmsIcon fontSize="small" />
                        </Badge>
                    </ListItemIcon>
                    <Typography  variant="inherit">{user.hasNewMessages ? "New Messages" : "Conversations"}</Typography>


                </MenuItem>
            }
            {navIndex !== 1 &&
                <MenuItem onClick={handleSetTopic} sx={{ display: { xs: "none", lg: "flex" } }}>
                    <ListItemIcon>
                        <WidgetsIcon fontSize="small" />
                    </ListItemIcon>
                    <Typography  variant="inherit">My Content</Typography>
                </MenuItem>
            }
            <MenuItem onClick={handleProfile} sx={{ display: { xs: "none", lg: "flex" } }}>
                <ListItemIcon>
                    <AccountCircleIcon fontSize="small" />
                </ListItemIcon>
                <Typography  variant="inherit">Profile</Typography>
            </MenuItem>
            <MenuItem onClick={handleAccount}>
                <ListItemIcon>
                    <ManageAccountsIcon fontSize="small" />
                </ListItemIcon>
                <Typography  variant="inherit">Account</Typography>
            </MenuItem>
            <MenuItem onClick={handleAffiliations}>
                <ListItemIcon>
                    <GroupsIcon fontSize="small" />
                </ListItemIcon>
                <Typography  variant="inherit">Affiliations</Typography>
            </MenuItem>
            <MenuItem onClick={handleActivity}>
                <ListItemIcon>
                    <TimelineIcon fontSize="small" />
                </ListItemIcon>
                <Typography  variant="inherit">Activity</Typography>
            </MenuItem>
            <MenuItem onClick={handleLogout}>
                <ListItemIcon>
                    <LogoutIcon fontSize="small" />
                </ListItemIcon>
                <Typography  variant="inherit">Sign Out</Typography>
            </MenuItem>
        </Menu>)
    } 
    const renderAvatar = () => {
        return (
            <Badge invisible={!user.hasNewMessages && !notificationCount} color="info" variant="dot" anchorOrigin={{ vertical: 'top', horizontal: 'left' }} overlap="circular">
                <Avatar className="avatarImageMedium" alt={user.firstName} src={"data:image/png;base64, " + user.avatar} />
            </Badge>
            )
    }
    const logo = () => {
        return (
            <>
                <Button color="secondary" sx={{ p:0, display: { xs: 'none', sm: 'block' }, marginRight: "10px", cursor: "pointer" }} onClick={() => setTopic("posts")}>
                    <img src={dark ? '/Images/lsletters.png' : '/Images/lsletters.png'} height="40px" widthtitle="LiveSphere" />
                </Button>
                <Button color="secondary" sx={{ p: 0, height: "40px", width: "40px", minWidth:40,display: { xs: 'block', sm: 'none' }, mr:1, cursor: "pointer" }} onClick={() => setTopic("posts")}>
                    <img src={dark ? '/Images/lscircle.png' : '/Images/lscircle.png'} height="40px" title="LiveSphere" />
                </Button>
            </>
            )
    } 
    const searchBox = () => {
        return (
            <Search>
                <SearchIconWrapper>
                    <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                    placeholder="Search LiveSphere"
                    inputProps={{ 'aria-label': 'search' }}
                    onKeyPress={onSearchKeyPress}
                    defaultValue={searchTerm}
                />
            </Search>
        )
    }
    const gapSpacer = () => {
        return (<Box sx={{ flexGrow: 1 }} />)
    }
    const headlines = () => {
        return <Headlines dark={dark} user={user} />
    }
    const userIconButton = () => {
        return (
            <>
                <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                    <IconButton
                        size="medium"
                        edge="end"
                        aria-label="account of current user"
                        aria-controls={menuId}
                        aria-haspopup="true"
                        onClick={handleProfileMenuOpen}
                        sx={{ color: "#e0e0e0" }}
                    >
                        {user ? renderAvatar() : <AccountCircleIcon />}
                    </IconButton>
                </Box>
                <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                    <Tooltip title="User Options">
                        <IconButton
                            size="large"
                            aria-label="show more"
                            aria-controls={mobileMenuId}
                            aria-haspopup="true"
                            onClick={handleMobileMenuOpen}
                            color="inherit"
                            sx={{ p: { xs: 0, sm: undefined }, mr: { xs: 1, sm: undefined } }}
                        >
                            <MoreIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
            </>
            )


    }
    return (
        <AppBar
            elevation={dark ? 0 : 8}
            position="fixed"
            sx={{
                zIndex: (theme) => theme.zIndex.drawer + 1,
                backgroundColor: (theme) => dark ? theme.palette.maintoolbar.dark : theme.palette.maintoolbar.light,
                color: (theme) => dark ? theme.palette.common.white : theme.palette.common.white,
            }}
        >
            <Toolbar sx={{ pr: { xs: 0, sm: 2 } }}>
                {logo()}
                {searchBox()}
                {gapSpacer()}
                {appOptions.includes("weather") && headlines()}
                {userIconButton()}
            </Toolbar>
            {renderMobileMenu()}
            {renderMenu()}
        </AppBar>
    );
}
