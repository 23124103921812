import React, { Component } from 'react';
//Custom Components
import { FolderLabel } from './FolderLabel'
import { ContentOuterContainer } from './ContentOuterContainer';
import { ContentTabs } from './ContentTabs';
import { ContentTabsLeftTitle } from './ContentTabsLeftTitle';
import { ContentLoader } from './ContentLoader';
import { ContentTabPanel } from './ContentTabPanel';
import { ContextMenu } from './ContextMenu';
import { ItemDeleter } from './ItemDeleter'
import { ItemCreator } from './ItemCreator';
import { capitalize, getIdxFromID } from '../../DataServices/Utility';
import { getContentProps } from '../../ObjectDefinitions/objectProperties'

//Material UI
import Tab from '@mui/material/Tab';
//DND
import { DragDropContext, Droppable, } from 'react-beautiful-dnd';

//Helpers
const getListStyle = isDraggingOver => ({
    opacity: isDraggingOver ? .8 : 1,
    border: isDraggingOver && "3px dashed #558b2f",
    padding:"8px",
    width: "100%",
    height: "100%"
});
const userFolder = (item, userID) => {
    if (item.ownerID !== userID) return false;
    if (item.name.includes("Shared ")) return false;
    if (item.name.includes("Profile ")) return false;
    if (item.name.includes("Posted ")) return false;
    return true;
}
const sharedFolder = (item, userID) => {
    try {
        if (item.name.includes("Shared ")) return true;
    }
    catch { }
    try {
        if (item.title.includes("Shared ")) return true;
    }
    catch { }

    return false;
}
export class ContentContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            propsLoaded: false,
            contextMenuFolder: null,
            showConfirmDelete: false,
            showEditFolder: false,
            showNewFolder: false
        }
    }
    componentDidMount() {
        this.getContentProps();
    }
    getContentProps = () => {
        const content = getContentProps(this.props.contentType);
        if (content) {
            this.setState({
                propsLoaded: true,
                itemFields: content.itemFields,
                folderFields: content.folderFields,
                functions: content.functions,
                options: content.options
            })
        }
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.props.contentType !== prevProps.contentType) {
            this.getContentProps();
        }
    }

    buildContextMenu = () => {
    const menuItems = [];
        if (this.state.functions.deleteFolder !== null) {
            const deleteItem = { callBack: this.handleShowConfirmDelete, label: "Delete" };
            menuItems.push(deleteItem);
        }
        if (this.state.functions.editFolder !== null) {
            const editItem = { callBack: this.handleShowEdit, label: "Edit" };
            menuItems.push(editItem);
        }
    return menuItems;
    }

    handleTabChange = (event, newValue) => {
        this.props.setTabValue(this.props.contentType, newValue)
        if (this.props.contentType === "photos") {
            if (!this.props.folders[newValue - 1].photos || this.props.folders[newValue - 1].photos.length === 0)
                this.props.getPhotos(newValue - 1);
        }
    }
    handleContextMenuFolder = (e, idx, item) => {
        e.preventDefault();
        if (userFolder(item, this.props.user.userID)) {
            if (this.state.contextMenuFolder === null) {
                this.setState({
                    contextMenuFolder: {
                        mouseX: e.clientX - 2,
                        mouseY: e.clientY - 4,
                    },
                })
                this.setState({
                    selectedFolder: item,
                    selectedFolderID: item.id,
                    selectedFolderIDX: idx,
                })
                this.handleTabChange(null, idx + 1);
            }
            else {
                this.setState({
                    contextMenuFolder: null,
                });
            }
        }
    }
    handleShowEdit = () => {
        this.setState({
            showEditFolder: true,
            contextMenuFolder: null
        })
    }
    handleShowNew = () => {
        this.setState({
            showNewFolder: true,
            contextMenuFolder: null
        })
    }
    handleShowConfirmDelete= () => {
        this.setState({
            showConfirmDelete: true,
            contextMenuFolder: null
        })
    }

    handleDeleteFolder = async () => {
        this.setState({ showConfirmDelete: false });
        const folders = [...this.props.folders];
        folders.splice(this.state.selectedFolderIDX, 1);
        this.props.updateData(this.props.contentType, folders);
        await this.state.functions.deleteFolder(this.state.selectedFolderID);
    }
    handleEditFolder = async (values) => {
        if (this.state.selectedFolderID && values && values.length > 0) {
            this.setState({ showEditFolder: false });
            const folders = [...this.props.folders];
            const fields = this.state.folderFields;
            for (var i = 0; i < fields.length; i++) {
                folders[this.state.selectedFolderIDX][fields[i].fieldName] = values[i];
            }
            this.props.updateData(this.props.contentType, folders);
            await this.state.functions.editFolder(this.state.selectedFolderID, ...values);
        }
    }
    handleNewFolder = async (values) => {
        if (values && values.length > 0) {
            this.setState({ showNewFolder: false });
            const folder = await this.state.functions.createFolder( ...values);           
            if (folder) {
                console.log(folder);
                const folders = [...this.props.folders]; 
                folder.ownerID = this.props.user.userID;
                folders.push(folder);
                this.props.updateData(this.props.contentType, folders);
            }

        }
    }
    folder = (item, index, isUser) => {
        return (
            <FolderLabel
                dark={this.props.dark}
                label={item.name}
                isUserFolder={isUser}
                isClosed={(this.props.tabValue - 1) !== index}
                isList={this.props.contentType === "lists"}
                sharedFolder={sharedFolder(item, this.props.user.userID)}
            />
            )
    }
    onDragEnd = async (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        //Get variables
        const folders = [...this.props.folders];
        const item = folders[this.props.tabValue - 1][this.props.contentType].find((x) => x.id === result.draggableId);
        const oldIdx = folders[this.props.tabValue - 1][this.props.contentType].indexOf(item);
        const newIdx = getIdxFromID(folders, result.destination.droppableId);

        await this.handleTabChange(null, newIdx + 1);

        //if variables are legit and new target is found and not the same as original target, then proceed...
        if (item && oldIdx >= 0 && newIdx >= 0) {
            //Remnove from old folder
            folders[this.props.tabValue - 1][this.props.contentType].splice(oldIdx, 1);

            //Push into new folder
            folders[newIdx][this.props.contentType].push(item);

            //Update state
            this.props.updateData(this.props.contentType, folders);


            //Post to api
            const success = await this.state.functions.moveItem(result.draggableId, result.destination.droppableId);
            if (!success) {
                console.log("Error saving chnages");
            }
        }
    }
    //Render functions
    content = () => {

        if (!this.props.contentLoading && this.props.folders && this.state.propsLoaded) {
            return (
                <ContentOuterContainer dark={this.props.dark}>
                    <DragDropContext onDragEnd={this.onDragEnd}>
                        <ContentTabs dark={this.props.dark} tabValue={this.props.tabValue} onChange={this.handleTabChange}>
                            <ContentTabsLeftTitle
                                dark={this.props.dark}
                                title={capitalize(this.props.contentType)}
                                tooltip={this.props.contentType === "lists" ? "New List" : (this.props.contentType === "notes" ? "New Notebook" : `New ${capitalize(this.props.contentType).slice(0, -1)} Folder`)}
                                onClick={this.handleShowNew}
                            />
                            {this.props.folders.length > 0 && this.props.folders.map((item, index) => {
                                    return (
                                        <Tab
                                            onContextMenu={(e) => this.handleContextMenuFolder(e, index, item)}
                                            key={item.id}
                                            label={userFolder(item, this.props.user.userID) ?
                                                <Droppable key={"folder_drop" + index} droppableId={item.id} isDropDisabled={(this.props.tabValue - 1) === index ? true : false} >
                                                    {(provided, snapshot) => (
                                                        <div {...provided.droppableProps} ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                                                            {this.folder(item, index, true)}
                                                        </div>
                                                    )}
                                                </Droppable>
                                                :
                                                this.folder(item, index, false)
                                            }
                                            sx={{ px: 1, py: 0, minHeight: "36px", fontSize: ".8rem", color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.white }}
                                        />
                                    )
                                })
                            }
                        </ContentTabs>
                        {this.props.folders.length > 0 && this.props.folders.map((item, index) => {
                            return this.tabPanel(item, index, userFolder(item, this.props.user.userID) );
                        })}

                    </DragDropContext>
                </ContentOuterContainer>
            )
        }
        return (
            <ContentLoader {...this.props} />
        )

    }
    tabPanel = (folder, index, isUserFolder) => {
        return (
            <ContentTabPanel
                {...this.props}
                itemFields={this.state.itemFields}
                folderFields={this.state.folderFields}
                functions={this.state.functions}
                options={isUserFolder ? this.state.options : null}
                key={folder.id + "ct"}
                isUserFolder={isUserFolder}
                folder={folder}
                index={index}
            />
        )
    }
    contextMenuFolder = () => {
        if (this.state.contextMenuFolder !== null) {
            if (this.state.functions.deleteFolder || this.state.functions.editFolder) {
                const menu = this.buildContextMenu();
                return (
                    <ContextMenu
                        dark={this.props.dark}
                        show={this.state.contextMenuFolder !== null}
                        onClose={() => this.setState({ contextMenuFolder: null })}
                        contextMenu={this.state.contextMenuFolder}
                        menuItems={menu}
                        id="folderCtx" />
                )
            }
        }

        return null;
    }
    folderDeleter = () => {
        return (
            <ItemDeleter
                dark={this.props.dark}
                user={this.props.user}
                handleClose={() => this.setState({ showConfirmDelete: false })}
                handleDelete={this.handleDeleteFolder}
                itemLabel={this.props.contentType === "lists" ? "List" : (this.props.contentType === "notes" ? "Notebook" : `${capitalize(this.props.contentType)} Folder`)}
                show={this.state.showConfirmDelete}
            />
        )
    }
    folderEditor = () => {
        if (this.state.selectedFolder && this.state.showEditFolder) {
            const fields = this.state.folderFields;
            for (var i = 0; i < fields.length; i++) {
                fields[i].value = this.state.selectedFolder[fields[i].fieldName];
            }
            return (
                <ItemCreator
                    dark={this.props.dark}
                    show={this.state.showEditFolder}
                    width={this.props.width}
                    user={this.props.user}
                    item={this.props.contentType === "lists" ? "List" : (this.props.contentType === "notes" ? "Notebook" : `${capitalize(this.props.contentType).slice(0, -1) } Folder`)}
                    handleClose={() => this.setState({ showEditFolder: false })}
                    handleSave={this.handleEditFolder}
                    fields={fields}
                    edit
                />
            )
        }
        return null;
    }
    folderCreator = () => {
        if (this.state.showNewFolder) {
            return (
                <ItemCreator
                    dark={this.props.dark}
                    show={this.state.showNewFolder}
                    width={this.props.width}
                    user={this.props.user}
                    item={this.props.contentType === "lists" ? "List" : (this.props.contentType === "notes" ? "Notebook" : `${capitalize(this.props.contentType).slice(0, -1) } Folder`)}
                    handleClose={() => this.setState({ showNewFolder: false })}
                    handleSave={this.handleNewFolder}
                    fields={this.state.folderFields}
                />
            )
        }
        return null;
    }

    render() {
        return (
            <>
                {this.content()}
                {this.contextMenuFolder()}
                {this.folderDeleter()}
                {this.folderEditor()}
                {this.folderCreator()}
            </>
        )
    }
}
