import React, { Component } from 'react';

//Custom Components
import { ModalDialog } from './ModalDialog';
import { Input } from './Input'

//Material UI
import Stack from '@mui/material/Stack';

export class ItemCreator extends Component {
    constructor(props) {
        super(props);
        this.state = {
            requiredFieldError: this.props.fields && this.props.fields.map(x => { return false }),
            validationFieldError: this.props.fields && this.props.fields.map(x => { return false }),
            fieldValues: this.props.fields && this.props.fields.map(x => { return x.value }),
            valid: true,
        }
    }

    setValid = (idx, valid) => {
        const validationFieldError = this.state.validationFieldError;
        validationFieldError[idx] = !valid;
        this.setState({ validationFieldError });
    }
    handleSave = () => {
        let valid = true;
        for (var i = 0; i < this.props.fields.length; i++) {
            const value = this.state.fieldValues[i];
            if ((!value) && this.props.fields[i].required) {
                const requiredFieldError = this.state.requiredFieldError;
                requiredFieldError[i] = true;
                this.setState({ requiredFieldError });
                valid = false;
            }
            else {
                if (this.state.validationFieldError[i]) {
                    valid = false;
                }
            }
        }   
        //If no errors, call save func from parent
        if (valid) {
            this.props.handleSave(this.state.fieldValues);
        }  
    }
    onBlur = (field, idx, value) => {
        if (field.onBlur) {
            field.onBlur(value);

        }
        if (value && this.state.requiredFieldError[idx]) {
            const requiredFieldError = this.state.requiredFieldError;
            requiredFieldError[idx] = false;
            this.setState({ requiredFieldError });
        }

    }
    onChange = (field, idx, value) => {
        //call any parent onchange handlers
        if (field.onChange) {
            field.onChange(field, idx, value);
        }

        //set the input value state
        const fieldValues = this.state.fieldValues;
        fieldValues[idx] = value;
        this.setState({ fieldValues });

        //if select control, remove any required field validation if now has a val
        if (field.type === "select") {
            if (value !== null && value !== -1 && this.state.requiredFieldError[idx]) {
                const requiredFieldError = this.state.requiredFieldError;
                requiredFieldError[idx] = false;
                this.setState({ requiredFieldError });
            }
        }
    }
    input = (field, idx) => {
        return (
            <Input
                key={idx + "inputField"}
                type={field.type}
                dark={this.props.dark}
                inputID={"field" + idx}
                label={field.label}
                defaultValue={field.defaultValue}
                format={field.format}
                value={this.props.edit ? this.state.fieldValues[idx] : field.defaultValue}
                onBlur={(value) => this.onBlur(field, idx, value)}
                onChange={(value) => this.onChange(field, idx, value)}
                onKeyPress={field.onKeyPress}
                autoFocus={field.autoFocus}
                required={field.required}
                maxLength={field.maxLength}
                minValue={field.minValue}
                maxValue={field.maxValue}
                listItems={field.listItems}
                allowNull={field.allowNull}
                validateOnBlur={field.validateOnBlur}
                setValid={(valid) => this.setValid(idx, valid)}
                requiredFieldError={this.state.requiredFieldError[idx]}
                helpText={field.helpText}
            />
            )
    }

    render() {
        return (
            <ModalDialog
                dark={this.props.dark}
                user={this.props.user}
                show={this.props.show}
                title={this.props.edit ? `Edit ${this.props.item}` : `New ${this.props.item}`}
                handleClose={this.props.handleClose}
                handleSave={this.handleSave}
                fullWidth={false}
                maxWidth="md"
                fullScreen={this.props.width < 600}
                fullHeight={this.props.width < 600}
                usePopper
            >
                <Stack sx={{ display: "flex", p: 2, width: "100%", minWidth: "300px" }} >
                    {this.props.fields.map((field, idx) => this.input(field, idx))}
                </Stack>
            </ModalDialog>
        )
    }
}