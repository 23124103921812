import React, { Component } from 'react';

//Custom Components
import { ModalDialog } from '../Utilities/ModalDialog'
import { ContextMenu } from '../Utilities/ContextMenu'
import { TextInput } from '../Utilities/TextInput'
import { SelectList } from '../Utilities/SelectList'
import { BusinessEditor } from '../Business/BusinessEditor'
import { UserAvatar } from '../Utilities/UserAvatar'
import { Group } from './Group'

//Database Services
import { getFamilies, newFamily, editFamily, deleteFamily } from '../../DataServices/FamilyService'
import { getGroups, newGroup, editGroup, deleteGroup, getGroupTypes } from '../../DataServices/GroupService'
import { getBusinesses, getBusinessTypes, getStateList, getUserAffiliations, newBusiness, getBusinessesByAdminUser } from '../../DataServices/BusinessService'
import { formatPhoneNumberInline, isValidURL } from '../../DataServices/Utility'

//Material UI
import Alert from '@mui/material/Alert';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { CardActionArea } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

//Helper Functions
const a11yProps = (index, id) => {
    return {
        id: `simple-tab-${index}-${id}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`affil-tabpanel-${index}`}
            aria-labelledby={`affil-tab-${index}`}
            {...other}
        >
            {value === index && children}
        </div>
    );
}


export class Affiliations extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dropDownLists: { businessTypes: [], states: [] },
            tabValue: 0,
            tabValueFamily: 0,
            showConfirmDeleteFamily: false,
            showConfirmDeleteFamilyMember: false,
            showEditFamily: false,
            showNewFamily: false,
            contextMenuFamily: null,
            contextMenuFamilyItems: [
                {
                    callBack: this.handleShowEditFamily,
                    label: "Edit"
                },
                {
                    callBack: this.handleShowConfirmDeleteFamily,
                    label: "Delete"
                },
            ],
            contextMenuFamily: null,
            tabValueFamily: 0,
            showNewFamily: false,
            showEditFamily: false,
            showConfirmDeleteFamily: false,
            contextMenuFamilyItems: [
                {
                    callBack: this.handleShowEditFamily,
                    label: "Edit"
                },
                {
                    callBack: this.handleShowConfirmDeleteFamily,
                    label: "Delete"
                },
            ],
            tabValueGroup: 0,
            selectedGroup: null,
            showNewGroup: false,
            contextMenuGroup: null,
            showConfirmDeleteGroup: false,
            contextMenuGroupItems: [
                {
                    callBack: this.handleShowEditGroup,
                    label: "Rename"
                },
                {
                    callBack: this.handleShowConfirmDeleteGroup,
                    label: "Delete"
                },
            ],
            showEditGroup: false,
            newGroupName: "",
            groupTypeID: 0,
            showNewBusiness: false,
            showBusinessEditor: false,
            typeValue: null,
            stateValue: null,
            followingLoaded: false,
            showGroup: false,
            selectedGroup: null,
            selectedGroupIDX: -1,
            businesses: []
        }
    }

    componentDidMount() {
        this.getFamilies();
        this.getGroups();
        this.getLists();
        this.getBusinesses();
        this.getAffiliations();
    }

    //Get Data----------------------
    getFamilies = async () => {
        const data = await getFamilies();
        if (data === 'error') {
            this.props.setToken('');
            return;
        }
        if (data) {
            this.setState({
                families: data
            });
        }
    }
    getGroups = async () => {
        const data = await getGroups();
        if (data === 'error') {
            this.props.setToken('');
            return;
        }
        if (data) {
            this.setState({
                groups: data
            });
        }
    }
    getMyBusinesses = async () => {
        const data = await getBusinesses();
        if (data) {
            this.setState({ businesses: data })
        }
    }
    getMyAdminBusinesses = async () => {
        const data = await getBusinessesByAdminUser();
        if (data) {
            const businesses = this.state.businesses;
            if (data.length > 0) {
                for (var i = 0; i < data.length; i++) {
                    if (!businesses.find(x => x.id === data[i].id)) {
                        businesses.push(data[i]);
                    }
                }
            }
            this.setState({ businesses });
        }
    }
    getBusinesses = async () => {
        await this.getMyBusinesses();
        await this.getMyAdminBusinesses();
        this.setState({  loading: false });

    }
    getAffiliations = async () => {
        const data = await getUserAffiliations();
        if (data && data !== 'error') {
            this.setState({
                affiliations: data,
            });
        }
        else {
            this.props.setToken('');
        }
    }
    getLists = async () => {
        const typeList = await getBusinessTypes();
        if (typeList) {
            this.setState(state => {
                const dropDownLists = state.dropDownLists;
                dropDownLists.businessTypes = typeList;
                return { dropDownLists };
            });
        }
        const states = await getStateList();
        if (states) {
            this.setState(state => {
                const dropDownLists = state.dropDownLists;
                dropDownLists.states = states;
                return { dropDownLists };
            });
        }
    }

    //Event Handlers-----------------
    //Global
    handleTabChange = (event, newValue) => {
        this.setState({ tabValue: newValue });
    }

    //Family
    handleTabChangeFamily = (event, newValue) => {
        this.setState({
            tabValueFamily: newValue,
            selectedFamily: this.state.families[newValue]
        });
    }
    handleShowNewFamily = () => {
        this.setState({
            showNewFamily: true,
            newFamilyName: "",
            contextMenuFamily: null
        });
    }
    handleShowEditFamily = () => {
        this.setState({
            showEditFamily: true,
            contextMenuFamily: null
        });
    }
    handleShowConfirmDeleteFamily = () => {
        this.setState({
            showConfirmDeleteFamily: true,
            contextMenuFamily: null
        });
    }
    handleContextMenuFamily= (e, id, idx) => {
        e.preventDefault();
        if (this.state.contextMenuFamily === null) {
            this.setState({
                contextMenuFamily: {
                    mouseX: e.clientX - 2,
                    mouseY: e.clientY - 4,
                },
                selectedFamilyID: id,
                tabValueFamily: idx
            })
        }
        else {
            this.setState({
                contextMenuFamily: null,
                listName: ""
            });
        }

    }
    handleNewFamily = async () => {
        var ele = document.getElementById("newfamily");
        if (ele) {
            const name = ele.value;
            if (name) {
                const meUser = this.props.user;
                meUser.avatarB64 = meUser.avatar;
                const id = await newFamily(name);
                var newitem = { id: id, familyName: name, members: [{ admin: true, user: meUser }] };
                this.setState(
                    state => {
                        state.groups.push(newitem);
                        return state;
                    }
                );
            }
        }
    }
    handleEditFamily = async (id, item) => {
        var ele = document.getElementById("editfamily");
        if (ele) {
            const name = ele.value;
            if (name) {
                const success = await editFamily(this.state.families[this.state.tabValueFamily].id, name);
                if (success) {
                    this.setState(
                        state => {
                            state.families[this.state.tabValueFamily].name = name;
                            state.showEdit = false;
                            return state;
                        }
                    );
                }
            }
            ele.value = "";
        }
    }
    handleDeleteFamily = async () => {
        const idx = this.state.tabValueFamily;
        const id = this.state.families[this.state.tabValueFamily].id;

        this.setState(
            state => {
                state.families.splice(idx, 1);
                state.showConfirmDeleteFamily = false;
                this.state.families.length > 0 ? state.tabValueFamily = 0 : state.tabValueFamily = null;
                return state;
            }
        );
        await deleteFamily(id);
    }
    handleClickFamily = (id, idx) => {

    }

    //Groups
    handleTabChangeGroup = (event, newValue) => {
        this.setState({
            tabValueGroup: newValue,
            selectedGroup: this.state.groups[newValue]
        });
    }
    handleShowNewGroup = () => {
        this.setState({
            showNewGroup: true,
            newGroupName: "",
            contextMenuGroup: null
        });
    }
    handleShowEditGroup = () => {
        this.setState({
            showEditGroup: true,
            contextMenuGroup: null
        });
    }
    handleShowConfirmDeleteGroup = () => {
        this.setState({
            showConfirmDeleteGroup: true,
            contextMenuGroup: null
        });
    }
    handleContextMenuGroup = (e, id, idx) => {
        e.preventDefault();
        if (this.state.contextMenuGroup === null) {
            this.setState({
                contextMenuGroup: {
                    mouseX: e.clientX - 2,
                    mouseY: e.clientY - 4,
                },
                selectedGroupID: id,
                tabValueGroup: idx
            })
        }
        else {
            this.setState({
                contextMenuGroup: null,
                listName: ""
            });
        }

    }
    handleNewGroup = async () => {
        var name = document.getElementById("newgroup").value;
        if (name) {
            const type = this.state.groupTypeID ?? 0;
            const meUser = this.props.user;
            meUser.avatarB64 = meUser.avatar;
            const id = await newGroup(name, type);
            var newitem = { id: id, name: name, members: [{ admin: true, user: meUser }] };
            this.setState(
                state => {
                    state.showNewGroup = false;
                    state.groups.push(newitem);
                    return state;
                }
            );
        }

    }
    handleEditGroup = async (id, item) => {
        var ele = document.getElementById("editgroup");
        if (ele) {
            const name = ele.value;
            if (name) {
                const success = await editGroup(this.state.groups[this.state.tabValueGroup].id, name);
                if (success) {
                    this.setState(
                        state => {
                            state.groups[this.state.tabValueGroup].name = name;
                            state.showEdit = false;
                            return state;
                        }
                    );
                }
            }
            ele.value = "";
        }
    }
    handleDeleteGroup = async () => {
        const idx = this.state.tabValueGroup;
        const id = this.state.groups[this.state.tabValueGroup].id;
        await deleteGroup(id);


        this.setState(
            state => {
                state.groups.splice(idx, 1);
                state.showConfirmDeleteGroup = false;
                this.state.groups.length > 0 ? state.tabValueGroup = 0 : state.tabValueGroup = null;
                return state;
            }
        );

    }
    handleClickGroup = (group, idx) => {
        this.setState({ selectedGroup: group, selectedGroupIDX: idx,showGroup: true });
    }
    //business
    handleClickBusiness = (id, idx, name) => {
        this.setState({
            selectedBusinessID: id,
            selectedBusinessIDX: idx,
            selectedBusinessName: name,
            showBusinessEditor: true
        })
    }
    handleDeleteBusiness = (id, idx) => {

    }
    handleSaveNewBusiness = async () => {

        const name = document.getElementById("fldName").value;
        const desc = document.getElementById("fldDesc").value;
        const type = this.state.typeValue;
        const website = document.getElementById("fldWebsite").value;
       // let phone = document.getElementById("fldPhone").value;
        let phone = this.state.phoneValue;
        if (phone) phone = phone.replace(/\D/g, '');
        const street = document.getElementById("fldStreet").value;
        const city = document.getElementById("fldCity").value;
        const state = this.state.stateValue &&  this.state.dropDownLists.states.find((x) => x.value === this.state.stateValue).label;
        const zip = document.getElementById("fldZip").value;
        const newBiz = await newBusiness(name, desc, type, street, city, state, zip, phone, website);
        this.setState(
            state => {
                const businesses = state.businesses.concat(newBiz);
                return {
                    businesses,
                    showNewBusiness: false,
                    typeValue: null,
                    stateValue: null
                };
            }
        );
        this.resetFormFields();
    }
    updateGroupMembers = (members, idx) => {
        let groups = this.state.groups;
        groups[idx].groupMembers = members;
        this.setState({ groups });
        this.forceUpdate();
    }
    updateGroupInvitedMembers = (members, idx) => {
        let groups = this.state.groups;
        groups[idx].invitedMembers = members;
        this.setState({ groups });
        this.forceUpdate();
    }
    handleStateChange = (e) => {
        this.setState({ stateValue: e });
    }
    handleTypeChange = (e) => {
        this.setState({ typeValue: e });
    }
    handlePhoneChange = (e) => {
        const formattedVal = formatPhoneNumberInline(e);
        this.setState({ phoneValue: formattedVal });
       // e.target.value = formattedVal;
    }
    handleWebsiteBlur = (e) => {
        const val = e;
        if (val) {
            if (!isValidURL(val)) {
                this.setState({ InputURLError: true });
            }
            else {
                this.setState({ InputURLError: false });
            }
        }
        else {
            this.setState({ InputURLError: false });
        }

    }
    resetFormFields = () => {
        document.getElementById("fldName").value = "";
        document.getElementById("fldDesc").value = "";
        document.getElementById("fldWebsite").value = "";
        document.getElementById("fldPhone").value = "";
        document.getElementById("fldStreet").value = "";
        document.getElementById("fldCity").value = "";
        document.getElementById("fldZip").value = "";
        this.setState({
            typeValue: 0,
            stateValue: 0,
            nameValue: null,
            descValue: null,
            websiteValue: null,
            phoneValue: null,
            streetValue: null,
            cityValue: null,
            zipValue: null,
        });
    }

    //Render Funcs
    userAvatar = (user, hideName) => {
        return (
            <Box key={new Date().toUTCString() + user.userID} sx={{ padding:"16px"}}>
                <UserAvatar
                    id={user.userID}
                    user={user}
                    dark={this.props.dark}
                    loaded={this.state.followingLoaded}
                    activeUser={this.props.user}
                    appIdentifier={this.props.appIdentifier}
                    hideName={hideName}
                />
            </Box>
        )
    }
    tabContainer = () => {
        return (
            <Box sx={{ width: '100%', height: { xs: "calc(100vh - 108px)", sm: '100%' }, minHeight: "600px", background: this.props.dark ? "#050505" : "#eee" }} className={this.props.dark && "dark"} >
                <Box sx={{ borderBottom: this.props.dark && "1px solid #151515", background: theme => this.props.dark ? "#151515" : theme.palette.secondary.main, boxShadow: this.props.dark ? 0 : 8 }} elevation={this.props.dark ? 0 : 8}>
                    <Tabs variant="scrollable" scrollButtons="auto" sx={{ minHeight: "36px", maxWidth: "fit-content", mx: "auto" }} allowScrollButtonsMobile indicatorColor="secondary" value={this.state.tabValue} onChange={(e, newValue) => this.setState({ tabValue: newValue })} aria-label="content tabs">
                        <Tab label="Family" {...a11yProps(0)} sx={{ paddingTop: "0px", paddingBottom: "0px", minHeight: "36px", fontSize: ".8rem", color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.white }} />
                        <Tab label="Groups" {...a11yProps(1)} sx={{ paddingTop: "0px", paddingBottom: "0px", minHeight: "36px", fontSize: ".8rem", color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.white }} />
                        <Tab label="Businesses" {...a11yProps(2)} sx={{ paddingTop: "0px", paddingBottom: "0px", minHeight: "36px", fontSize: ".8rem", color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.white }} />
                        <Tab label="Other Affiliations" {...a11yProps(3)} sx={{ paddingTop: "0px", paddingBottom: "0px", minHeight: "36px", fontSize: ".8rem", color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.white }} />
                    </Tabs>
                </Box>
                <TabPanel style={{ display: "contents" }} value={this.state.tabValue} index={0}>
                    {this.families()}
                </TabPanel>
                <TabPanel style={{ display: "contents" }} value={this.state.tabValue} index={1}>
                    {this.groups()}
                </TabPanel>
                <TabPanel style={{ display: "contents" }} value={this.state.tabValue} index={2}>
                    {this.businesses()}
                </TabPanel>
                <TabPanel style={{ display: "contents" }} value={this.state.tabValue} index={3}>
                    {this.other()}
                </TabPanel>
            </Box>
        )
    }
    sectionTitle = (title, tooltip,onClickAdd, noAction) => {
        return (
            <Box display="flex" sx={{ marginBottom: "8px" }}>
                {!noAction && <Box sx={{ width: "40px" }} />}
                <Box sx={{ flexGrow: 1 }} />
                <Typography variant="h5" sx={{ padding: noAction && "5px", margin:"auto", color: (theme) => this.props.dark ? theme.palette.common.white : theme.palette.common.dimblack }}>
                    {title}
                </Typography>
                <Box sx={{ flexGrow: 1 }} />
                {!noAction &&
                    <Tooltip title={tooltip}>
                        <IconButton aria-label={title} onClick={onClickAdd} sx={{ color: (theme) => this.props.dark ? theme.palette.common.white : theme.palette.common.dimblack }}>
                            <AddIcon />
                        </IconButton>
                    </Tooltip>
                }
            </Box>
            )
    }

    //business
    businesses = () => {
        if (this.state.businesses) {
            return (
                <Box sx={{ p:2, width: "100%", height: "100%", }} className="prettyScroll">
                    {this.sectionTitle("Businesses", "New Business", () => this.setState({ showNewBusiness: true }))}
                    <Stack spacing={2} sx={{ p: 1 }} >
                        {this.state.businesses.map((item, index) => {
                            return this.businessCard(item, index);
                        })}
                    </Stack>               
                </Box>
            )
        }
        return null;
    }
    businessEditor = () => {
        if (this.state.selectedBusinessID) {
            return (
                <ModalDialog
                    dark={this.props.dark}
                    show={this.state.showBusinessEditor}
                    title={"Business Manager - " + this.state.selectedBusinessName}
                    handleClose={() => this.setState({ showBusinessEditor: false })}
                    fullWidth={true}
                    fullScreen={true}
                    solid={true}
                    hideScroll={true}
                    fullHeight
                    disableButtons
                    maxWidth="xl"
                    noPad
                >
                    < BusinessEditor
                        dark={this.props.dark}
                        businessID={this.state.selectedBusinessID}
                        businessName={this.state.selectedBusinessName}
                        dropDownLists={this.state.dropDownLists}
                        handleClose={() => this.setState({ showBusinessEditor: false })}
                        editorLoaded={() => this.setState({ editorLoaded: true })}
                        appIdentifier={this.props.appIdentifier}
                        user={this.props.user}
                    />
                </ModalDialog>
            )
        }
        return null;
    }
    businessAdder = () => {
        return (
            <ModalDialog
                {...this.props}
                show={this.state.showNewBusiness}
                title="New Business"
                subTitle="Provide the following information to set up your business in Livesphere.  We will add further details later."
                handleClose={() => this.setState({ showNewBusiness: false })}
                handleSave={this.handleSaveNewBusiness}
                fullWidth={true}
                maxWidth="sm"
                closeLabel="Close"

            >
                <Box sx={{ background: this.props.dark ? "#151515" : "#ddd", borderRadius: "8px", padding: "8px" }}>
                    <TextInput
                        {...this.props}
                        cKey="CnewBusinessName"
                        cid="CnewBusinessName"
                        id="fldName"
                        label="Business Name"
                        defaultValue={this.state.nameValue}
                    />
                    <TextInput
                        {...this.props}
                        cKey="CnewBusinessDesc"
                        cid="CnewBusinessDesc"
                        id="fldDesc"
                        label="Business Description"
                        defaultValue={this.state.descValue}
                    />
                    <SelectList
                        dark={this.props.dark}
                        key="etype"
                        label="Business Type"
                        value={this.state.typeValue}
                        onChange={this.handleTypeChange}
                        listItems={this.state.dropDownLists.businessTypes}
                    />

                    <TextInput
                        {...this.props}
                        cKey="CnewWebsite"
                        cid="CnewWebsite"
                        id="fldWebsite"
                        label="Website"
                        defaultValue={this.state.websiteValue}
                        onBlur={this.handleWebsiteBlur}
                    />
                    {this.state.InputURLError &&
                        <Alert severity="error">Not a valid web address</Alert>
                    }
                    <TextInput
                        dark={this.props.dark}
                        cKey="phoneNumber"
                        cid="phoneNumberF"
                        id="fldPhone"
                        label="Main Phone"
                        defaultValue={this.state.phoneValue}
                        onChange={this.handlePhoneChange}
                    />
                    <TextInput
                        dark={this.props.dark}
                        cKey="street"
                        cid="streetF"
                        id="fldStreet"
                        label="Street Address"
                        defaultValue={this.state.streetValue}
                    />
                    <TextInput
                        dark={this.props.dark}
                        cKey="city"
                        cid="cityF"
                        id="fldCity"
                        label="City"
                        defaultValue={this.state.cityValue}
                    />
                    <SelectList
                        dark={this.props.dark}
                        key="eState"
                        label="State"
                        value={this.state.stateValue}
                        onChange={this.handleStateChange}
                        listItems={this.state.dropDownLists.states}
                    />
                    <TextInput
                        dark={this.props.dark}
                        cKey="zip"
                        cid="zipF"
                        id="fldZip"
                        label="Zip"
                        defaultValue={this.state.zipValue}
                        inputProps={{ id: "newZip", maxLength: 5, pattern: "[0-9]+" }}
                        onChange={this.handleNumbersOnly}
                    />
                </Box>
            </ModalDialog>

        )
    }
    businessCard = (item, index) => {
        return (
            <Card key={"b" + index}  sx={{ width: "100%", background: (theme) => this.props.dark && theme.palette.card.dark, color: (theme) => this.props.dark && theme.palette.common.dimwhite }} raised elevation={this.props.dark ? 1 : 8}>
                <CardActionArea onClick={() => this.handleClickBusiness(item.id, index, item.businessName)} sx={{ p: 1}}>
                    <CardContent>
                        <Typography gutterBottom variant="subtitle1" component="div" align="center">
                            {item.businessName}
                        </Typography>
                        <Typography variant="subtitle2" sx={{ fontSize: ".8rem", marginBottom: "8px" }} align="center" color="secondary">
                            {item.description}
                        </Typography>
                        <Typography variant="body2" sx={{ fontSize: ".8rem", marginBottom: "8px" }}  align="center">
                            {item.website.replace('http://', '').replace('https://','')}
                        </Typography>
                        <Typography variant="body2" sx={{ fontSize: ".7rem" }}  align="center">
                            {item.streetAddress}
                        </Typography>
                        <Typography variant="body2" sx={{ fontSize: ".7rem" }}  align="center">
                            {`${item.city}, ${item.state}  ${item.zip}`}
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
        )
    }

    //groups
    groupCreator = () => {
        if (this.state.showNewGroup) {
            return (
                <ModalDialog
                    dark={this.props.dark}
                    show={this.state.showNewGroup}
                    title="New Group"
                    handleClose={() => this.setState({ showNewGroup: false })}
                    handleSave={this.handleNewGroup}
                    fullWidth={true}
                    maxWidth="sm"
                >
                    <Box sx={{ background: this.props.dark ? "#151515" : "#ddd", borderRadius: "8px", padding: "8px" }}>
                        <TextInput
                            dark={this.props.dark}
                            cKey="Cnnewgroup"
                            cid="Cnewgroup"
                            id="newgroup"
                            label="Group Name"
                            defaultValue=""
                        />
                        <SelectList
                            dark={this.props.dark}
                            key="gtype"
                            label="Group Type"
                            value={this.state.groupTypeID}
                            onChange={(e) => this.setState({ groupTypeID: e.target.value })}
                            listItems={getGroupTypes()}
                        />
                    </Box>
                </ModalDialog>
            )
        }
        return null;
    }
    groupCard = (item, index) => {
        return (
            <Card key={"g" + index} sx={{ width:"100%", background: (theme) => this.props.dark && theme.palette.card.dark, color: (theme) => this.props.dark && theme.palette.common.dimwhite }} raised elevation={this.props.dark ? 1 : 8}>
                <CardActionArea onClick={() => this.handleClickGroup(item, index)} onContextMenu={(e) => this.handleContextMenuGroup(e, item.id, index)} sx={{ p: 1 }}>
                    <CardContent>
                        <Typography gutterBottom variant="subtitle1" component="div" align="center">
                            {item.name}
                        </Typography>
                        <Typography variant="subtitle2" sx={{ fontSize: ".8rem", marginBottom: "8px" }} align="center" color="secondary">
                            {item.type}
                        </Typography>
                        <Typography variant="body2" sx={{ fontSize: ".7rem" }} align="center">
                            {(item.groupMembers && item.groupMembers.length > 0) ? item.groupMembers.length + (item.groupMembers.length == 1 ? " member" : " members") : "No members"}
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
        )
    }
    groups = () => {
        if (this.state.groups) {
            return (
                <Box sx={{ p:2, width: "100%", height: "100%", }} className="prettyScroll">
                    {this.sectionTitle("My Groups", "New Group", () => this.setState({ showNewGroup: true })) }
                    <Stack spacing={2} sx={{ p: 1 }} >
                        {this.state.groups.map((item, index) => {
                            return this.groupCard(item, index);
                        }) }
                    </Stack>
                    {this.state.selectedGroup && this.groupManager()}
                </Box>
            )
        }
        return null;
    }
    groupDeleteConfirmation = () => {
        return (
            <ModalDialog show={this.state.showConfirmDeleteGroup}
                dark={this.props.dark}
                title="Confirm Deletion"
                subTitle={"Group will be deleted.  Proceed?"}
                handleClose={() => this.setState({ showConfirmDeleteGroup: false })}
                handleDelete={this.handleDeleteGroup}
                fullWidth={false}
                maxWidth="sm"
            />
        )

    }
    groupContextMenu = () => {
        return (
            <ContextMenu show={this.state.contextMenuGroup !== null}
                dark={this.props.dark}
                onClose={() => this.setState({ contextMenuGroup: null })}
                contextMenu={this.state.contextMenuGroup}
                menuItems={this.state.contextMenuGroupItems}
                id="Groupctx" />
        )
    }
    groupEditor = () => {
        if (this.state.showEditGroup) {
            return (
                <ModalDialog
                    dark={this.props.dark}
                    show={this.state.showEditGroup}
                    title="Edit Group Name"
                    handleClose={() => this.setState({ showEditGroup: false })}
                    handleSave={this.handleEditGroup}
                    fullWidth={true}
                    maxWidth="sm"
                >
                    <Box sx={{ background: this.props.dark ? "#151515" : "#ddd", borderRadius: "8px", padding: "8px" }}>
                        <TextInput
                            dark={this.props.dark}
                            cKey="Ceditgroup"
                            cid="Ceditgroup"
                            id="editgroup"
                            label="Group Name"
                            defaultValue={this.state.groups[this.state.tabValueGroup].groupName}
                        />
                    </Box>
                </ModalDialog>
            )
        }
        return null;
    }
    groupManager = () => {
        if (this.state.showGroup && this.state.selectedGroup) {
            return (
                <ModalDialog
                    dark={this.props.dark}
                    show={this.state.showGroup}
                    title="Group Manager"
                    handleClose={() => this.setState({ showGroup: false })}
                    fullWidth={true}
                    fullScreen={false}
                    hideScroll={true}
                    fullHeight
                    disableButtons
                    maxWidth="lg"
                    noPad
                >
                    <Group
                        appIdentifier={this.props.appIdentifier}
                        user={this.props.user}
                        dark={this.props.dark}
                        group={this.state.selectedGroup}
                        idx={this.state.selectedGroupIDX}
                        updateGroupMembers={this.updateGroupMembers}
                        updateGroupInvitedMembers={this.updateGroupInvitedMembers}
                    />
                </ModalDialog>
            )
        }
        return null;
    }

    //family
    families = () => {
        if (this.state.families) {
            return (
                <Box sx={{ p:2, width: "100%", height: "100%", }} className="prettyScroll">
                    {this.sectionTitle("My Families", "New Family", () => this.setState({ showNewFamily: true }))}
                    <Stack spacing={2} sx={{ p: 1 }} >
                        {this.state.families.map((item, index) => {
                            return this.familyCard(item, index);
                        })}
                    </Stack>
                </Box>
            )
        }
        return null;
    }
    familyDeleteConfirmation = () => {
        return (
            <ModalDialog show={this.state.showConfirmDeleteFamily}
                dark={this.props.dark}
                title="Confirm Deletion"
                subTitle={"Family will be deleted.  Proceed?"}
                handleClose={() => this.setState({ showConfirmDeleteFamily: false })}
                handleDelete={this.handleDeleteFamily}
                fullWidth={false}
                maxWidth="sm"
            />
        )

    }
    familyContextMenu = () => {
        return (
            <ContextMenu show={this.state.contextMenuFamily !== null}
                dark={this.props.dark}
                onClose={() => this.setState({ contextMenuFamily: null })}
                contextMenu={this.state.contextMenuFamily}
                menuItems={this.state.contextMenuFamilyItems}
                id="Familyctx" />
        )
    }
    familyEditor = () => {
        if (this.state.showEditFamily) {
            return (
                <ModalDialog
                    dark={this.props.dark}
                    show={this.state.showEditFamily}
                    title="Edit Family Name"
                    handleClose={() => this.setState({ showEditFamily: false })}
                    handleSave={this.handleEditFamily}
                    fullWidth={true}
                    maxWidth="sm"
                >
                    <Box sx={{ background: this.props.dark ? "#151515" : "#ddd", borderRadius: "8px", padding: "8px" }}>
                        <TextInput
                            dark={this.props.dark}
                            cKey="Ceditfamily"
                            cid="Ceditfamily"
                            id="editfamily"
                            label="Family Name"
                            defaultValue={this.state.families[this.state.tabValueFamily].familyName}
                        />
                    </Box>
                </ModalDialog>
            )
        }
        return null;
    }
    familyCreator = () => {
        return (
            <ModalDialog
                dark={this.props.dark}
                show={this.state.showNewFamily}
                title="New Family"
                handleClose={() => this.setState({ showNewFamily: false })}
                handleSave={this.handleAddFamily}
                fullWidth={true}
                maxWidth="sm"
            >
                <Box sx={{ background: this.props.dark ? "#151515" : "#ddd", borderRadius: "8px", padding: "8px" }}>
                    <TextInput
                        dark={this.props.dark}
                        cKey="Cnnewfamily"
                        cid="Cnewfamily"
                        id="newfamily"
                        label="Family Name"
                        defaultValue=""
                    />
                </Box>
            </ModalDialog>
        )
    }
    familyCard = (item, index) => {
        return (
            <Card key={"f" + index} sx={{ width: "100%", background: (theme) => this.props.dark && theme.palette.card.dark, color: (theme) => this.props.dark && theme.palette.common.dimwhite }} raised elevation={this.props.dark ? 1 : 8}>
                <CardActionArea onClick={() => this.handleClickFamily(item.id, index)} onContextMenu={(e) => this.handleContextMenuFamily(e, item.id, index)} sx={{ p: 1 }}>
                    <CardContent>
                        <Typography gutterBottom variant="subtitle1" component="div" align="center">
                            {item.familyName}
                        </Typography>
                        <Typography variant="body2" sx={{ fontSize: ".7rem" }} align="center">
                            {(item.members && item.members.length > 0) ? item.members.length + (item.members.length == 1 ? " member" : " members") : "No members"}
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
        )
    }

    //other affiliations
    other = () => {
        if (this.state.affiliations) {
            return (
                <Box sx={{ p: 2, width: "100%", height: "100%", }} className="prettyScroll" >
                    {this.sectionTitle((this.state.affiliations && this.state.affiliations.length > 0) ? "My Affiliations to Other Businesses" : "No Current Affilations to Other Businesses", "", null, true)}
                    <Stack spacing={2} sx={{ p: 1 }} >
                        {this.state.affiliations.map((item, index) => {
                            return this.otherCard(item, index);
                        })}
                    </Stack>
                </Box>
            )
        }
        return null;
    }
    otherCard = (item, index) => {
        return (
            <Card key={"o" + index} sx={{ width: "100%", background: (theme) => this.props.dark && theme.palette.card.dark, color: (theme) => this.props.dark && theme.palette.common.dimwhite }} raised elevation={this.props.dark ? 1 : 8}>
                <CardActionArea sx={{ p: 1 }}>
                    <CardContent>
                        <Typography gutterBottom variant="subtitle1" component="div" align="center">
                            {item.targetName}
                        </Typography>
                        <Typography variant="body2" sx={{ fontSize: ".7rem" }} align="center">
                            {item.desc}
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
        )
    }

    //main component render
    render() {
        return (
            <>
                {this.tabContainer()}
                {this.familyDeleteConfirmation()}
                {this.familyContextMenu()}
                {this.familyEditor()}
                {this.familyCreator()}
                {this.groupDeleteConfirmation()}
                {this.groupContextMenu()}
                {this.groupEditor()}
                {this.groupCreator()}
                {this.businessEditor()}
                {this.businessAdder()}
            </>
        )
    }
}
