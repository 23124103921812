
export async function getPage(id) {
    var url = "webpage/getpage?id=" + id;
    const response = await fetch(url);
    if (response.status !== 200) 
        return "error";
    const data = await response.json();
    if (data)
        return data;
    return null;
}



export async function addSection(pageid, name, title, subtitle, containertype) {
    const req = {
        PageID: pageid,
        Name: name,
        Title: title,
        Subtitle: subtitle,
        ContainerType: containertype ?? 0
    };
    const response = await fetch("webpage/addsection", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const id = await response.json();
        return id;
    }
    return null;
}
export async function editSection(pageid, name, title, subtitle, containertype) {
    const req = {
        PageID: pageid,
        Name: name,
        Title: title,
        Subtitle: subtitle,
        ContainerType: containertype ?? 0
    };
    const response = await fetch("webpage/editsection", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const id = await response.json();
        return id;
    }
    return null;
}
export async function addContent(pageid, sectionid, contenttypeid, refid, enabled, title, subtitle, previewtext, photoid) {
    const req = {
        PageID: pageid,
        SectionID: sectionid,
        ContentTypeID: contenttypeid,
        RefID: refid,
        enabled: enabled,
        Title: title,
        Subtitle: subtitle,
        PreviewText: previewtext,
        PhotoID: photoid
    };
    const response = await fetch("webpage/addcontent", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const id = await response.json();
        return id;
    }
    return null;
}

export async function editContent(id, title, subtitle, previewtext) {
    const req = {
        ID: id,
        Title: title,
        Subtitle: subtitle,
        PreviewText: previewtext,
    };
    const response = await fetch("webpage/editcontent", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const id = await response.json();
        return id;
    }
    return null;
}
export async function setContentPhoto(id, photoid) {
    const req = {
        ID: id,
        PhotoID: photoid
    };
    const response = await fetch("webpage/setcontentphoto", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const id = await response.json();
        return id;
    }
    return null;
}
export async function moveContent(id, index) {
    const req = {
        ID: id,
        Index: index
    };
    const response = await fetch("webpage/movecontent", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const result = await response.json();
        return result;
    }
    return false;
}

export async function deleteContent(id) {
    const req = {
        ID: id,
    };
    const response = await fetch("webpage/deletecontent", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const result = await response.json();
        return result;
    }
    return false;
}

export async function enableContent(id) {
    const req = {
        ID: id,
    };
    const response = await fetch("webpage/enablecontent", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const result = await response.json();
        return result;
    }
    return false;
}

export async function disableContent(id) {
    const req = {
        ID: id,
    };
    const response = await fetch("webpage/disablecontent", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const result = await response.json();
        return result;
    }
    return false;
}

export async function getContactFormData(pageid, status) {
    if (status !== 0 && !status) {
        status = -1;
    }
    var url = "webpage/getcontactformdata?pageid=" + pageid + "&status=" + status;
    const response = await fetch(url);
    if (response.status !== 200)
        return "error";
    const data = await response.json();
    if (data)
        return data;
    return null;
}

export async function addContactFormSubmission(pageid, first, last, email, ip, comment) {
    const req = {
        PageID: pageid,
        FirstName: first,
        LastName: last,
        Email: email,
        IpAddress: ip,
        Comment: comment
    };
    const response = await fetch("webpage/addcontactformsubmission", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const result = await response.json();
        return result;
    }
    return false;
}
export async function updateContactFormSubmissionStatus(id, status) {
    const req = {
        ID: id,
        PageID: id,
        Status: status
    };
    const response = await fetch("webpage/updatecontactformsubmissionstatus", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const result = await response.json();
        return result;
    }
    return false;
}
export async function getPageUsers(pageid) {
    var url = "webpage/getpageusers?pageid=" + pageid;
    const response = await fetch(url);
    if (response.status !== 200)
        return "error";
    const data = await response.json();
    if (data)
        return data;
    return null;
}
export async function getPageAdmins(pageid) {
    var url = "webpage/getpageadmins?pageid=" + pageid;
    const response = await fetch(url);
    if (response.status !== 200)
        return "error";
    const data = await response.json();
    if (data)
        return data;
    return null;
}
export async function setUserType(userid, usertype) {
    const req = {
        UserID: userid,
        UserType: usertype
    };
    const response = await fetch("webpage/setusertype", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        return await response.json();
    }
    return null;
}
export async function getContactNotificationRecipients(pageid) {
    var url = "webpage/getcontactnotificationrecipients?pageid=" + pageid;
    const response = await fetch(url);
    if (response.status !== 200)
        return "error";
    const data = await response.json();
    if (data)
        return data;
    return null;
}

export async function addContactNotificationRecipient(pageid,email) {
    const req = {
        PageID: pageid,
        Email: email,
    };
    const response = await fetch("webpage/addcontactnotificationrecipient", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const result = await response.json();
        return result;
    }
    return false;
}
export async function removeContactNotificationRecipient(id) {
    const req = {
        ID: id,
    };
    const response = await fetch("webpage/removecontactnotificationrecipient", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(req)
    })
    if (response.status === 200) {
        const result = await response.json();
        return result;
    }
    return false;
}