import { deleteLibrary, editLibrary, newLibrary, movePhoto, deletePhoto } from '../DataServices/PhotoService';

const itemFields = []

const folderFields = [
    {
        type: "text",
        label: "Folder Name",
        fieldName: "name",
        value: "",
        maxLength: 100,
        required: true
    },
]

const functions = {
    createItem: null,
    editItem: null,
    deleteItem: deletePhoto,
    moveItem: movePhoto,
    createFolder: newLibrary,
    editFolder: editLibrary,
    deleteFolder: deleteLibrary,
}
const options = {
    notify: true,
    noItemClick: true,
    edit: true,
    delete: true,
    share: true,
    post: true,
    copy: true
}
const photoProperties = {
    itemFields: itemFields,
    folderFields: folderFields,
    functions: functions,
    options: options
}

export default photoProperties;

