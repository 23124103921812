import React, { useState } from 'react';
import { Switch, Route } from "react-router-dom";

//Data Services
import { getPhotoThumb } from './components/DataServices/PhotoService'
import { getUserInfo, getUserTheme, setUserTheme } from './components/DataServices/UserService'

//Livesphere components
import { Layout } from './components/Common/Layout/Layout';
import { Home } from './components/Common/Layout/Home';
import Login from './components/SiteSpecific/Login';
import ChangePassword from './components/SiteSpecific/ChangePassword';
import useToken from './components/Common/Helpers/useToken';
import { Spin } from './components/Common/Utilities/Spin';

//MUI
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import ThemeConfig from './theme';

//Styles
import './Styles/common.css';
import './Styles/datagrid.css'

function App() {
    const appIdentifier = "LS";
    const webPageID = "livesphere";
    const appOptions = [
//        "weather",
    ];
    const getLocalUserInfo = () => {
        const localUserString = localStorage.getItem("user");
        let user;
        if (localUserString) {
            try {
                user = JSON.parse(localUserString);
            } catch (e) {
                user = null;
            }
        }

        return user;
    }
    const { token, setToken } = useToken(appIdentifier);
    const [loaded, setLoaded] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [passwordResetRequest, setPasswordResetRequest ] = useState();
    const [userInfo, setUserInfo] = useState(getLocalUserInfo() ?? null);
    const [dark, setDark] = useState(true);
    const [photoRepo, setPhotoRepo] = useState();
    const setTheme = async (isDark) => {
        let theme = "light";
        if (isDark) {
            theme = "dark";
            setDark(true);
            localStorage.setItem("theme", "dark");
        }
        else {
            setDark(false);
            localStorage.setItem("theme", "light");
        }
        await setUserTheme(theme);
    }
    const getTheme = async () => {
        const localTheme = localStorage.getItem("theme");
        let theme;
        if (!localTheme || (localTheme !== "dark" && localTheme != "light")) {
            theme = await getUserTheme();
            localStorage.setItem("theme", theme === "light" ? "light" : "dark");
        }
        else {
            theme = localTheme;
        }
      
        theme !== "light" ? setDark(true) : setDark(false);
    }
    const getUser = async () => {
        let user = getLocalUserInfo();
        if (!user) {
            user = await getUserInfo(appIdentifier);
            if (user.success) {
                let userString = JSON.stringify(user);
                localStorage.setItem("user", userString);
            }
            else {
                user = null;
            }
        }
        setUserInfo(user);
    }
    const setUserInfoCallback = (user) => {
        let userString = JSON.stringify(user);
        try {
            localStorage.setItem("user", userString);
        }
        catch (err) {
            console.log(err);
        }

        setUserInfo(user);

    }
    const getPhoto = async (id, size) => {
        let photo = photoRepo.find((x) => x.photoID === id);
        const idx = photoRepo.indexOf(photo);
        if (photo) {
            if (size === "full") {
                if (photo.fullPhoto) {
                    return photo;
                }
                else {
                    photo = await getPhoto(photo.photoID);
                    photoRepo.splice(idx, 1);
                    photoRepo.push(photo);
                    return photo;
                }
            }
            else {
                if (photo.thumb) {
                    return photo;
                }
                else {
                    const thumb = await getPhotoThumb(photo.photoID);
                    if (thumb) {
                        photoRepo[idx].thumb = thumb.thumb;
                    }
                    return photoRepo[idx];
                }
            }
        }
        else {
            if (size === "full") {
                photo = await getPhoto(id)
                photoRepo.push(photo);
                return photo;
            }
            else {
                photo = await getPhotoThumb(id)
                photoRepo.push(photo);
                return photo;
            }

        }
    }
    const [topic, setTopic] = useState('posts');
    const appHeight = () => {
        const doc = document.documentElement
        doc.style.setProperty('--app-height', `${window.innerHeight}px`)
    }
    window.addEventListener('resize', appHeight)
    appHeight();

    //const [searchParams, setSearchParams] = useSearchParams();

    if (!loaded) {
        if(!userInfo || userInfo.userID === null) getUser();
        getTheme();
        setPhotoRepo([]);
        setLoaded(true);
    }

    return (
        <ThemeConfig>
            <Switch>
                <Route exact  path="/passwordreset/:id" render={(props) => <ChangePassword  {...props} request={passwordResetRequest} setRequest={setPasswordResetRequest} setToken={setToken} />} />
                {(!token || !userInfo || !userInfo.accessToken) ?
                    <Login setToken={setToken} setUserInfo={setUserInfoCallback} setDarkMode={setDark} appIdentifier={appIdentifier} />
                    :
                    userInfo ?
                        <Layout
                            appIdentifier={appIdentifier}
                            setToken={setToken}
                            setUserInfo={setUserInfoCallback}
                            user={userInfo}
                            dark={dark}
                            setDark={setTheme}
                            getPhoto={getPhoto}
                            searchTerm={searchTerm}
                            setSearchTerm={setSearchTerm}
                            topic={topic}
                            setTopic={setTopic}
                            appOptions={appOptions}
                        >
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <Home
                                    appIdentifier={appIdentifier}
                                    webPageID={webPageID}
                                    setToken={setToken}
                                    dark={dark}
                                    user={userInfo}
                                    searchTerm={searchTerm}
                                    topic={topic}
                                    setTopic={setTopic}
                                />
                 
                            </MuiPickersUtilsProvider>
                        </Layout>
                        :
                        <Spin open={true} />
                }
            </Switch>
        </ThemeConfig>
    );
}

export default App;


