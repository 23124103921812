import React, { Component } from 'react';

//Custom Components
import { ModalDialog } from '../Utilities/ModalDialog'
import { ItemDeleter} from '../Utilities/ItemDeleter'

//MaterialUI
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { DataGrid, GridToolbar, GridToolbarContainer } from '@mui/x-data-grid';

//Database Services
import { getContactFormData, updateContactFormSubmissionStatus } from '../../DataServices/WebPageService'
import { removeFromArrayByID } from '../../DataServices/Utility'
//Helper functions


export class ContactFormData extends Component {

  constructor(props) {
      super(props);
      this.state = {
          anchorEl: null,
          showConfirmDelete: false,
          showMessage: false,

      };
  }

    componentDidMount() {
        this.getContactFormData();

    }
    handleSelectClient = (e) => {
        const selectedMessage = this.state.contactFormData.find(x => x.id === e.id);
        if (selectedMessage) {
            this.setState({ selectedMessage, showMessage: true })
        }
    }

    getContactFormData = async () => {
        const contactFormData = await getContactFormData(this.props.appID, 0);
        if (contactFormData) {
            this.setState({ contactFormData });
        }
    }
    handleShowMenu = (e, params) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({ selectedID: params.id, anchorEl: e.currentTarget });
    }
    delete = async () => {
        const response = await updateContactFormSubmissionStatus(this.state.selectedID, 2);
        if (response) {
            let contactFormData = [...this.state.contactFormData];
            contactFormData = removeFromArrayByID(contactFormData, this.state.selectedID);
            this.setState({ contactFormData, showConfirmDelete: false });
            return;
        }

    }
    //Render Funcs



    messageRow = (item, idx) => {
        return { id: item.id, Status: item.status, Sent: new Date(item.createDate).toLocaleDateString(), Name: item.firstName + " " + item.lastName, email: item.email, comment: item.comment }
    }
    contactFormData = () => {
        const columns = [
            { field: 'id', headerName: 'ID', width: 0, hide: true },
            { field: 'Status', headerName: 'Status', width: 100, flex: 1 },
            { field: 'Sent', headerName: 'Sent', width: 100, flex: 1 },
            { field: 'Name', headerName: 'Name', width: 100, flex: 1 },
            { field: 'email', headerName: 'Email', width: 100, flex: 1 },
            { field: 'comment', headerName: 'Message', width: 200, flex: 3 },
            {
                field: 'actions', headerName: '', renderCell: (params) => (
                    <>
                        <IconButton size="small" onClick={(e) => this.handleShowMenu(e, params)}>
                            <MoreHorizIcon sx={{ color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.dimblack }} />
                        </IconButton>
                        <Menu
                            open={this.state.anchorEl !== null}
                            onClose={() => this.setState({ anchorEl: null })}
                            anchorEl={this.state.anchorEl}
                            PaperProps={{ elevation: 1, sx: { backgroundColor: (theme) => this.props.dark ? theme.palette.backgroundModal.dark : theme.palette.backgroundModal.light, color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.dimblack } }}
                            MenuListProps={{ sx: { backgroundColor: (theme) => this.props.dark ? theme.palette.backgroundModal.dark : theme.palette.backgroundModal.light, color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.dimblack } }}
                        >
                            <MenuItem onClick={() => this.setState({ showConfirmDelete: true, anchorEl: null })}>Delete</MenuItem>
                        </Menu>
                    </>
                ),
            },
        ];
        if (this.state.contactFormData) {
            const rows = this.state.contactFormData.map((item, index) => {
                return this.messageRow(item, index)
            })

            return (
                <Stack style={{ width: "100%" }} className={this.props.dark && "dark"} >
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        columnVisibilityModel={{
                            ID: false,
                        }}
                        density="compact"
                        pageSize={20}
                        onRowClick={this.handleSelectClient}
                    />
                </Stack>
            )
        }
        return null;
    }
    messageViewer = () => {
        if (this.state.selectedMessage) {
            return (
                <ModalDialog
                    dark={this.props.dark}
                    user={this.props.user}
                    show={this.state.showMessage}
                    title={"Message from " + this.state.selectedMessage.firstName + " " + this.state.selectedMessage.lastName}
                    handleClose={() => this.setState({ showMessage: false })}
                    fullWidth={false}
                    maxWidth="sm"
                >
                    <Box sx={{ display: "flex", px: 3, py:1, width: "100%", fontWeight: 500, fontSize: { xs: ".75rem", sm: ".8rem" }, color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.black }}>
                        <Stack>
                            <Typography sx={{ fontWeight: 500, fontSize: { xs: ".75rem", sm: ".8rem" }, color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.black }}>
                                {"Sent:  " + new Date(this.state.selectedMessage.createDate).toLocaleDateString() + " " + new Date(this.state.selectedMessage.createDate).toLocaleTimeString()}
                            </Typography>
                            <Typography sx={{ fontWeight: 500, fontSize: { xs: ".75rem", sm: ".8rem" }, color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.black }}>
                                {"From:  " + this.state.selectedMessage.firstName + " " + this.state.selectedMessage.lastName}
                            </Typography>
                            <Typography sx={{ fontWeight: 500, fontSize: { xs: ".75rem", sm: ".8rem" }, color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.black }}>
                                {"Email:  " + this.state.selectedMessage.email}
                            </Typography>
                            <Typography sx={{ fontWeight: 500, fontSize: { xs: ".75rem", sm: ".8rem" }, color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.black }}>
                                {"IP Address:  " + this.state.selectedMessage.ipAddress}
                            </Typography>
                            <Typography sx={{ fontWeight: 500, fontSize: { xs: ".75rem", sm: ".8rem" }, color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.black }}>
                                {"Email:  " + this.state.selectedMessage.email}
                            </Typography>
                            <hr/>
                            <Typography sx={{ fontWeight: 500, fontSize: { xs: ".75rem", sm: ".8rem" }, color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.black }}>
                                {this.state.selectedMessage.comment}
                            </Typography>
                        </Stack>
                    </Box>
                </ModalDialog>
            )
        }
        return null;
    }
    deleter = () => {
        if (this.state.showConfirmDelete) {
            return (
                <ItemDeleter
                    show={this.state.showConfirmDelete}
                    handleClose={() => this.setState({ showConfirmDelete: false })}
                    dark={this.props.dark}
                    user={this.props.user}
                    itemLabel="Message"
                    handleDelete={this.delete}
                />
                )
        }
        return null;
    }


    //main component render
    render() {
        return (
            <>
                {this.contactFormData()}
                {this.deleter()}
                {this.messageViewer()}
            </>
            )

    }
}