import React, { Component } from 'react';

//Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolder, faFolderOpen, faList, faShare } from '@fortawesome/free-solid-svg-icons';

//Material UI
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';


export class FolderLabel extends Component {
    //'#fbc02d' - yellow
    render() {
        return (
            <Box display="flex" sx={{ width: "100%", textAlign: "left" }}>
                {!this.props.noIcon &&
                    <Box display={{ xs: "none", sm: "block" }}>
                        {this.props.isUserFolder ?

                            ((this.props.isClosed || this.props.isList)
                                ?
                                <FontAwesomeIcon icon={this.props.isList ? faList : faFolder} style={{ fontSize: "16px", color: this.props.dark ? '#32478C' : 'palegoldenrod', marginRight: "8px", marginTop: "auto", marginBottom: "auto" }} />
                                :
                                <FontAwesomeIcon icon={this.props.isList ? faList : faFolderOpen} style={{ fontSize: "16px", color: this.props.dark ? '#32478C' : 'palegoldenrod', marginRight: "8px", marginTop: "auto", marginBottom: "auto" }} />)
                            :
                            ((this.props.isClosed || this.props.isList)
                                ?
                                <FontAwesomeIcon icon={this.props.isList ? faList : faFolder} style={{ fontSize: "16px", color: this.props.dark ? '#32478C' : 'palegoldenrod', marginRight: "8px", marginTop: "auto", marginBottom: "auto" }} />
                                :
                                <FontAwesomeIcon icon={this.props.isList ? faList : faFolderOpen} style={{ fontSize: "16px", color: this.props.dark ? '#32478C' : 'palegoldenrod', marginRight: "8px", marginTop: "auto", marginBottom: "auto" }} />)
                        }
                    </Box>
                }
                {this.props.sharedFolder && <FontAwesomeIcon icon={faShare} style={{ fontSize: "12px", color: this.props.dark ? '#32478C' : '#32478C', marginRight: "2px", marginTop: "auto", marginBottom: "auto" }} />}
                <Typography noWrap sx={{ my: "auto", fontWeight: 700, fontSize: { xs: ".8rem", sm: ".8rem" }, color: this.props.dark ? "white" : "#222"}}>
                    {this.props.label}
                </Typography>
            </Box>

        )
    }
}
