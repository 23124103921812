import React, { Component } from 'react';

//material ui
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';


export class ContentTabsLeftTitle extends Component {

    //main component render
    render() {
        return (
            <Box sx={{ display: "inline-flex" }}>
                <Box sx={{ display: { xs: "none", sm: "flex" } }}>
                    <Typography
                        variant="h6"
                        sx={{
                            pl:2,
                            my: "auto",
                            color: (theme) => this.props.dark ? theme.palette.common.white : theme.palette.common.white,
                            pr: this.props.onClick ? 0 : 1
                        }}>
                        {this.props.title}
                    </Typography>
                </Box>
                {this.props.onClick &&
                    <Tooltip title={this.props.tooltip}>
                        <IconButton
                            size="small"
                            key="newitem"
                            aria-label={this.props.tooltip}
                            onClick={this.props.onClick}
                            sx={{
                                mr: 2,
                                fontSize: "1rem",
                                boxShadow: this.props.dark && "none",
                                color: theme => this.props.dark ? theme.palette.common.white : theme.palette.common.white
                            }}
                        >
                            <AddIcon />
                        </IconButton>
                    </Tooltip>
                }
            </Box>
        )
    }
}
