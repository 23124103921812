import React, { Component } from 'react';

//Custom Components
import { Comments } from '../Comments/Comments';
import { Blog } from '../Content/Blog/Blog';
import { DocumentViewer } from '../Content/Documents/DocumentViewer';
import { Event } from '../Content/Events/Event';
import { Product } from '../Content/Market/Product';
import { Song } from '../Content/Music/Song';
import { PhotoGallery } from '../Content/Photos/PhotoGallery';
import { Reactions } from '../Reactions/Reactions';
import { ModalDialog } from '../Utilities/ModalDialog';
import { UserAvatar } from '../Utilities/UserAvatar';
import { PostContentComposer } from './PostContentComposer';
import { Repost } from './Repost';
import { VideoPlayer } from '../Utilities/VideoPlayer';
import { Menu } from '../Utilities/Menu'
//Font Awesome
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//Link Components
import Microlink from '@microlink/react';
import Linkify from 'react-linkify';

//Material UI
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { CardActionArea } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
//import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
//Data Services
import { getDocument } from '../../DataServices/DocumentService';
import { addMention } from '../../DataServices/MentionsService';
import { createNotification } from '../../DataServices/NotificationService';
import { getPostContent, repost, savePost, unsavePost } from '../../DataServices/PostService';
import { newTextTag } from '../../DataServices/TagService';

export class Post extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showDelete: false,
            mode: this.props.mode,
            anchorEl: null,
            anchorUserEl: null,
            openPhotoViewer: false,
            selectedPhoto: null,
            showComments: false,
            showConfirmDeletePost: false,
            contentLoaded: false,
            showCommentSection: this.props.post.commentCount > 0 ? true : true,
            showBlog: false,
            showDocument: false,
            showMarketItem: false,
            allowEdit: false,
            showUserSite: false,
            streamBase: "https://mylivesphere.com/api/videostream/",
        };
    }

    componentDidMount() {
        this.loadPost();
    }
    //Data getters
    loadPost = async () => {
        await this.getPostContent();
        this.setState({ contentLoaded: true });
    }
    getPostContent = async () => {
        const post = this.props.post;
        if (post.content) {
            for (var j = 0; j < post.content.length; j++) {
                if (post.content[j].id &&
                    (post.content[j].type === 2 && (!post.content[j].photo || !post.content[j].photo.thumb)) ||
                    (post.content[j].type === 3 && !post.content[j].rumbleVideo) ||
                    (post.content[j].type === 4 && !post.content[j].blog) ||
                    (post.content[j].type === 8 && !post.content[j].event) ||
                    (post.content[j].type === 12 && !post.content[j].marketItem) ||
                    (post.content[j].type === 6 && !post.content[j].document)) {
                    let content = post.content[j];
                    if (content.type !== 0 && content.type !== 1) {
              
                        const data = await getPostContent(content.id);

                        this.props.updatePostContent(this.props.channel, this.props.idx, j, data);
                    }

                }
            }

        }
    }

    //Event handlers
    handleFollow = async () => {
        let post = this.props.post;
        post.user.following = true;
        this.props.updatePosts(this.props.channel,post, this.props.idx);
    }
    handleUnfollow = async () => {
        let post = this.props.post;
        post.user.following = false;
        this.props.updatePosts(this.props.channel, post, this.props.idx);
    }
    handleShowPostActionMenu = (e) => {
        this.setState({ anchorEl: e.currentTarget });
    }
    handleClosePostActionMenu = (e) => {
        this.setState({ anchorEl: null });
    }
    handleClickPhoto(e, photo, idx) {
        let target = e.target;
        target.style.cursor = 'wait';
        document.body.style.cursor = 'wait';
        this.setState({
            selectedPhoto: photo,
            selectedPhotoIDX: idx,
            openPhotoViewer: true
        })
        target.style.cursor = 'default';
        document.body.style.cursor = 'default';
    }
    handleEdit = () => {
        this.props.handleEditPost(this.props.post);
    }
    handleDelete = () => {
        this.setState({ showConfirmDeletePost: true, anchorEl: null });
    }
    handleOpen = () => {
        this.setState({ anchorEl: null });
        this.props.setSelectedPost(this.props.post, this.props.idx);
    }
    handleShare = () => {
        this.setState({
            anchorEl: null,
            showPostComposer: true
        });

    }
    handleHide = () => {
        this.props.handleHidePost(this.props.post);
    }
    handleConfirmDelete = () => {
        this.setState({ showConfirmDeletePost: false });
        this.props.handleDeletePost(this.props.post.id, this.props.idx);
    }
    handleMenuClick = (event) => {
        this.setState({ anchorEl: event.currentTarget });
    };
    updatePhotoCommentCount = (count) => {
        this.setState(
            prevState => {
                let state = { ...prevState };
                state.selectedPhoto.commentCount = count;
                return { state };
            }
        );
    }
    handleComments = () => {
        this.setState({
            anchorEl: null,
            showCommentSection: !this.state.showCommentSection
        });
        //this.props.menuOpen(false);
    }
    handleClickDocument = async (e, docid, idx) => {
        let target = e.target;
        target.style.cursor = 'wait';
        document.body.style.cursor = 'wait';
        const doc = await getDocument(docid);
        target.style.cursor = 'default';
        document.body.style.cursor = 'default';

        this.setState({
            selectedDocument: doc,
            selectedDocumentName: doc.documentName,
            selectedDocumentContent: doc.eFiles[0].fileContent,
            selectedDocumentIDX: idx,
            showDocument: true
        })
    }
    handleSaveRepost = async (post) => {

        const npost = await repost(this.props.user.userID, this.props.post.id, post.textContent, this.props.appIdentifier);
        if (npost) {
            this.props.updateLastPost(npost.id);
            this.props.handleSharePost(npost);

            if (post.tags && post.tags.length > 0) {
                for (var t = 0; t < post.tags.length; t++) {
                    await newTextTag(npost.id, 2, post.tags[t]);
                }
            }
            if (post.mentions && post.mentions.length > 0) {
                for (var m = 0; m < post.mentions.length; m++) {
                    await addMention(post.mentions[m].id, this.props.user.userID, 2, npost.id);

                    let message = (this.props.user.displayName ? this.props.user.displayName : "A user") + " mentioned you in a post";
                    if (post.mentions[m].id === this.props.user.userID) {
                        message = "You mentioned yourself in a post";
                    }
                    await createNotification(post.mentions[m].id, 2, npost.id, this.props.user.userID, message, this.props.appIdentifier);
                }
            }

            let msg = (this.props.user.displayName ? this.props.user.displayName : "A user") + " shared your post";
            if (this.props.post.user.userID === this.props.user.userID) {
                msg = "You shared your own post";
            }
            await createNotification(this.props.post.user.userID, 2, npost.id, this.props.user.userID, msg, this.props.appIdentifier);
        }

        this.setState({ showPostComposer: false });


    }
    savePost = async () => {
        let post = this.props.post;
        post.isSaved = true;
        this.props.updatePosts(this.props.channel, post, this.props.idx);
        await savePost(post.id);
    }
    unsavePost = async () => {
        let post = this.props.post;
        post.isSaved = false;
        this.props.updatePosts(this.props.channel, post, this.props.idx);
        await unsavePost(post.id);
    }
    updateCommentCount = (count) => {
        let post = this.props.post;
        post.commentCount = count;
        this.props.updatePosts(this.props.channel, post, this.props.idx);
    }

    //Render funcs
    postHeader = () => {
        if (this.props.post.user) {
            return (
                <Box key={this.props.post.id + "h"} sx={{ display: "flex", padding: { xs: "8px", md: "0px" }, marginBottom: "8px" }}>
                    {this.userAvatar()}
                    <Box sx={{ flexGrow: 1 }} />
                    {this.state.contentLoaded &&
                        <Box sx={{ display: { xs: 'flex' } }}>
                            {this.saved()}
                            {this.reactions()}
                            {this.postMenu()}
                        </Box>
                    }
                </Box>
            )
        }
        return null;
    }
    userAvatar = () => {
        return (
            <UserAvatar
                id={this.props.post.id}
                user={this.props.post.user}
                dark={this.props.dark}
                loaded={this.state.contentLoaded}
                activeUser={this.props.user}
                appIdentifier={this.props.appIdentifier}
                handleFollow={this.handleFollow}
                handleUnfollow={this.handleUnfollow}
                post={this.props.post}
                width={this.props.width}
            />
            )
    }
    reactions = () => {
        return (
            <Reactions
                inline
                user={this.props.user}
                refUser={this.props.post.user}
                targetTypeDesc="post"
                dark={this.props.dark}
                myReaction={this.props.post.myReaction}
                likeCount={this.props.post.likeCount}
                loveCount={this.props.post.loveCount}
                targetID={this.props.post.id}
                idx={this.props.idx}
                targetType={2}
                noColor={this.props.dark ? true : false}
                appIdentifier={this.props.appIdentifier}
            />
        )
    }
    saved = () => {
        return (
            <Box sx={{ display: "inline-block" }}>
                {!this.props.post.isSaved ?
                    <Tooltip title="Save Post">
                        <IconButton onClick={this.savePost}>
                            <StarBorderIcon sx={{ color: "#e65100"}} fontSize="small"/>
                        </IconButton>
                    </Tooltip>
                    :
                    <Tooltip title="Unsave Post">
                        <IconButton onClick={this.unsavePost}>
                            <StarIcon sx={{ color: "#e65100" }} fontSize="small"/>
                        </IconButton>
                    </Tooltip>
                }

            </Box>
        )
    }
    postMenu = () => {
        if (!this.props.readOnly) {
            return (
                <Box sx={{ display: "inline-block" }}>
                    <IconButton onClick={this.handleShowPostActionMenu} size="small">
                        <MoreHorizIcon sx={{ color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.dimblack }} />
                    </IconButton>
                    <Menu
                        {...this.props}
                        id={"post" + this.props.post.id}
                        open={this.state.anchorEl !== null}
                        onClose={this.handleClosePostActionMenu}
                        anchorEl={this.state.anchorEl}
                    >
                        {this.props.setSelectedPost &&
                            <MenuItem onClick={this.handleOpen}>
                                Open
                            </MenuItem>
                        }
                        <MenuItem onClick={this.handleComments}>
                            {this.state.showCommentSection ? "Hide Comments" : "Show Comments"}
                        </MenuItem>
                        {(this.state.allowEdit && this.props.post.user.userID === this.props.user.userID) &&
                            <MenuItem onClick={this.handleEdit}>Edit</MenuItem>
                        }
                        {this.props.post.user.userID === this.props.user.userID &&
                            <MenuItem onClick={this.handleDelete}>Delete</MenuItem>
                        }
                        {(!this.props.post.isRepost && true === true) &&
                            <MenuItem onClick={this.handleShare}>
                                Share
                            </MenuItem>
                        }
                        {this.props.post.shareFlag && <MenuItem onClick={this.handleHide}>Hide</MenuItem>}

                    </Menu>
                </Box>
            )
        }
        return null;
    }

    //rendered content in post container
    postContent = () => {
        if (this.props.post.content && this.state.contentLoaded) {
            let photos = false;
            return (
                <Box key={this.props.post.id + "contentRegion"} className="prettyScroll">
                    {this.props.post.content.map((content, index) => {
                        switch (content.type) {
                            case 2:
                                photos = true;
                                break;
                            case 3:
                                return this.videoContent(content, index);
                            case 4:
                                return this.blogContent(content, index);
                            case 5:
                                return this.songContent(content, index);
                            case 6:
                                return this.docContent(content, index);
                            case 8:
                                return this.eventContent(content, index);
                            case 11:
                                return this.repost(content.post, index);
                            case 12:
                                return this.marketContent(content, index);
                            default:

                                return null;
                        }

                    })}
                    {photos && this.photoContent()}


                    {this.props.post.content.map((content, index) => {
                        switch (content.type) {
                            case 0:
                                return this.textContent(content, index);
                            case 1:
                                return this.linkContent(content, index);
                            default:
                                return null;
                        }
                    })}

                </Box>
            )
        }
        return (
            <Skeleton sx={{ height: 190 }} animation="wave" variant="rectangular" />
        )
    }
    textContent = (content, index) => {
        if (content.textContent && content.textContent !== "") {
            const words = content.textContent.split(' ');
            if (words && words.length > 0) {
                for (var i = 0; i < words.length; i++) {
                    if (words[i].startsWith("@")) {
                        words[i] = words[i].replace("@", "");
                    }
                }
            }
            content.textContent = words.join(' ');
            return (
                <Box key={"tc-" + index + content.id} className="prettyScroll" sx={{ padding: "8px 24px", pb: 0, maxHeight: "500px" }}>
                    <Linkify componentDecorator={(decoratedHref, decoratedText, key) => (<a target="blank" href={decoratedHref} key={key}> {decoratedText} </a>)}>
                        <Typography paragraph variant="subtitle2" sx={{ fontSize: { xs: "1rem", sm: ".875rem" }, color: (theme) => this.props.dark ? theme.palette.common.white : theme.palette.common.dimblack, fontWeight: 500 }}>
                            <span>{content.textContent}</span>
                        </Typography>
                    </Linkify>
                </Box >
            )
        }

        return null;
    }
    linkContent = (content, index) => {
        if (content.textContent) {
            return (
                <Box key={"lc-" + index + content.id} sx={{ m:2, boxShadow: this.props.dark ? 0 : 8 }} className={this.props.dark ? "dark linkframe" : "light linkframe"}>
                    <Microlink size='large' url={content.textContent} style={{ border: "none", mx: "auto", width: "-webkit-fill-available", maxWidth: "unset", }}  />
                </Box>
            )
        }
        return null;
    }
    photoContent = () => {
        const content = this.props.post.content.filter((x) => x.type === 2);
        if (content && content.length > 0) {
            const photos = content.map(x => x.photo);
            return (
                <PhotoGallery {...this.props}  photos={photos} />
                )
        }
        return null;

    }
    docContent = (content, index) => {   
        if (content.document) {
            return (
                <Card
                    key={"cardfor" + content.document.documentID}
                    sx={{ margin: "16px", maxWidth: 360, background: (theme) => this.props.dark && theme.palette.note.dark, color: (theme) => this.props.dark && theme.palette.common.dimwhite }}
                    raised
                    elevation={this.props.dark ? 1 : 4}
                >
                    <CardActionArea onClick={(e) => this.handleClickDocument(e, content.document.documentID, index)}>
                        <CardContent sx={{ padding:"12px" }}>
                            <Box display="flex" >
                                <FontAwesomeIcon icon={faFilePdf} style={{ fontSize: "42px", color: 'rgb(50,71,140)', }} />
                                <Stack sx={{ marginLeft: "8px" }}>
                                    <Typography gutterBottom variant="subtitle1" sx={{ fontSize: ".9rem", marginBottom: 0 }} component="div">
                                        {content.document.documentName ? content.document.documentName.substr(0, 30) : "Document"}
                                    </Typography>
                                    <Typography variant="body2" sx={{ color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.dimblack }}>
                                        {new Date(content.document.createDate).toLocaleDateString()}
                                    </Typography>
                                </Stack>
                            </Box>
                        </CardContent>
                </CardActionArea>
                {this.docViewer(content)}
                </Card>

            )
        }
        return (
            <Skeleton key={content.id + index} sx={{ height: 190 }} variant="rectangular" />
        )
    }
    videoContent = (content, index) => {
        if (content.video) {
            return (<VideoPlayer key={"pv" + index} video={content.video} />  )
        }
        return null;
    }
    blogContent = (content, index) => {
        if (content.blog) {
            return (
                <Box key={"outercfor" + content.id}>
                <Paper
                    key={"cfor" + content.id}
                    onClick={() => this.setState({ showBlog: true })}
                    elevation={this.props.dark ? 0 : 4}
                    sx={{ cursor:"pointer", padding: 0, marginTop: "8px", borderRadius: "0px", maxHeight: "500px", overflow: "hidden", border: this.props.dark ? "none" : "solid 1px #ddd", }}>
                    <Blog
                        key={content.id}
                        dark={this.props.dark}
                        pubDate={new Date(this.props.post.postDate).toLocaleDateString()}
                        blog={content.blog}
                        channel={null}
                        user={this.props.user}
                        hideSubscribe
                    />

      
                    </Paper>
                    {this.blogViewer(content)}
                </Box>

            )
        }
        return (
            <Skeleton key={content.id + index}  sx={{ height: 190}} variant="rectangular" />
        )
    }
    eventContent = (content, index) => {
        if (content.event) {
            return (
                <Box key={"outercfor" + content.id}>
                    <Paper
                        key={"cfor" + content.id}
                        onClick={() => this.setState({ showEvent: true })}
                        elevation={this.props.dark ? 0 : 4}
                        sx={{ cursor: "pointer", padding: 0, marginTop: "8px", borderRadius: "0px", maxHeight: "300px", overflow: "hidden", border: this.props.dark ? "none" : "solid 1px #ddd", }}>
                        <Event
                            key={content.id}
                            dark={this.props.dark}
                            event={content.event}
                            user={this.props.user}
                            post
                        />
                    </Paper>
                    {this.eventViewer(content)}
                </Box>

            )
        }
        return ( 
            <Skeleton key={content.id + index} sx={{ height: 190 }} variant="rectangular" />
        )
    }
    songContent = (content, index) => {
        if (content.refID) {
            return (
                <Song
                    key={content.id}
                    dark={this.props.dark}
                    id={content.refID}
                    user={this.props.user}
                />
            )
        }
        return (  <Skeleton variant="rectangular" animation="wave" sx={{ height: "194px", mx: 2 }} />  )
    }
    marketContent = (content, index) => {
        if (content.marketItem) {
            return (
                <Box key={"outercfor" + content.id}>
                    <Paper
                        key={"cfor" + content.id}
                        onClick={() => this.setState({ showMarketItem: true })}
                        elevation={this.props.dark ? 0 : 4}
                        sx={{ cursor: "pointer", padding: 0, marginTop: "8px", borderRadius: "0px", maxHeight: "250px", overflow: "hidden", border: this.props.dark ? "none" : "solid 1px #ddd", }}>
                        <Product
                            key={content.id + new Date().toISOString()}
                            dark={this.props.dark}
                            item={content.marketItem}
                            user={this.props.user}
                            preview
                        />
                    </Paper>
                    {this.marketViewer(content)}
                </Box>

            )
        }
        return (
            <Skeleton key={content.id + index} height={190} variant="rectangular" />
        )
    }
    repost = (post, index) => {
        return (
            <Box key={post.id + "repost"} sx={{ p:1}}>
                <Typography sx={{ pl:.5, fontSize: ".7rem", color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.dimwblack }}>Shared Post</Typography>
                <Box sx={{  borderRadius: { xs: 0, sm: 0.5 } }}>
                    <Repost
                        dark={this.props.dark}
                        post={post}
                        postIdx={this.props.idx}
                        idx={index}
                        user={this.props.user}
                        handleDeletePost={this.props.handleDeletePost}
                        handleEditPost={this.props.handleEditPost}
                        handleSharePost={this.props.handleSharePost}
                        handleHidePost={this.props.handleHidePost}
                        menuOpen={this.props.menuOpen}
                        updatePostContent={this.props.updateRepostContent}
                        appIdentifier={this.props.appIdentifier}
                        isRepost
                        setSelectedPost={this.props.setSelectedPost}
                    />
                </Box>
            </Box>
        )
    }

    //post footer items
    comments = () => {
        return (
            <Box key={"commentsfor" + this.props.post.id} sx={{ p: 1, }}>
                <Comments
                    appIdentifier={this.props.appIdentifier}
                    open={this.state.showComments}
                    targetID={this.props.post.id}
                    targetType={2}
                    commentCount={this.props.post.commentCount}
                    dark={this.props.dark}
                    user={this.props.user}
                    noIcon
                    refUser={this.props.post.user}
                    targetTypeDesc="post"
                    updateCommentCount={this.updateCommentCount}
                />
            </Box>
        )
    }
    postInfo = () => {
        if (this.props.showInfo) {
            return (
                <Box sx={{ p: 1, pb: 0, pt: 2 }} key={"pi-" + this.props.post.id} >
                    <Box sx={{ p: 0.5, pl: 1, pr: 1, borderRadius: "8px", background: this.props.dark ? "#151515" : "#eee" }}>
                        <Typography key={"pit-" + this.props.post.id} sx={{ fontSize: ".7rem", color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.dimmerblack }}>
                            Post Information
                        </Typography>
                        <Box sx={{ p: 0.5 }}>
                            {this.category()}
                            {this.sources()}
                        </Box>
                    </Box>
                </Box>
            )
        }
        return null;
    }
    sources = () => {
        if (this.props.post.sources && this.props.post.sources.length > 0) {
            return (
                <Box sx={{ mt: 1 }}>
                    <Typography variant="subtitle1" sx={{ fontSize: ".75rem", color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.dimmerblack }}>
                        Sources:
                    </Typography>
                    <Linkify componentDecorator={(decoratedHref, decoratedText, key) => (<a target="blank" href={decoratedHref} key={key}> {decoratedText} </a>)}>
                        <Stack sx={{ pl: 1, pr: 1 }} >
                            {this.props.post.sources.map((source, idx) => {
                                return (
                                    <Typography key={"source" + this.props.post.id + idx} variant="body2" sx={{ fontSize: ".8rem" }}>
                                        {source}
                                    </Typography>
                                )
                            })}
                        </Stack>
                    </Linkify>
                </Box>
            )
        }
        return null;
    }
    category = () => {
        if (this.props.post && this.props.post.textCategory) {
            return (
                <Chip
                    key={"cat" + this.props.post.id}
                    label={this.props.post.textCategory.replaceAll("_", " ")}
                    size="small"
                    color="success"
                    sx={{ width: "fit-content", fontSize: ".6rem" }}
                />
            )
        }
        return null;
    }

    //popup viewers for content clicks
    marketViewer = (content) => {
        if (this.state.showMarketItem) {
            return (
                <ModalDialog
                    dark={this.props.dark}
                    show={this.state.showMarketItem}
                    handleClose={() => this.setState({ showMarketItem: false })}
                    fullWidth={false}
                    maxWidth="xl"
                    fullScreen={true}
                    noPad
                    prettyScroll
                >
                    <Product
                        key={content.id}
                        dark={this.props.dark}
                        item={content.marketItem}
                        user={this.props.user}
                    />
                </ModalDialog>
            )
        }
        return null;
    }
    eventViewer = (content) => {
        if (this.state.showEvent) {
            return (
                <ModalDialog
                    dark={this.props.dark}
                    show={this.state.showEvent}
                    handleClose={() => this.setState({ showEvent: false })}
                    fullWidth={false}
                    maxWidth="xl"
                    fullScreen={true}
                    noPad
                    prettyScroll
                >
                    <Event
                        key={"post" + content.id}
                        dark={this.props.dark}
                        event={content.event}
                        user={this.props.user}
                    />
                </ModalDialog>
            )
        }
        return null;
    }
    blogViewer = (content) => {
        if (this.state.showBlog) {
            return (
                <ModalDialog
                    dark={this.props.dark}
                    show={this.state.showBlog}
                    handleClose={() => this.setState({ showBlog: false })}
                    fullWidth={false}
                    maxWidth="xl"
                    noPad
                    fullScreen={true}
                    prettyScroll
                >
                    <Blog
                        key={"post" + content.id}
                        dark={this.props.dark}
                        pubDate={new Date(this.props.post.postDate).toLocaleDateString()}
                        blog={content.blog}
                        channel={null}
                        user={this.props.user}
                    />
                </ModalDialog>
            )
        }
        return null;
    }
    docViewer = () => {
        if (this.state.showDocument && this.state.selectedDocument) {
            return (
                <ModalDialog
                    dark={this.props.dark}
                    show={this.state.showDocument}
                    handleClose={() => this.setState({ showDocument: false })}
                    fullWidth={true}
                    disableButtons
                    hideScroll={true}
                    noUpperClose
                    maxWidth="xl"
                    closeLabel="Close"
                    noSidePad
                    fullScreen
                    solid
                    fullHeight
                >
                    <DocumentViewer
                        dark={this.props.dark}
                        fileName={this.state.selectedDocumentName}
                        document={this.state.selectedDocumentContent}
                        scale={this.props.width < 400 ? 0.5 : 1.6}
                        handleClose={() => this.setState({ showDocument: false })}
                        fullHeight
                        width={this.props.width}
                    />
                </ModalDialog>
            )
        }
        return null;
    }
    postDeleteConfirmation = () => {
        return (
            <ModalDialog dark={this.props.dark} show={this.state.showConfirmDeletePost}
                title="Confirm Deletion"
                subTitle={"Post will be deleted.  Proceed?"}
                handleClose={() => this.setState({ showConfirmDeletePost: false })}
                handleSave={this.handleConfirmDelete}
                fullWidth={false}
                maxWidth="sm"
                saveLabel="Delete"
            />
        )
    }
    composer = () => {
        if (this.state.showPostComposer) {
            return (
                <PostContentComposer
                    user={this.props.user}
                    show={this.state.showPostComposer}
                    savePost={this.handleSaveRepost}
                    cancelPost={() => this.setState({ showPostComposer: false })}
                    dark={this.props.dark}
                    postType={this.props.post.type}
                    title="New Shared Post"
                    userList={this.props.userList}
                />
            )
        }
        return null;
    }

    //post container
    post = () => {
        return (
            <Paper
                key={this.props.isRepost ? "paperContREP" + this.props.post.id : "paperCont" + this.props.post.id}
                elevation={this.props.dark ? 0 : 4}
                sx={{
                    height: "100%",
                    padding: { xs: "0px", md: "8px" },
                    paddingTop: "8px",
                    borderRadius: { xs: "0px", md: "4px" },
                    border: (theme) => this.props.dark ? "none" : { xs: "none", sm: "solid 1px #dadada" },
                    background: (theme) => this.props.dark ? theme.palette.post.dark : theme.palette.post.light,
                }}
            >
                <Stack>
                    {this.postHeader()}
                    {this.postContent()}
                    <Box sx={{ flexGrow: 1 }} />
                    {this.props.post.type === 0 && this.postInfo()}
                    {this.state.showCommentSection && this.comments()}
                </Stack>
                {this.postDeleteConfirmation()}
                {this.composer()}
            </Paper>
            )
    }

    //main component render
    render() {
        return this.post();
    }
}
