import React, { Component } from 'react';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';


export class SectionFieldGroup extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <Card
                elevation={0}
                sx={{
                    width: "100%",
                    boxShadow: this.props.dark ? "none" : { xs: 0, sm: "unset"},
                    backgroundColor: (theme) => this.props.dark ? "transparent" : "rgba(255,255,255,.5)",
                    mb: 1,
                    mt: this.props.topMargin ? 1 : 0,
                    borderRadius: { xs: 0, sm: 1 },
                }}>
                <CardContent sx={{ padding: "8px", paddingBottom: "8px!important" }}>
                    <Stack sx={{ width: "100%" }}>
                        {this.props.children}
                    </Stack>
                </CardContent>
            </Card>
        );
    }
}
