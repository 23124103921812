import React, { Component } from 'react';

//Custom Components
import { Spin } from '../../Utilities/Spin';
import { Comments } from '../../Comments/Comments';
import { Tags } from '../../Tags/Tags';
import { Reactions } from '../../Reactions/Reactions'
import { ModalDialog } from '../../Utilities/ModalDialog';
import { PhotoEditor } from './PhotoEditor';
//Material UI
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import TagIcon from '@mui/icons-material/Tag';
import Box from '@mui/material/Box';
//Data Services
import { getPhoto, updatePhoto } from '../../../DataServices/PhotoService'

export class PhotoViewer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            photo: this.props.photo ? this.props.photo : null,
            index: this.props.index,
            fullScreen: false,
            showComments: false,
            showCommentSection: false,
            showTagSection: false,
            showEditor: false,
            title: ""
        }
    }

    componentDidMount() {
        if (!this.state.photo || !this.state.photo.fullPhoto) {
            this.getPhoto();
        }
        else {
            this.setState({ loading: false });
        }
    }
    
    componentDidUpdate(prevProps, prevState) {
        if (this.props.photoID !== prevProps.photoID) {
            this.setState(
                state => {
                    state.photo.fullPhoto = null;
                    return state;
                }
            );
            this.updatePhoto();
        }
    }
    handleEdit = () => {
        if (this.state.showEditor) {
            this.setState({ showEditor: false, title: "" });
        }
        else {
            this.setState({ showEditor: true, title: "Photo Editor" });
        }
    }
    getPhoto = async () => {
        const photo = await getPhoto(this.props.photoID);
        if (photo) {
            this.setState({
                photo: photo,
                index: this.props.index,
                showComments: false,
                loading: false
            });
        }
        if (this.props.handleUpdateItem)
            this.props.handleUpdateItem(photo, this.props.index);
    }
    updatePhoto = async () => {
        this.setState({ loading: true });
        if (!this.props.photo || !this.props.photo.fullPhoto) {
            this.getPhoto();
        }
        else {
            this.setState({
                photo: this.props.photo,
                index: this.props.index,
                showComments: false,
                loading: false
            });
        }
    }
    toggleFullScreen = () => {
        this.setState({fullScreen : !this.state.fullScreen})
    }
    handleSaveEdit = (b64) => {
        const photo = this.state.photo;
        photo.fullPhoto = b64;
        photo.thumb = b64;
        photo.extension = ".png";
        this.setState({ photo, showEditor: false });
        this.props.handleUpdateItem(photo, this.props.index);

    }

    //Render Funcs
    comments = () => {
        return (
            <Comments
                open={this.state.showComments}
                targetID={this.state.photo.photoID}
                targetType={4}
                commentCount={this.state.photo.commentCount}
                dark={this.props.dark}
                user={this.props.user}
                updateCommentCount={this.props.updateCommentCount}
            />)
    }
    tags = () => {
        return (

            <Tags
                dark={this.props.dark}
                targetID={this.props.photo.photoID}
                targetType={2}
            />
        )
    } 
    card = () => {
        return (
  
            <Card sx={{
                borderRadius: 0,
                maxWidth: "100%",
                background: this.props.dark ? "#050505" : "transparent",
                boxShadow: "none",
                height: this.state.showEditor ? "100%" : "100%",
                display: {sm:"flex"},
            }}>
                {this.state.showTagSection && this.tags()}
                {this.state.showCommentSection &&
                    <CardContent sx={{ padding: "8px" }}>
                        {this.comments()}
                    </CardContent>
                }
                {this.state.showEditor ?
                    <PhotoEditor src={"data:image/png;base64, " + this.state.photo.fullPhoto} dark={this.props.dark} width={this.props.width} onSave={this.handleSaveEdit} photoID={this.state.photo.photoID} />
                    :
                    <Box sx={{ pb: { xs: 7, lg: 0 }, display: "flex", width: "100%" }}>
                    <CardMedia
                        component="img"
                        sx={{
                            height: { xs: "auto" },
                            width: { xs: "100%" },
                            maxHeight: { xs: "calc(100vh - 106px)",  },
                            minWidth: "350px",
                            maxWidth: { lg: "1000px", xl: "1400px"},
                            borderRadius: 0,
                            objectFit:"contain",
                            border: this.props.border && "1px solid #333",
                            //boxShadow: this.props.dark ? 0 : 8,
                            mt: { md: "auto", },
                            mb: { sm :"auto"},
                            mx:"auto"
                        }}
                        src={"data:image/png;base64, " + this.state.photo.fullPhoto}
                        alt={this.state.photo.photoName}
                        />
                    </Box>
                }


            </Card>
        )
    }
    tagsButton = () => {
        return (
            <Tooltip title={this.state.showTagSection ? "Hide Tags" : "Show Tags"}>
                <IconButton onClick={() => this.setState({ showTagSection: !this.state.showTagSection })} size="small" sx={{ marginLeft: "4px" }}>
                    <TagIcon sx={{ fontSize: "18px!important", color: (theme) => this.props.dark ? theme.palette.common.dimmerwhite : theme.palette.common.white }} />
                </IconButton>
            </Tooltip>
        )
    } 
    commentButton = () => {
        return (
            <Tooltip title={this.state.showCommentSection ? "Hide Comments" : "Show Comments"}>
                <IconButton onClick={() => this.setState({ showCommentSection: !this.state.showCommentSection })} size="small" sx={{ marginLeft: "4px" }}>
                    <CommentOutlinedIcon sx={{ fontSize: "18px!important", color: (theme) => this.props.dark ? theme.palette.common.dimmerwhite : theme.palette.common.white }} />
                </IconButton>
            </Tooltip>
        )
    }
    reactions = () => {
        return (
            <Reactions
                dark={true}
                myReaction={this.state.photo.myReaction}
                likeCount={this.state.photo.likeCount}
                loveCount={this.state.photo.loveCount}
                targetID={this.state.photo.photoID}
                idx={this.state.index}
                targetType={4}
                targetTypeDesc="photo"
                noColor={!this.props.dark}
                user={this.props.user}
                refUser={{ userID: this.props.photo.ownerID }}
                appIdentifier={this.props.appIdentifier}
            />
        )
    } 
    closeButton = () => {
        return (
            <Tooltip title="Close">
                <IconButton onClick={this.props.handleClose} sx={{ color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.white }}>
                    <CloseIcon />
                </IconButton>
            </Tooltip>
        )
    }
    navButtons = () => {
        return (
            <>
                {(this.props.allowPrevious && this.props.handlePrevious) &&
                    <Tooltip title="Previous photo">
                        <IconButton onClick={this.props.handlePrevious} sx={{ color: this.props.dark ? "#ccc" : "#444" }}>
                            <NavigateBeforeIcon />
                        </IconButton>
                    </Tooltip>

                }
                {(this.props.allowNext && this.props.handleNext) &&
                    <Tooltip title="Previous photo">
                        <IconButton onClick={this.props.handleNext} sx={{ color: this.props.dark ? "#ccc" : "#444" }}>
                            <NavigateNextIcon />
                        </IconButton>
                    </Tooltip>

                }
            </>

        )
    }
    title = () => {
        return (
            <Typography
                variant="subtitle1"
                component="div"
                sx={{
                    fontWeight:500,
                    marginTop: "auto",
                    marginBottom:"auto",
                    color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.white
                }}
                align="left"
            >
                Photo Viewer
            </Typography>
        )
    }


    render() {

        let contents = this.state.loading
            ? <Spin open={this.state.loading} />
            :
            <ModalDialog
                dark={this.props.dark}
                show={this.props.open}
                handleClose={this.props.handleClose}
                handleEdit={this.handleEdit}
                allowEdit={this.props.allowEdit}
                closeLabel="Close"
                fullWidth
                fullScreen
                fullHeight
                solid
                noPad
                disableButtons
                maxWidth="xl"
                prettyScroll
                title={this.state.title}
                editModeActive={this.state.showEditor}
            >
                {this.card()}
            </ModalDialog>



               
        return contents;
    }
}
