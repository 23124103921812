import React, { useState } from 'react';

//Custom
import { Spin } from "../Common/Utilities/Spin"
import { ModalDialog } from '../Common/Utilities/ModalDialog'
import { SectionFieldGroup } from '../Common/Utilities/SectionFieldGroup'
import { TextInput } from '../Common/Utilities/TextInput'
//MUI
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';

import { validateEmail } from '../DataServices/Utility'
import { createPasswordResetRequest } from '../DataServices/UserService'
import { sendEmail } from '../DataServices/EmailService'


async function loginUser(credentials) {
    return fetch('home/login', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
    })
        .then(data =>  data.json() )
}
async function registerUser(credentials) {
    return fetch('home/register', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
    })
        .then(data => data.json())
}
async function addEmail(email) {

    const response = await fetch('home/addemail?type=' + 0 + '&email=' + email + '&primary=' + true, { method: 'POST' })
    const id = await response.text();
    return id;
}
 

export default function Login({ setToken, setUserInfo, appIdentifier }) {

    const [username, setUserName] = useState();
    const [password, setPassword] = useState();
    const [loggingIn, setLoggingIn] = useState(false);
    const [firstname, setFirstName] = useState();
    const [lastname, setLastName] = useState();
    const [showRequiredAlert, setShowRequiredAlert] = useState(false);
    const [showDupAlert, setDupAlert] = useState(false);
    const [showInvalidAlert, setInvalidAlert] = useState(false);
    const [newUser, setNewUser] = useState(false);
    const [showForgotPwd, setShowForgotPwd] = useState(false);
    const [showForgotPwdEmailSent, setShowForgotPwdEmailSent] = useState(false);
    const [showInvalidEmailAlert, setShowInvalidEmailAlert] = useState(false);

    const handleLoginSubmit = e => {
        e.preventDefault();
        setLoggingIn(true);
        Login();

    }
    const Login = async () => {
        const loginResult = await loginUser({
            username,
            password,
            AppID : appIdentifier
        });
        if (loginResult.success) {
            const token = loginResult.accessToken;
            setUserInfo(loginResult);
            setToken(token);
        }
        else {
            const token = ''
            setToken(token);
            setLoggingIn(false);
            setInvalidAlert(true);
        }
    }
    const handleRegisterSubmit = async e => {
        e.preventDefault();
        setToken("");
        if (showInvalidEmailAlert) {
            return;
        }
        if(!username || !password || !firstname || !lastname) {
            setShowRequiredAlert(true);
            return;
        }
        const loginResult = await registerUser({
            username,
            password,
            firstname,
            lastname,
            appIdentifier
        });
        if (loginResult.accessToken === "Duplicate") {
            setDupAlert(true);
        }
        else {
            await addEmail(username);
            const token = loginResult.accessToken
            setUserInfo(loginResult);
            setToken(token);
        }
    }
    const handleNewUser = () => {
        setNewUser(true);
        setShowInvalidEmailAlert(false);

    }
    const handleExistingUser = () => {
        setNewUser(false);
        setShowInvalidEmailAlert(false);

    }
    const handleLoginUserNameChange = async e => {
        e.preventDefault();
        const email = e.target.value;

        setInvalidAlert(false);
        setUserName(email);
        setShowInvalidEmailAlert(false);

    }
    const handleLoginUserNameBlur = async e => {
        e.preventDefault();
        const email = e.target.value;
        if (validateEmail(email)) {
            setUserName(email);
            setShowInvalidEmailAlert(false);
            setDupAlert(false);
        }
        else {
            setShowInvalidEmailAlert(true);
        }
    }
    const handleLoginPasswordChange = async e => {
        e.preventDefault();
        setPassword(e.target.value);
        setShowRequiredAlert(false);
        setInvalidAlert(false);
    }
    const handleNewUserNameBlur = async e => {
        e.preventDefault();
        const email = e.target.value;
        if (validateEmail(email)) {
            setUserName(email);
            setShowInvalidEmailAlert(false);
            setDupAlert(false);
        }
        else {
            setShowInvalidEmailAlert(true);
        }
    }
    const handleNewUserNameChange = async e => {
        e.preventDefault();
        const email = e.target.value;
        setShowRequiredAlert(false);
        setShowInvalidEmailAlert(false);
        setDupAlert(false);
        setUserName(email);
    }
    const handleFirstNameChange = async e => {
        e.preventDefault();
        setFirstName(e.target.value);
        setShowRequiredAlert(false);
        setDupAlert(false);
    }
    const handleLastNameChange = async e => {
        e.preventDefault();
        setLastName(e.target.value);
        setShowRequiredAlert(false);
        setDupAlert(false);
    }
    const handleSubmitForgotPwd = async e => {
        const reqID = await createPasswordResetRequest(username);
        //const response = await sendEmail(username, "", 'Password Reset', 'https://localhost:44377/passwordreset/' + reqID, '', appIdentifier);
        let h = "<div style='padding:12px'>";
        h += "<img src='https://mylivesphere.com/Images/lsletters.png' height='40px' title='LiveSphere'>";
        h += "</div>";
        h += "<div style='padding:48px'>";
        h += "<h1>Password Reset</h1>";
        h += "<h5>Click below to reset password</h5>";
        h += "<a href='" + 'https://mylivesphere.com/passwordreset/' + reqID + "'>Reset</a>";
        h += "</div>";
        const response = await sendEmail(username, "", 'Password Reset', h + reqID, '', appIdentifier);
        console.log(response);
        setShowForgotPwdEmailSent(true);
    }
    const copyright = () => {
        return (
            <Box sx={{ mt: 5 }}>
                <Typography sx={{ color: "white", m: "auto", }} variant="body2" align="center">
                    &copy; Copyright 2023
                    <Link color="inherit" href="http://simplebluetech.com" target="_blank" sx={{ ml: 1 }}>
                        livesphere
                    </Link>{' '}
                </Typography>
            </Box>
        );
    }
    const lsLogo = () => {
        return (
            <Box
                sx={{
                    p: 2,
                    py: {xs:3, sm:1},
                    backgroundColor: {xs:"rgba(0,0,0,.8)", sm:"transparent"},
                    marginBottom: { sm: "20px", } ,
                    maxWidth: { xs: "100%", sm: "calc(100% - 20px)" },
                }} >
                <img src='/Images/lsletters.png' style={{ maxWidth: "100%", maxHeight: { sm: "72px" } }} alt="" />
                <Typography component="h4" sx={{ pr:2, fontSize: "2rem",color: theme => theme.palette.common.dimwhite, textAlign:"right" }}>
                    Work hard.  Play harder...
                </Typography>
            </Box>
            )
    }
    const dailyQuote = () => {
        return (
            <Box
                sx={{
                    background: "rgba(255,255,255,.05)",
                    padding: "24px",
                    borderRadius: "16px",
                    position: "fixed",
                    left: "100px",
                    bottom: "100px",
                    maxWidth: "400px",
                    color: "#ccc",
                    display: { xs: "none", md: "flex" }
                }}>
                <Typography component="h6" sx={{ color: "white", textAlign: "center" }}>
                    <span>"And in the end it's not the years in your life that count. It's the life in your years."<br /><b>-Abraham Lincoln</b></span>
                </Typography>
            </Box>
            )
    };
    const forgotPwd = () => {
        return (
            <ModalDialog
                dark={true}
                show={showForgotPwd}
                title="Forgotten Password?"
                subTitle={!showForgotPwdEmailSent && "No problem!  Simply enter your email to receive password reset instructions."}
                handleClose={() => setShowForgotPwd(false)}
                handleSave={!showForgotPwdEmailSent && handleSubmitForgotPwd}
                saveLabel={!showForgotPwdEmailSent ? "Submit" : "Close"}
                fullWidth={true}
                maxWidth="xs"
                bold={true}
            >
                <SectionFieldGroup dark={true}>

                    {showForgotPwdEmailSent ?
                        <Alert variant="filled" severity="info">{"Password reset instructions send to " + username}</Alert>
                        :
                        <TextInput
                            dark={true}
                            cKey="fpemail"
                            cid="fpemail"
                            id="fpemail"
                            label="Email"
                            onChange={handleLoginUserNameChange}
                        />
                    }
                </SectionFieldGroup>
            </ModalDialog>
            
            )
    }

    const loginForm = () => {
        return (
            <Box
                component="form"
                noValidate
                onSubmit={handleLoginSubmit}
                sx={{
                    mt: { sm: 2 },
                    background: { xs: "rgba(0,0,0,.8)", sm: "rgba(0,0,0,.8)" } ,
                    p: { xs: 2, sm: 3 },
                    borderRadius: { xs: 0, sm: "16px" },
                    height: { xs: "-webkit-fill-available", sm: "unset" },
                    width:"100%"
                }}>

                <Typography component="h1" variant="h4" sx={{ color: "white", textAlign: "center" }}>
                    Sign in
                </Typography>
                <TextField
                    margin="normal"
                    variant="standard"
                    required
                    fullWidth
                    size="medium"
                    id="email"
                    label="Email Address"
                    placeholder="Email Address"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    defaultValue=""
                    onBlur={handleLoginUserNameBlur}
                    onChange={handleLoginUserNameChange}
                    InputProps={{ sx: { color: "#eee" } }}
                    color="secondary"
                   InputLabelProps={{ style: { color: "#ddd" } }}
                />
                {showInvalidEmailAlert && <Alert severity="error">Invalid email format.</Alert>}
                <TextField
                    margin="normal"
                    required
                    variant="standard"
                    fullWidth
                    size="medium"
                    name="password"
                    label="Password"
                    placeholder="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    onChange={handleLoginPasswordChange}
                    InputProps={{ sx: { color: "#eee" } }}
                    color="secondary"
                    InputLabelProps={{ style: { color: "#ddd" } }}
                />
                {showInvalidAlert && <Alert severity="error">Invalid username or password.  Please try again.</Alert>}
                <Stack spacing={1} sx={{ width: "100%" }}>
                    <Button
                        type="submit"
                        fullWidth
                        color="secondary"
                        variant="contained"
                        sx={{ mt: 3, mb: 2, boxShadow: "none" }}
                    >
                        Sign In
                    </Button>
                    <Button variant="link" sx={{ mt: 2, color: "white", m: "auto" }} onClick={() => setShowForgotPwd(!showForgotPwd)}>
                        Forgot password?
                    </Button>
                    <Button onClick={() => handleNewUser()} sx={{ mt: 1, color: "white", m: "auto" }}>
                        {"Don't have an account? Sign Up"}
                    </Button>
             
            
                  
           

                </Stack>
                {copyright()}
            </Box>
            )
    }
    const registerForm = () => {
        return (
            <Box
                component="form"
                noValidate
                onSubmit={handleRegisterSubmit}
                sx={{
                    mt: { sm: 2 },
                    background: { xs: "rgba(0,0,0,.8)", sm: "rgba(0,0,0,.8)" },
                    p: { xs: 2, sm: 3 },
                    borderRadius: { xs: 0, sm: "16px" },
                    height: { xs: "-webkit-fill-available", sm: "unset" },
                    width: "100%"
                }}>
                <Typography component="h1" variant="h4" sx={{ color: "white", textAlign: "center" }}>
                    Sign up for new account
                </Typography>
                <TextField
                    autoComplete="new-password"
                    margin="normal"
                    variant="standard"
                    required
                    fullWidth
                    size="medium"
                    id="newemail"
                    label="Email Address"
                    placeholder="Email Address"
                    name="newemail"
                    autoFocus
                    onBlur={handleNewUserNameBlur}
                    onChange={handleNewUserNameChange}
                    defaultValue=""
                    InputLabelProps={{ style: { color: "#ddd" } }}
                    InputProps={{ autoComplete: 'new-password', sx: { color: "#ccc" } }}
                />
                {showInvalidEmailAlert && <Alert severity="error">Invalid email format.</Alert>}
                <TextField
                    autoComplete="new-password"
                    margin="normal"
                    required
                    variant="standard"
                    fullWidth
                    size="medium"
                    name="rpassword"
                    label="Password"
                    placeholder="Password"
                    type="password"
                    id="newpassword"
                    onChange={handleLoginPasswordChange}
                    defaultValue=""
                    InputLabelProps={{ style: { color: "#ddd" } }}
                    InputProps={{ autoComplete: 'new-password', sx: { color: "#ccc" } }}
                />
                <TextField
                    autoComplete="given-name"
                    margin="normal"
                    variant="standard"
                    required
                    fullWidth
                    size="medium"

                    label="First Name"
                    placeholder="First Name"
                    name="rfirstname"
                    onChange={handleFirstNameChange}
                    defaultValue=""
                    InputLabelProps={{ style: { color: "#ddd" } }}
                    InputProps={{ autoComplete: 'given-name', sx: { color: "#ccc" }, form: { autoComplete: 'given-name' } }}
                />
                <TextField
                    autoComplete="new-password"
                    margin="normal"
                    variant="standard"
                    required
                    fullWidth
                    size="medium"
                    id="newlastname"
                    label="Last Name"
                    placeholder="Last Name"
                    name="rlastname"
                    onChange={handleLastNameChange}
                    defaultValue=""
                    InputLabelProps={{ style: { color: "#ddd" } }}
                    InputProps={{ autoComplete: 'new-password', sx: { color: "#ccc" } }}

                />
                {showRequiredAlert && <Alert severity="error">All fields are required.</Alert>}
                {showDupAlert && <Alert severity="error">Email is associated to an existing LiveSphere account.  If this is your email, please sign in.  Otherwise, use a different email address.</Alert>}
                <Stack spacing={1} sx={{ width: "100%" }}>
                    <Button
                        type="submit"
                        fullWidth
                        color="secondary"
                        variant="contained"
                        sx={{ mt: 3, mb: 2, boxShadow: "none" }}
                    >
                        Sign In
                    </Button>
                    <Button onClick={() => handleExistingUser()} sx={{ color: "white" }}>
                        {"Already have an account? Sign in"}
                    </Button>
   
                </Stack>
                {copyright()}
            </Box>
            )
    }

    let contents = 

        <Box sx={{
            width: "100%",
            backgroundImage: 'url(/Images/SphereLeft.jpeg)',
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
                t.palette.mode === 'light' ? t.palette.grey[900] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',

        }}>


            {dailyQuote()}
  
            <Grid container component="main" sx={{ height: '100vh' }}>
                <CssBaseline />
                <Grid
                    item
                    xs={false}
                    sm={4}
                    md={7}
                >
                </Grid>
                <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square sx={{ background: "rgba(255,255,255,0.05)" }}>
                    <Box
                        sx={{
                            height: { xs: "100%", sm: undefined },
                            my: { xs: 0, sm: 8 },
                            mx: { xs: 0, sm: 4 },
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        {lsLogo()}
                        {!newUser ? loginForm() : registerForm()}

                    </Box>
                </Grid>
            </Grid>
        </Box>

    return (
        <div>
            {contents}
            {forgotPwd()}
            <Spin open={loggingIn} />
        </div>
    );
}


Login.propTypes = {
    setToken: PropTypes.func.isRequired
};