import React, { Component } from 'react';

//Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSnowflake, faCloudRain, faCloudShowersHeavy, faSun, faCloud } from '@fortawesome/free-solid-svg-icons'

//Material UI
import Popover from '@mui/material/Popover';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';

//Helper Functions
const googleHolidayApiUrl = 'https://www.googleapis.com/calendar/v3/calendars/en.usa%23holiday%40group.v.calendar.google.com/events?key=AIzaSyAnmqvxR1FpaYk8Vr9MvtvqbR-ioxzakRc'
//const weatherApiUrlZip = 'http://api.openweathermap.org/data/2.5/weather?units=imperial&appid=71d385b8abda1f30e9663a70b140b959&zip='
const weatherApiUrlBase = 'https://api.openweathermap.org/data/2.5/onecall?units=imperial'
const dateWithoutTime = (date) => {
    date.setHours(0, 0, 0, 0)
    return date;
}
const isToday = (someDate) => {
    const today = new Date()
    return someDate.getDate() == today.getDate() &&
        someDate.getMonth() == today.getMonth() &&
        someDate.getFullYear() == today.getFullYear()
}
const numDaysBetween = (d1, d2) => {
    var diff = d1.getTime() - d2.getTime();
    return diff / (1000 * 60 * 60 * 24);
};
const windDirection = (deg) => {

    if (deg >= 350 || deg < 20) return "N";
    if (deg >= 20 && deg < 40) return "N";
    if (deg >= 40 && deg < 60) return "NE";
    if (deg >= 60 && deg < 80) return "E/NE";
    if (deg >= 80 && deg < 110) return "E";
    if (deg >= 110 && deg < 130) return "E/SE";
    if (deg >= 130 && deg < 150) return "SE";
    if (deg >= 150 && deg < 170) return "S/SE";
    if (deg >= 170 && deg < 200) return "S";
    if (deg >= 200 && deg < 220) return "S/SW";
    if (deg >= 220 && deg < 240) return "SW";
    if (deg >= 240 && deg < 260) return "W/SW";
    if (deg >= 260 && deg < 290) return "W";
    if (deg >= 290 && deg < 310) return "W/NW";
    if (deg >= 310 && deg < 330) return "NW";
    if (deg >= 330 && deg < 350) return "N/NW";
}
const DayDetailWeatherIcon = (weather, size, useColor) => {
    let icon, color;
    switch (weather) {
        case 800:
            icon = faSun;
            color = "yellow";
            break;
        default:
            switch (parseInt(weather.toString().charAt(0))) {
                case 2:
                    icon= faCloudShowersHeavy;
                    color= "#ccc";
                    break;
                case 3:
                case 5:
                    icon= faCloudRain;
                    color= "#ddd";
                    break;
                case 6:
                    icon = faSnowflake;
                    color = "lightblue";
                    break;
                case 7:
                case 8:
                    icon = faCloud;
                    color = "#eee";
                    break;
                default:
                    icon = faSun;
                    color = "white";

            }
    }
    if (useColor) color = "#384370";
    return <FontAwesomeIcon icon={icon} style={{ color: color, fontSize: size === "small" ? "16px" : "20px", margin: "8px" }} />
}
const openWeatherAPI = '71d385b8abda1f30e9663a70b140b959';

export class Headlines extends Component {
  constructor(props) {
      super(props);
      this.state = {
          holidays: [],
          anchorEl: null,
      };
  }
  
    componentDidMount() {
        try {
            this.getLocationThenWeather();
        }
        catch (err) {
            console.log(err);
        }
        try {
            this.getHolidays();
        }
        catch (err) {
            console.log(err);
        }
    }

    //Get Data
    getHolidays = async () => {
        let date = new Date();
        date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
        date.setHours(0, 0, 0, 0);
        if (!this.state.holidayList) {
            let url = googleHolidayApiUrl;
            const response = await fetch(url);
            if (response.status === 200) {
                const data = await response.json();
                if (data) {
                    this.setState({ holidayList: data.items });
                    this.setMonthHolidays(date);
                }
            }
        }

    }
    setMonthHolidays = (date) => {
        let month = date.getMonth();

        let year = date.getFullYear();
        let firstDayOfMonth = new Date(year, month, 1);
        let lastDayOfMonth = new Date(year, month + 1, 0);
        let firstDayOfYear = new Date(year, 0, 1);
        let startTime = firstDayOfYear.toISOString();
        let lastDayOfYear = new Date(year, 12, 0, 23, 59, 59);
        let endTime = lastDayOfYear.toISOString();
        if (this.state.holidayList && this.state.holidayList.length > 0) {
            let holidays = [];
            for (var i = 0; i < this.state.holidayList.length; i++) {
                let item = this.state.holidayList[i];
                let dt = new Date(item.start.date);
                dt.setDate(dt.getDate() + 1);
                if (dt >= firstDayOfMonth && dt <= lastDayOfMonth) {
                    const hDay = { name: item.summary, date: dt };
                    holidays.push(hDay);
                }
            }
            this.setState({ holidays: holidays });
        }

    }
    getLocationThenWeather = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(this.storeLocation);
        }
    }
    storeLocation = (position) => {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        this.setState({ position: position.coords });
        this.getWeather(latitude, longitude);
    }
    getWeather = async (latitude, longitude) => {
        if (!this.state.weather) {
            let url = `${weatherApiUrlBase}&lat=${latitude}&lon=${longitude}&appid=${openWeatherAPI}`
            const response = await fetch(url);
            if (response.status === 200) {
                const data = await response.json();
                if (data) {
                    this.setState({ weather: data });
                }
            }
        }
    }
    //render
    weatherDetail = (desc, high, low, windspeed, winddirection, humidity, uvi, day, weatherCode) => {
        return (
            <Popover
                id="wdetail"
                open={this.state.anchorEl !== null}
                anchorEl={this.state.anchorEl}
                onClose={() => this.setState({ anchorEl: null })}
                PaperProps={{
                    sx: {
                        backgroundColor: (theme) => this.props.dark ? theme.palette.backgroundMenu.dark : theme.palette.backgroundMenu.light,
                        border: (theme) => this.props.dark ? theme.palette.borderMenu.dark : theme.palette.borderMenu.light,
                        color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.dimblack
                    },
                    elevation: this.props.dark ? 0 : 8,
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
            >
                <Box sx={{ color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.dimblack, p: 1, }}>
                    <Stack sx={{ width: "100%" }}>
                        <Box display="flex">
                            <Typography variant="body1" sx={{ fontWeight: 600, fontSize: ".8rem", my: "auto", }}>{isToday(day) ? "Today's Weather" : "Weather Forecast"}</Typography>
                            <Box sx={{ flexGrow: 1 }} />
                            <Tooltip title={this.props.showMoreWeather ? "Less Details" : "Show Details"}>
                                <IconButton key="moreWeather" onClick={() => this.setState({ showMoreWeather: !this.state.showMoreWeather })} sx={{ color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.dimblack }}>
                                    {this.state.showMoreWeather ? <MoreVertIcon sx={{ fontSize: 18 }} /> : <MoreHorizIcon sx={{ fontSize: 18 }} /> }
                                </IconButton>
                            </Tooltip>
                        </Box>
                        <Box display="flex">
                            <Tooltip title={desc}>
                                <Box sx={{ mr: 1 }}>
                                    {DayDetailWeatherIcon(weatherCode, "large", true)}
                                </Box>
                            </Tooltip>
                            <Box sx={{ flexGrow: 1 }} />
                            <Typography key={"weather3"} variant="body2" sx={{ my: "auto", marginRight: "20px", fontSize: ".8rem", color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.dimblack }}>
                                {`Current: ${Math.floor(this.state.weather.current.temp) + String.fromCharCode(176)}F`}
                            </Typography>
                            <Typography key={"weather3"} variant="body2" sx={{ my: "auto", marginRight: "20px", fontSize: ".8rem", color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.dimblack }}>
                                {"Hi: " + high + String.fromCharCode(176) + "F"}
                            </Typography>
                            <Typography key={"weather4"} variant="body2" sx={{ mr: 1, my: "auto", fontSize: ".8rem", color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.dimblack }}>
                                {"Lo: " + low + String.fromCharCode(176) + "F"}
                            </Typography>
                        </Box>
                        {this.state.showMoreWeather &&
                            <Stack spacing={2}>
                                <Box display="flex" sx={{ padding: "0px", marginTop: "4px" }}>
                                    <Typography key={"weather4"} variant="body2" sx={{ marginRight: "24px", fontSize: ".7rem", color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.dimblack }}>
                                        {"Wind: " + windspeed + "mph " + winddirection}
                                    </Typography>
                                    <Typography key={"weather5"} variant="body2" sx={{ marginRight: "24px", fontSize: ".7rem", color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.dimblack }}>
                                        {"Humidity: " + humidity + "%"}
                                    </Typography>
                                    <Typography key={"weather6"} variant="body2" sx={{ fontSize: ".7rem", color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.dimblack }}>
                                        {"UV Index: " + uvi}
                                    </Typography>
                                </Box>
                                {this.importantDaysDetail()}
                            </Stack>
                        }
                    </Stack>
                </Box>
            </Popover>
        )
    }
    weatherSummary = (desc, high, low) => {
        return (
            <Stack>
                <span>{`${desc}  ${Math.floor(this.state.weather.current.temp) + String.fromCharCode(176)}F`}</span>
                <span>{"High: " + high + String.fromCharCode(176) + "F Low: " + low + String.fromCharCode(176) + "F"}</span>
            </Stack>
            )
    }
    weatherForecastSummary = () => {
        if (this.state.weather) {
            let day = new Date();
            day.setMinutes(day.getMinutes() - day.getTimezoneOffset());
            let today = dateWithoutTime(new Date()).getTime();
            let refDay = dateWithoutTime(new Date(day)).getTime();
            let diff = (refDay - today) / (1000 * 3600 * 24);
            const forecast = (this.state.weather.daily[0]);
            if (forecast) {
                const high = parseInt(forecast.temp.max);
                const low = parseInt(forecast.temp.min);
                const uvi = parseInt(forecast.uvi);
                const windspeed = Math.round(forecast.wind_speed);
                const winddirection = windDirection(forecast.wind_deg);
                const desc = forecast.weather[0].main;
                const weatherCode = forecast.weather[0].id;
                const humidity = parseInt(forecast.humidity);

                return (
                    <>
                        <Box sx={{ display: { xs: "block", sm: "none" } }} >
                            <Tooltip title={this.weatherSummary(desc, high, low)}>
                                <IconButton onClick={(e) => this.setState({ anchorEl: e.currentTarget })} sx={{ p: { xs: 0, sm: undefined }, ml: { xs: 1, sm: undefined } }} >
                                    {DayDetailWeatherIcon(weatherCode, "small")}
                                </IconButton>
                            </Tooltip>
                        </Box>
                        <Box sx={{ display: { xs: "none", sm: "block" } }} >
                            <Tooltip title={this.weatherSummary(desc, high, low)}>
                                <IconButton onClick={(e) => this.setState({ anchorEl: e.currentTarget })} sx={{ p: { xs: 0, sm: undefined }, ml: { xs: 1, sm: undefined } }} >
                                    {DayDetailWeatherIcon(weatherCode, "large")}
                                </IconButton>
                            </Tooltip>
                        </Box>
                        {this.weatherDetail(desc, high, low, windspeed, winddirection, humidity, uvi, day, weatherCode)}
                    </>
                )

            }
        }
        return null;
    }
    importantDaysDetail = () => {
        let comingHolidays = [];
        let birthdays = [];
        let anniversaries = [];
        let goals = [];

        if (this.state.holidayList) {
            comingHolidays = this.state.holidayList.filter((x) => numDaysBetween(new Date(x.start.date), new Date()) > 0 && numDaysBetween(new Date(x.start.date), new Date()) < 30);
            comingHolidays.sort(function (a, b) {
                var keyA = new Date(a.start.date),
                    keyB = new Date(b.start.date);
                // Compare the 2 dates
                if (keyA < keyB) return -1;
                if (keyA > keyB) return 1;
                return 0;
            });
        }

        if (this.state.selectedDayDetails && this.state.selectedDayDetails.importantDates && this.state.selectedDayDetails.importantDates.length > 0) {
            birthdays = this.state.selectedDayDetails.importantDates.filter((x) => x.type === "Birthday");
            if (birthdays) {
                birthdays.sort(function (a, b) {
                    var keyA = a.daysUntil,
                        keyB = b.daysUntil;
                    // Compare the 2 dates
                    if (keyA < keyB) return -1;
                    if (keyA > keyB) return 1;
                    return 0;
                });
            }

            anniversaries = this.state.selectedDayDetails.importantDates.filter((x) => x.type === "Anniversary");
            if (anniversaries) {
                anniversaries.sort(function (a, b) {
                    var keyA = a.daysUntil,
                        keyB = b.daysUntil;
                    // Compare the 2 dates
                    if (keyA < keyB) return -1;
                    if (keyA > keyB) return 1;
                    return 0;
                });
            }

            goals = this.state.selectedDayDetails.importantDates.filter((x) => x.type === "Goal");
            if (goals) {
                goals.sort(function (a, b) {
                    var keyA = a.daysUntil,
                        keyB = b.daysUntil;
                    // Compare the 2 dates
                    if (keyA < keyB) return -1;
                    if (keyA > keyB) return 1;
                    return 0;
                });
            }
        }


        return (
            <Stack sx={{ width: "100%", padding: "0px" }}>
                {(comingHolidays && comingHolidays.length > 0) &&
                    <>
                        <Typography key={"id2"} variant="subtitle2" sx={{ fontSize: ".8rem", color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.dimblack }}>
                            Upcoming Holidays
                        </Typography>
                        <Box sx={{ padding: "8px" }}>
                            {comingHolidays.map((item, index) => {
                                const today = new Date();
                                today.setHours(0, 0, 0, 0);
                                return (
                                    <Typography key={"id2" + index} variant="body2" sx={{ fontSize: ".7rem", color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.dimblack }}>
                                        {item.summary + " " + Math.round(numDaysBetween(new Date(item.start.date), today)) + " days away"}
                                    </Typography>
                                )
                            })}
                        </Box>
                    </>
                }

                {(birthdays && birthdays.length > 0) &&
                    <>
                        <Typography key={"id1"} variant="subtitle2" sx={{ fontSize: ".8rem", color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.dimblack }}>
                            Upcoming Birthdays
                        </Typography>
                        <Box sx={{ padding: "8px" }}>
                            {birthdays.map((item, index) => {
                                let term = "away";
                                let timer = item.daysUntil;
                                if (item.daysUntil < 0) {
                                    term = "ago";
                                    timer = -timer;
                                }
                                return (
                                    <Typography key={"id" + index} variant="body2" sx={{ fontSize: ".7rem", color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.dimblack }}>
                                        {item.name + " " + timer + " days " + term}
                                    </Typography>
                                )
                            })}
                        </Box>
                    </>
                }


                {(anniversaries && anniversaries.length > 0) &&
                    <>
                        <Typography key={"id1"} variant="subtitle2" sx={{ fontSize: ".8rem", color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.dimblack }}>
                            Upcoming Anniversaries
                        </Typography>
                        <Box sx={{ padding: "8px" }}>
                            {anniversaries.map((item, index) => {
                                let term = "away";
                                let timer = item.daysUntil;
                                if (item.daysUntil < 0) {
                                    term = "ago";
                                    timer = -timer;
                                }
                                return (
                                    <Typography key={"id" + index} variant="body2" sx={{ fontSize: ".7rem", color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.dimblack }}>
                                        {item.name + " " + timer + " days " + term}
                                    </Typography>
                                )
                            })}
                        </Box>
                    </>
                }


                {(goals && goals.length > 0) &&
                    <>
                        <Typography key={"id1"} variant="subtitle2" sx={{ fontSize: ".8rem", color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.dimblack }}>
                            Personal Goals Due Soon
                        </Typography>
                        <Box sx={{ padding: "8px" }}>
                            {goals.map((item, index) => {
                                let term = "away";
                                let timer = item.daysUntil;
                                if (item.daysUntil < 0) {
                                    term = "ago";
                                    timer = -timer;
                                }
                                return (
                                    <Typography key={"id" + index} variant="body2" sx={{ fontSize: ".7rem", color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.dimblack }}>
                                        {item.name + " due in " + timer + " days"}
                                    </Typography>
                                )
                            })}
                        </Box>
                    </>
                }

            </Stack>
        )
    }

    render() {
        return this.weatherForecastSummary();
    }
}
