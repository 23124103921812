// ----------------------------------------------------------------------

export default function Input(theme) {
    return {
       
    //MuiInputBase: {
    //  styleOverrides: {
    //        input: {
    //        color:"white",
    //      '&::placeholder': {
    //        opacity: 1,
    //        color: "#888"
    //      }
    //    }
    //  }
    //},
    MuiInput: {
            styleOverrides: {
                underline:
                {
                    '&:before': {
                        borderBottomColor: "#555",
                    },

                },
    

            }
    },
    //MuiFilledInput: {
    //  styleOverrides: {
    //    root: {
    //      backgroundColor: theme.palette.grey[500_12],
    //      '&:hover': {
    //        backgroundColor: theme.palette.grey[500_16]
    //      },
    //      '&.Mui-focused': {

    //      },
    //      '&.Mui-disabled': {
    //        backgroundColor: theme.palette.action.disabledBackground
    //      }
    //    },
    //    underline: {
    //      '&:before': {
    //        borderBottomColor: theme.palette.grey[500_56]
    //      }
    //    }
    //  }
    //},
    //MuiOutlinedInput: {
    //  styleOverrides: {
    //    root: {
    //      '& .MuiOutlinedInput-notchedOutline': {
    //        borderColor: theme.palette.grey[500_32]
    //      },
    //      '&.Mui-disabled': {
    //        '& .MuiOutlinedInput-notchedOutline': {
    //          borderColor: theme.palette.action.disabledBackground
    //        }
    //      }
    //    }
    //  }
    //}
  };
}
