import React, { Component } from 'react';
import { ModalDialog } from '../../Utilities/ModalDialog';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Slider from '@mui/material/Slider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Tooltip from '@mui/material/Tooltip';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { HexColorInput, HexColorPicker } from "react-colorful";
import Cropper from 'react-easy-crop';
//import getCroppedImg from './CropImage';
import { EyeDropper, OnChangeEyedrop, useEyeDrop } from 'react-eyedrop'

import { blobToBase64 } from '../../../DataServices/Utility'
import { updatePhoto } from '../../../DataServices/PhotoService'
//import 'context-filter-polyfill/dist/index.js'


export const createImage = (url) =>
    new Promise((resolve, reject) => {
        const image = new Image()
        image.addEventListener('load', () => resolve(image))
        image.addEventListener('error', (error) => reject(error))
        image.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
        image.src = url
    })

export function getRadianAngle(degreeValue) {
    return (degreeValue * Math.PI) / 180
}

export function rotateSize(width, height, rotation) {
    const rotRad = getRadianAngle(rotation)

    return {
        width:
            Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
        height:
            Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
    }
}

export default async function getCroppedImg(
    imageSrc,
    pixelCrop,
    rotation = 0,
    flip = { horizontal: false, vertical: false },
    backgroundColor,
    filters,
    base64
) {
    const image = await createImage(imageSrc)
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')

    if (!ctx) {
        return null
    }

    const rotRad = getRadianAngle(rotation)

    // calculate bounding box of the rotated image
    const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
        image.width,
        image.height,
        rotation
    )

    // set canvas size to match the bounding box
    canvas.width = bBoxWidth
    canvas.height = bBoxHeight


    //add selected background color to canvas
    ctx.fillStyle = backgroundColor ?? "#222";
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    if (filters) {
        ctx.filter = filters;
    }


    // translate canvas context to a central location to allow rotating and flipping around the center
    ctx.translate(bBoxWidth / 2, bBoxHeight / 2)
    ctx.rotate(rotRad)
    ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1)
    ctx.translate(-image.width / 2, -image.height / 2)

    //add selected background color to canvas
    ctx.fillStyle = backgroundColor ?? "#222";
    ctx.fillRect(0, 0, canvas.width, canvas.height);


    // draw rotated image
    ctx.drawImage(image, 0, 0)

    // croppedAreaPixels values are bounding box relative
    // extract the cropped image using these values
    const data = ctx.getImageData(
        pixelCrop.x,
        pixelCrop.y,
        pixelCrop.width,
        pixelCrop.height
    )

    // set canvas width to final desired crop size - this will clear existing context
    canvas.width = pixelCrop.width
    canvas.height = pixelCrop.height
  
    //add selected background color to canvas
    ctx.fillStyle = backgroundColor ?? "#222";
    ctx.fillRect(0, 0, canvas.width, canvas.height);


    // paste generated rotate image at the top left corner
    ctx.putImageData(data, 0, 0)

    
    if (base64) {
        // As Base64 string
        return canvas.toDataURL();
    }
    else { 
        // As a blob
        return new Promise((resolve, reject) => {
            canvas.toBlob((file) => { resolve(file) })
        })
    }
}

export class PhotoEditor extends Component {
    constructor(props) {
        super(props);  
        this.state = {
            src: this.props.src,
            page: 0,
            zoom: 1,
            crop: { x: 0, y: 0 },
            savedCrop: { x: 0, y: 0 },
            rotation: 0,
            blur: 0,
            brightness: 1,
            contrast: 1,
            grayscale: 0,
            hueRotate: 0,
            invert: 0,
            saturate: 100,
            opacity: 100,
            sepia: 0,
            aspect: 1 / 1,
            backgroundColor: "#transparent",
            showColorPicker: false,
            restrictPosition: true

        }
    }

    //event handlers
    resetFilters = () => {
        this.setState({
            blur: 0,
            brightness: 1,
            contrast: 1,
            grayscale: 0,
            hueRotate: 0,
            invert: 0,
            saturate: 100,
            opacity: 100,
            sepia: 0,
        });
    }
    handleGoToFilters = (e) => {
        this.setState({
            filteredB64: this.state.croppedB64,
            page: 1,
        })
    }
    handlePickColor = () => {
        this.setState({ showColorPicker: false });
        this.onCropComplete(null, this.state.savedCrop);

    }
    onCropComplete = async (croppedArea, croppedAreaPixels) => {
        const croppedImage = await getCroppedImg(
            this.state.src,
            croppedAreaPixels,
            this.state.rotation,
            false,
            this.state.backgroundColor,
            null,
            false
        )

        const fileUrl = URL.createObjectURL(croppedImage);
        this.setState({ croppedB64: fileUrl, savedCrop: croppedAreaPixels });
       
    }
    onFilterComplete = async () => {
        const croppedImageBlob = await getCroppedImg(
            this.state.src,
            this.state.savedCrop,
            this.state.rotation,
            false,
            this.state.backgroundColor,
            this.computeFilters(),
            false
        )

        const b64withPrefix = await blobToBase64(croppedImageBlob);
        const base64Image = b64withPrefix.split(',')[1];
        this.props.onSave(base64Image);
        this.setState({ finalBlob: croppedImageBlob });

        const file = new File([croppedImageBlob], "image.png", { type: "image/png", lastModified: Date.now() })
        updatePhoto(file, this.props.photoID);
    }

    handleSaveEdit = async () => {
        await this.onFilterComplete();


    }
    //render funcs
    zoomSlider = () => {
        return (
            <Stack sx={{ width: "20%", px: { xs: 2, sm: 3 }, py: 2 }}>
                <Typography gutterBottom sx={{ fontSize: { xs: ".8rem", sm: "1rem" }, color: theme => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.black }}>
                    Zoom
                </Typography>
                <Slider
                    value={this.state.zoom}
                    onChange={(e, value) => this.setState({ zoom: value })}
                    step={0.05}
                    min={0.1}
                    max={6.0}
                    aria-label="Default"
                    valueLabelDisplay="auto"
                    color="secondary"
                />
            </Stack>
            )
    }
    rotateSlider = () => {
        return (
            <Stack sx={{ width: "20%", px: { xs: 2, sm: 3 }, py: 2 }}>
                <Typography gutterBottom sx={{ fontSize: { xs: ".8rem", sm: "1rem" }, color: theme => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.black }}>
                    Rotation
                </Typography>
                <Slider
                    value={this.state.rotation}
                    onChange={(e, value) => this.setState({ rotation: value })}
                    step={15.0}
                    min={0.0}
                    max={360.0}
                    aria-label="Default"
                    valueLabelDisplay="auto"
                    color="secondary"
                />
            </Stack>
            )
    }
    aspectSwitch = () => {
        return (
            <Stack sx={{ width: "20%", px: { xs: 2, sm: 3 }, py: 2 }}>
                <FormControlLabel
                    labelPlacement="top"
                    control={<Switch color="secondary" checked={this.state.aspect === 1 / 1} onChange={(e) => this.setState({ aspect: e.target.checked ? 1 / 1 : 4 / 3 })} size="small" />}
                    label={
                        <Typography gutterBottom sx={{ fontSize: { xs: ".8rem", sm: "1rem" }, color: theme => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.black }}>
                            {this.state.aspect === 1 / 1 ? "Square" : "Rectangle"}
                        </Typography>
                    }
                />
            </Stack>
            )
    }
    restrictPositionSwitch = () => {
        return (
            <Stack sx={{ width: "20%", px: { xs: 2, sm: 3 }, py: 2 }}>
                <FormControlLabel
                    labelPlacement="top"
                    control={<Switch color="secondary" checked={this.state.restrictPosition} onChange={(e) => this.setState({ restrictPosition: e.target.checked})} size="small" />}
                    label={
                        <Typography gutterBottom sx={{ fontSize: { xs: ".8rem", sm: "1rem" }, color: theme => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.black }}>
                            Restrict
                        </Typography>
                    }
                />
            </Stack>
        )
    }
    cropperComponent = () => {
        return (
            <Cropper
                image={this.props.src}
                crop={this.state.crop}
                zoom={this.state.zoom}
                rotation={this.state.rotation}
                onCropChange={crop => this.setState({ crop })}
                onCropComplete={this.onCropComplete}
                onZoomChange={(zoom) => this.setState({ zoom })}
                aspect={this.state.aspect}
                zoomWithScroll
                restrictPosition={this.state.restrictPosition}
                //objectFit="contain"
                showGrid
                style={{
                    containerStyle: {
                        top: "150px",
                        bottom: this.props.width < 600 ? "56px" : "0px",
                        backgroundColor: this.props.dark ? "#222" : "unset"
                    }
                }}

            />
            )
    }
    croppedPreview = () => {
        return (
            <Box sx={{ py: 1, px: 2 }} onClick={() => this.setState({ showColorPicker: true })}>
                <img src={this.state.croppedB64} height={80} width="auto" style={{ backgroundColor: this.state.backgroundColor }} />
            </Box>
            )
    }
    blurSlider = () => {
        return (
            <Box sx={{ mx: "auto", width: "100%", px: { xs: 1.5, sm: 2.5 }, py: { xs: 0, sm: 1 } }}>
                <Typography sx={{ textAlign: "center", mb: "0px!important", fontSize: ".8rem", color: theme => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.black }}>
                    Blur
                </Typography>
                <Slider
                    value={this.state.blur}
                    onChange={(e, value) => this.setState({ blur: value })}
                    step={0.1}
                    min={0.0}
                    max={10.0}
                    aria-label="Default"
                    valueLabelDisplay="auto"
                    color="secondary"
                    size={this.props.width < 600 && "small"}
                />
            </Box>
            )
    }
    brightnessSlider = () => {
        return (
            <Box sx={{ mx: "auto", width: "100%", px: { xs: 1.5, sm: 2.5 }, py: { xs: 0, sm: 1 } }}>
                <Typography sx={{ textAlign: "center", mb: "0px!important", fontSize: ".8rem", color: theme => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.black }}>
                    Brightness
                </Typography>
                <Slider
                    value={this.state.brightness}
                    onChange={(e, value) => this.setState({ brightness: value })}
                    step={0.1}
                    min={0.0}
                    max={10.0}
                    aria-label="Default"
                    valueLabelDisplay="auto"
                    color="secondary"
                    size={this.props.width < 600 && "small"}
                />
            </Box>
        )
    }
    contrastSlider = () => {
        return (
            <Box sx={{ mx: "auto", width: "100%", px: { xs: 1.5, sm: 2.5 }, py: { xs: 0, sm: 1 } }}>
                <Typography sx={{ textAlign: "center", mb: "0px!important", fontSize: ".8rem", color: theme => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.black }}>
                    Contrast
                </Typography>
                <Slider
                    value={this.state.contrast}
                    onChange={(e, value) => this.setState({ contrast: value })}
                    step={0.1}
                    min={0.0}
                    max={10.0}
                    aria-label="Default"
                    valueLabelDisplay="auto"
                    color="secondary"
                    size={this.props.width < 600 && "small"}
                />
            </Box>
        )
    }
    grayscaleSlider = () => {
        return (
            <Box sx={{ mx: "auto", width: "100%", px: { xs: 1.5, sm: 2.5 }, py: { xs: 0, sm: 1 } }}>
                <Typography sx={{ textAlign: "center", mb: "0px!important", fontSize: ".8rem", color: theme => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.black }}>
                    Grayscale
                </Typography>
                <Slider
                    value={this.state.grayscale}
                    onChange={(e, value) => this.setState({ grayscale: value })}
                    step={1.0}
                    min={0.0}
                    max={100.0}
                    aria-label="Default"
                    valueLabelDisplay="auto"
                    color="secondary"
                    size={this.props.width < 600 && "small"}
                />
            </Box>
        )
    }
    hueRotateSlider = () => {
        return (
            <Box sx={{ mx: "auto", width: "100%", px: { xs: 1.5, sm: 2.5 }, py: { xs: 0, sm: 1 } }}>
                <Typography sx={{ textAlign: "center", mb: "0px!important", fontSize: ".8rem", color: theme => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.black }}>
                    Hue
                </Typography>
                <Slider
                    value={this.state.hueRotate}
                    onChange={(e, value) => this.setState({ hueRotate: value })}
                    step={1.0}
                    min={0.0}
                    max={360.0}
                    aria-label="Default"
                    valueLabelDisplay="auto"
                    color="secondary"
                    size={this.props.width < 600 && "small"}
                />
            </Box>
        )
    }
    invertSlider = () => {
        return (
            <Box sx={{ mx: "auto", width: "100%", px: { xs: 1.5, sm: 2.5 }, py: { xs: 0, sm: 1 } }}>
                <Typography sx={{ textAlign: "center", mb: "0px!important", fontSize: ".8rem", color: theme => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.black }}>
                    Invert
                </Typography>
                <Slider
                    value={this.state.invert}
                    onChange={(e, value) => this.setState({ invert: value })}
                    step={1.0}
                    min={0.0}
                    max={100.0}
                    aria-label="Default"
                    valueLabelDisplay="auto"
                    color="secondary"
                    size={this.props.width < 600 && "small"}
                />
            </Box>
        )
    }
    saturateSlider = () => {
        return (
            <Box sx={{ mx: "auto", width: "100%", px: { xs: 1.5, sm: 2.5 }, py: { xs: 0, sm: 1 } }}>
                <Typography sx={{ textAlign: "center", mb: "0px!important", fontSize: ".8rem", color: theme => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.black }}>
                    Saturation
                </Typography>
                <Slider
                    value={this.state.saturate}
                    onChange={(e, value) => this.setState({ saturate: value })}
                    step={1.0}
                    min={0.0}
                    max={300.0}
                    aria-label="Default"
                    valueLabelDisplay="auto"
                    color="secondary"
                    size={this.props.width < 600 && "small"}
                />
            </Box>
        )
    }
    opacitySlider = () => {
        return (
            <Box sx={{ mx: "auto", width: "100%", px: { xs: 1.5, sm: 2.5 }, py: { xs: 0, sm: 1 } }}>
                <Typography sx={{ textAlign: "center", mb: "0px!important", fontSize: ".8rem", color: theme => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.black }}>
                    Opacity
                </Typography>
                <Slider
                    value={this.state.opacity}
                    onChange={(e, value) => this.setState({ opacity: value })}
                    step={1.0}
                    min={0.0}
                    max={100.0}
                    aria-label="Default"
                    valueLabelDisplay="auto"
                    color="secondary"
                    size={this.props.width < 600 && "small"}
               
                />
            </Box>
        )
    }
    sepiaSlider = () => {
        return (
            <Box sx={{ mx: "auto", width: "100%", px: { xs: 1, sm: 2.5 }, py: { xs: 0, sm: 1 }}}>
                <Typography sx={{ textAlign: "center", mb: "0px!important", fontSize:".8rem", color: theme => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.black }}>
                    Sepia
                </Typography>
                <Slider
                    value={this.state.sepia}
                    onChange={(e, value) => this.setState({ sepia: value })}
                    step={1.0}
                    min={0.0}
                    max={100.0}
                    aria-label="Default"
                    valueLabelDisplay="auto"
                    color="secondary"
                    size={this.props.width < 600 && "small"}
                
                />
            </Box>
        )
    }
    computeFilters = () => {
        let filters = `blur(${this.state.blur}px) brightness(${this.state.brightness}) contrast(${this.state.contrast}) grayscale(${this.state.grayscale}%) hue-rotate(${this.state.hueRotate}deg) invert(${this.state.invert}%) saturate(${this.state.saturate}%) opacity(${this.state.opacity}%) sepia(${this.state.sepia}%)`;
        return (
            filters
            )
    }
    filterPreview = () => {
        return (
            <Box sx={{ p: { sm: 3 }, display: "flex", width: "100%", backgroundColor: "rgba(0,0,0,.5)", height: "inherit" }}>
                <img
                    src={this.state.filteredB64}
                    width="100%"
                    style={{
                        backgroundColor: "#333",
                        filter: this.computeFilters(),
                        marginBottom: "auto",
                        maxWidth: 600,
                        objectFit: "cover",
                        marginLeft: "auto",
                        marginRight:"auto"

                    }} />
            </Box>
        )
    }
    colorPicker = () => {
        return (
            <ModalDialog
                dark={this.props.dark}
                show={this.state.showColorPicker}
                title="Choose Background Color"
                handleClose={() => this.setState({ showColorPicker: false })}
                saveLabel="Select Color"
                handleSave={this.handlePickColor}
                handleDelete={() => this.setState({ showColorFromImagePicker: true })}
                deleteLabel="From Image"
                fullWidth={false}
                maxWidth="xs"
                noSidePad
                containedButton
                fullHeight
            >
                <HexColorPicker color={this.state.backgroundColor} onChange={color => this.setState({ backgroundColor: color })} />
                <Box display="flex" sx={{ width: "100%", p: 2 }}>
                    <Typography sx={{ color: theme => this.props.dark ? theme.palette.common.white : theme.palette.common.black, my: "auto", mr: 1 }}>Color</Typography>
                    <HexColorInput color={this.state.backgroundColor} onChange={color => this.setState({ backgroundColor: color })} />
                </Box>
                {this.colorFromImagePicker()}
            </ModalDialog>
        )
    }
    handleSelectColorFromImageClick = async (event) => {
        var img = await createImage(this.props.src);
        //img.setAttribute('crossOrigin', 'anonymous') 
        console.log(img);
        var canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        canvas.getContext('2d').drawImage(img, 0, 0, img.width, img.height);
        var pixelData = canvas.getContext('2d').getImageData(event.offsetX, event.offsetY, 1, 1).data;
        if (pixelData && pixelData.length === 4) {
            const rgbaColor = `rgba(${pixelData[0]},${pixelData[1]},${pixelData[2]},${pixelData[3]})`;
            this.setState({ backgroundColor: rgbaColor, showColorFromImagePicker: false });
        }
    }
    handleEyedropperChange = (color) => {
        this.setState({ backgroundColor: color.hex, showColorFromImagePicker: false });
    }
    colorFromImagePicker = () => {
        return (
            <ModalDialog
                dark={this.props.dark}
                show={this.state.showColorFromImagePicker}
                title="Click on Image to Select Color"
                handleClose={() => this.setState({ showColorFromImagePicker: false })}
                fullWidth={false}
                maxWidth="xs"
                noSidePad
                fullHeight
            >
                <Box sx={{ width: 300, height: 300, m:"auto" }} >
                    <img src={this.props.src} width={300} style={{ objectFit: "contain" }} />
                    <EyeDropper
                        onChange={this.handleEyedropperChange}
                        customComponent={({ onClick }) =>
                            <Box sx={{ width: "100%", p:2 }}>
                                <Button size="small" variant="contained" color="secondary" onClick={onClick} sx={{ width:"100%", height: "32px", m: "auto", color: this.props.dark ? "#eee" : "#eee" }} >Choose Color</Button>
                            </Box>
                        }
                    />
                </Box>
            </ModalDialog>
        )
    }
    filterPage = () => {
        return (
            <Box display="flex" sx={{ height:"inherit", width: "100%", borderTop: this.props.dark ? "none" : "none" }}>
                <Stack sx={{ width: "100%", height: "inherit" }}>
                    <Box display="flex" sx={{ background: this.props.dark && "#000", width: "100%", p: 1 }}>
                        <Tooltip title="Back to Crop and Orientation Settings">
                            <IconButton  color="secondary" sx={{ background: theme=> theme.palette.secondary.main, color: "white" }} onClick={() => this.setState({ page: 0 })}>
                                <ArrowBackIcon sx={{ fontSize: "1.1rem" }} />
                            </IconButton>
                        </Tooltip>

                        <Box sx={{  flexGrow: 1 }} />
                        <Button size="small" variant="contained" color="secondary" onClick={this.handleSaveEdit} sx={{width:"120px", height: "32px", m: "auto",  color: this.props.dark ? "#eee" : "#eee" }} >Save Edits</Button>
                        <Box sx={{ flexGrow: 1 }} />
                    </Box>
                    {this.filterPreview()}
                </Stack>
                {this.filterControls()}
            </Box>
            )
    }
    filterControls = () => {
        return (
            <Stack sx={{ width: { xs: "96px", sm: "156px" }, px: { xs: 1.5, sm: 0 }, backgroundColor: this.props.dark ? "#000" : "unset", borderLeft: this.props.dark ? "1px solid #222" : "1px solid #ddd" }}>
                <Box display="flex" sx={{ width: "100%", justifyContent:"center", mb:1 }}>
                    <Typography gutterBottom sx={{ my: 0.5, textAlign: "center", fontWeight: 600, fontSize: "1rem", color: theme => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.black }}>
                        Filters
                    </Typography>
                    <Tooltip title="Reset Filters">
                        <IconButton variant="contained" size="small" color="secondary" sx={{ color: this.props.dark ? "#ccc" : "#444" }} onClick={this.resetFilters}>
                            <SettingsBackupRestoreIcon sx={{ fontSize: { xs:"1.2rem", sm:"1.5rem"} }}/>
                        </IconButton>
                    </Tooltip>
                </Box>

                {this.blurSlider()}
                {this.brightnessSlider()}
                {this.contrastSlider()}
                {this.grayscaleSlider()}
                {this.hueRotateSlider()}
                {this.invertSlider()}
                {this.saturateSlider()}
                {this.opacitySlider()}
                {this.sepiaSlider()}
            </Stack>
            )
    }
    cropPage = () => {
        return (
            <>
                <Box sx={{ background: this.props.dark && "#000", display: "flex", px: 1, width: "100%", borderTop: this.props.dark && "1px solid #333" }}>

                    {this.zoomSlider()}
                    {this.rotateSlider()}
                    <Box sx={{ display: { xs: "none", sm: "flex" }, width:"40%" }}>
                        {this.aspectSwitch()}
                        {this.restrictPositionSwitch()}
                    </Box>
                    <Box sx={{ flexGrow: 1 }} />
                    {this.state.croppedB64 && this.croppedPreview()}
                    {this.colorPicker()}
                    <Box sx={{ flexGrow: 1 }} />
                    <Tooltip title="Adjust Image Filters">
                        <IconButton color="secondary" sx={{ m: "auto", background: theme => theme.palette.secondary.main, color: "white" }} onClick={this.handleGoToFilters}>
                            <ArrowForwardIcon sx={{ fontSize: "1.1rem" }} />
                        </IconButton>
                    </Tooltip>
                </Box>
                {this.cropperComponent()}
            </>
            )
    }
    content = () => {
        return (
            <Stack sx={{ height: this.props.height ?? "inherit", width:"100%" }}>
                {this.state.page === 0 ? this.cropPage() : this.filterPage()}
            </Stack>
        )
    }

    render() {
        return this.content();
    }

}