import React, { Component } from 'react';

//Custom Components
import { Post } from './Post'

//Material UI
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';

export class PostsContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: null,        
            menuOpen: false,           
        };
    }

    //Event handlers
    handleScroll = (e) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop >= e.target.clientHeight * 0.75;
        if (bottom) {
            console.log("bottom")
            this.props.getMorePosts();
        }
    }

    //render funcs
    loadingSpinner = () => {
        return (
            <Box sx={{ height: !this.props.site && { xs: "calc(100vh - 148px)", sm: "calc(100vh - 156px)", lg: "calc(100vh - 100px)" }, minHeight: this.props.site && "400px", width: "100%", display: 'flex', justifyContent: "center", background: this.props.site ? "transparent" : (theme) => this.props.dark ? theme.palette.backgroundMain.dark : theme.palette.backgroundMain.light, }}>
                <Stack sx={{ marginTop: "auto", marginBottom: "auto", justifyContent: "center", textAlign: "center" }}>
                    <Typography variant="subtitle1" sx={{ fontWeight: 500, marginBottom: "6px", fontSize: ".9rem", color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.dimblack }}>
                        {"Loading Posts..."}
                    </Typography>
                    <CircularProgress color="secondary" style={{ alignSelf: "center" }} />
                </Stack>
            </Box>
            )
    }
    searchingSpinner = () => {
        return (
            <Box sx={{ height: !this.props.site && { xs: "calc(100vh - 93px)", md: "calc(100vh - 101px)" }, minHeight: this.props.site && "400px", width: "100%", display: 'flex', justifyContent: "center", background: this.props.site ? "transparent" : (theme) => this.props.dark ? "#090909" : theme.palette.component3.light, }}>
                <Stack sx={{ marginTop: "auto", marginBottom: "auto", justifyContent: "center", textAlign: "center" }}>
                    <Typography variant="subtitle1" sx={{ fontWeight: 500, marginBottom: "6px", fontSize: ".9rem", color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.dimblack }}>
                        {"Searching..."}
                    </Typography>
                    <CircularProgress color="secondary" style={{ alignSelf: "center" }} />
                </Stack>
            </Box>
        )
    }
    searchNoResultsMessage = () => {
        return (
            <Box sx={{ paddingTop: "80px", textAlign: "center" }}>
                <Typography variant="h5" sx={{ marginBottom: "12px", color: (theme) => this.props.dark ? theme.palette.common.dimwhite : theme.palette.common.black }}>
                    {'Sorry, your search for "' + this.props.searchTerm + '" returned no results.'}
                </Typography>
            </Box>
            )
    }
    posts = () => {
        if (this.props.loaded) {
            return (
                <Box
                    id="postScroller"
                    sx={{
                        background: (theme) => this.props.site ? "unset" : (this.props.dark ? "#050505" : theme.palette.backgroundMain.light),
                        height: !this.props.site && (this.props.dark ? { xs: "calc(100vh - 149px)", sm: "calc(100vh - 157px)", lg: "calc(100vh - 102px)" } : { xs: "calc(100vh - 148px)", sm: "calc(100vh - 156px)", lg:"calc(100vh - 101px)" }) ,
                        position: "relative",
                        minHeight: this.props.site && "400px"
                    }}
                    className="prettyScroll"
                    onScroll={this.handleScroll}
                >
                    <Box sx={{
                        maxWidth: this.props.grid ? "1600px" : { xs: "100%", md: "calc(100% - 100px)", xl: "calc(100% - 160px)" },
                        mx: "auto",
                        px: { xs: 0, sm: 1 },
                        pt: { xs: 0, sm: 1.5 },
                        pb: { xs: 1.5, sm: 1.5 },
                    }}
                    >
                        {(this.props.channel === 100 && this.props.searching)
                            ?
                            this.searchingSpinner() 
                            :
                            ((this.props.channel === 100 && !this.props.hasResults)
                            ?
                            this.searchNoResultsMessage()
                            :
                            <Grid
                                container
                                    spacing={{ xs: 0.5, sm: 0.5 }}
                            >
                                {this.props.posts.map((post, index) => {
                                    return (
                                        <Grid
                                            item
                                            key={"gridfor" + post.id + index}
                                            xs={12}
                                            lg={this.props.grid ? 6 : 4}
                                            sx={{ borderTop: this.props.addDivider ? `2px solid ${this.props.dark ? "#333" : "#ccc"}` : "none" }}
                                        >
                                            <Post
                                                dark={this.props.dark}
                                                channel={this.props.channel}
                                                post={post}
                                                idx={index}
                                                user={this.props.user}
                                                handleDeletePost={this.props.handleDeletePost}
                                                handleEditPost={this.props.handleEditPost}
                                                handleSharePost={this.props.handleSharePost}
                                                handleHidePost={this.props.handleHidePost}
                                                menuOpen={this.props.toggleMenu}
                                                updatePostContent={this.props.updatePostContent}
                                                updateRepostContent={this.props.updateRepostContent}
                                                appIdentifier={this.props.appIdentifier}
                                                updateLastPost={this.props.updateLastPost}
                                                updatePosts={this.props.updatePosts}
                                                setSelectedPost={this.props.setSelectedPost}
                                                userList={this.props.userList}
                                                showInfo={this.props.showInfo}
                                                width={this.props.width}
                                                height={this.props.height}
                                            />
                                        </Grid>
                                    )
                                })}
                            </Grid>
                            )}

                    </Box>
                </Box>
            )
        }
        else {
            if (this.props.searching) {
                return this.searchingSpinner();
            }
            return this.loadingSpinner();
        }    
    }

    //main component render
    render() {
        return this.posts();
    }
}

