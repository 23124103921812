import React, { Component } from 'react';

//Custom Modules
import { ProfileImageUploader } from './ProfileImageUploader';
import { ModalDialog } from '../Utilities/ModalDialog';
import { Section } from '../Utilities/Section';
import { SectionGrid } from '../Utilities/SectionGrid';
import { SectionFieldGroup } from '../Utilities/SectionFieldGroup';
import { SelectList } from '../Utilities/SelectList';
import { DateSelector } from '../Utilities/DateSelector';
import { TextInput } from '../Utilities/TextInput';
import { PublicAttribute } from '../Utilities/PublicAttribute';
import { Input } from '../Utilities/Input';
//Material UI
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import Tooltip from '@mui/material/Tooltip';

//Data Services
import { saveName, saveHeadline, saveImportantDate, saveTrait } from '../../DataServices/UserService'

export class ProfileBasic extends Component {
    constructor(props) {
        super(props);
        this.state = {
            //nameChanged: false,
            //firstName: this.props.profile.firstName,
            //middleName: this.props.profile.middleName,
            //lastName: this.props.profile.lastName,
            //headline: this.props.profile.demographics.personalHeadline,
            dob: this.props.profile.importantDates.dob,
            wedding: this.props.profile.importantDates.weddingAnniversary,
            job: this.props.profile.importantDates.jobAnniversary,
            showPhotoUploader: false,
            showConfirmDelete: false
        }
    }

    //event handlers
    handleUpdateProfile = (b64) => {
        this.setState({ showPhotoUploader: false });
        this.props.handleUpdateProfile(b64);
    }
    //hasNameChanged = () => {
    //    var first = document.getElementById("firstName").value;
    //    var middle = document.getElementById("middleName").value;
    //    var last = document.getElementById("lastName").value;
    //    var headline = document.getElementById("headline").value;
    //    if (first !== this.props.profile.firstName ||
    //        middle !== this.props.profile.middleName ||
    //        last !== this.props.profile.lastName ||
    //        headline !== this.props.profile.demographics.personalHeadline) {
    //        this.setState({
    //            nameChanged: true,
    //            firstName: first,
    //            middleName: middle,
    //            lastName: last,
    //            headline: headline
    //        });
    //    }
    //    else {
    //        this.setState({
    //            nameChanged: false,
    //            firstName: first,
    //            middleName: middle,
    //            lastName: last,
    //            headline: headline
    //        });
    //    }
    //}
    //saveName = async () => {
    //    await saveName(this.state.firstName, this.state.middleName, this.state.lastName);
    //    await saveHeadline(this.state.headline);
    //    this.setState({ nameChanged: false });
    //}
    handleMyDOBChange = async (e) => {
        this.props.updateMyDob(e);
        await saveImportantDate(0, e);
    }
    handleWeddingChange = async (e) => {
        this.props.updateMyWeddingAnniversary(e);
        await saveImportantDate(1, e);
    }
    handleJobChange = async (e) => {
        this.props.updateMyJobAnniversary(e);
        await saveImportantDate(2, e);
    }
    handleTypeChange = (e) => {
        this.setState({ typeValue: e.target.value });
    }
    handleGenderChange = (val) => {
        this.setState({ genderValue: val});
    }
    handleDOBChange = (e) => {
        this.setState({ dobValue: new Date(e).toLocaleDateString() });
    }
    handleSexChange = async (val) => {
        this.props.handlePersonalChange("sex", val);
        await saveTrait(2, val);
    }
    handleGenderChange = async (val) => {
        this.props.handlePersonalChange("gender",val);
        await saveTrait(0, val);
    }
    handleRelationshipChange = async (val) => {
        this.props.handlePersonalChange("relationship",val);
        await saveTrait(1, val);
    }
    handlePoliticsChange = async (val) => {
        this.props.handlePersonalChange("politics", val);
        await saveTrait(3, val);
    }
    handleReligionChange = async (val) => {
        this.props.handlePersonalChange("religion",val);
        await saveTrait(4, val);
    }
    handleSocialChange = async (val) => {
        this.props.handlePersonalChange("social", val);
        await saveTrait(5, val);
    }


    handleSaveName = async () => {
        const first = document.getElementById("fieldFirst Name").value;
        const middle = document.getElementById("fieldMiddle Name").value;
        const last = document.getElementById("fieldLast Name").value;
        await saveName(first, middle, last);
    }
    handleSaveHeadline = async (val) => {
        await saveHeadline(val);
    }
    handleFirstChange = (val) => {
        const profile = this.props.profile;
        profile.firstName = val;
        this.props.updateData(profile);
    }
    handleMiddleChange = (val) => {
        const profile = this.props.profile;
        profile.middleName = val;
        this.props.updateData(profile);
    }
    handleLastChange = (val) => {
        const profile = this.props.profile;
        profile.lastName = val;
        this.props.updateData(profile);
    }
    handleHeadlineChange = (val) => {
        const profile = this.props.profile;
        profile.demographics.personalHeadline = val;
        this.props.updateData(profile);
    }
    render() {

        const ProfilePic = () => {
            return (
                <Section dark={this.props.dark} title="Profile Photo" >
                    <div style={{ textAlign: "center" }}>
                        <Tooltip title="Click to add/update profile photo">
                            {this.props.profile.imageB64 ?
                                <Paper
                                    component="img"
                                    elevation={this.props.dark ? 0 : 4}
                                    src={"data:image/png;base64, " + (this.props.profile.imageB64 ? this.props.profile.imageB64 : this.props.profile.avatarB64)}
                                    style={{ height: "230px", width: "230px", objectFit: "cover", borderRadius: "6px", cursor: "pointer" }}
                                    onClick={() => this.setState({ showPhotoUploader: true })}
                                />
                                :
                                <Button variant="contained" style={{ height: "230px", width: "230px", backgroundColor: this.props.dark ? "#444" : "#bbb", fontSize: "76px" }} onClick={() => this.setState({ showPhotoUploader: true })}>
                                    <InsertPhotoIcon sx={{ color: (theme) => this.props.dark ? "#bbb" : "#444" }} style={{ fontSize: "76px" }} />
                                </Button>
                            }
                        </Tooltip>

                    </div>
                </Section>)
        } 

        const ImportantDates = () => {
            return (
                <Section dark={this.props.dark} title="Important Dates" >
                    <SectionFieldGroup dark={this.props.dark}>
                        <Box display="flex" sx={{width:"100%"}}>
                        <DateSelector
                            dark={this.props.dark}
                            label="My Date of Birth"
                            value={this.props.profile.importantDates.dob !== "0001-01-01T00:00:00" ? this.props.profile.importantDates.dob : null}
                            handleChange={this.handleMyDOBChange}
                            />
                            <PublicAttribute {...this.props} attr={"dob"} />
                        </Box>

                        <Box display="flex" sx={{ width: "100%" }}>
                        <DateSelector
                            dark={this.props.dark}
                            label="My Wedding Anniversary"
                            value={this.props.profile.importantDates.weddingAnniversary !== "0001-01-01T00:00:00" ? this.props.profile.importantDates.weddingAnniversary : null}
                            handleChange={this.handleWeddingChange}
                            />
                            <PublicAttribute {...this.props} attr={"weddinganniversary"} />
                        </Box>
                        <Box display="flex" sx={{ width: "100%" }}>
                        <DateSelector
                            dark={this.props.dark}
                            label="My Job Anniversary"
                            value={this.props.profile.importantDates.jobAnniversary !== "0001-01-01T00:00:00" ? this.props.profile.importantDates.jobAnniversary : null}
                            handleChange={this.handleJobChange}
                        />
                            <PublicAttribute {...this.props} attr={"jobanniversary"} />
                        </Box>
                    </SectionFieldGroup>
                </Section>)
        } 

        const NameHeadline = () => {
            const fields = [
                { name: "first", type: "text", label: "First Name", value: this.props.profile.firstName, onChange: this.handleFirstChange, onBlur: this.handleSaveName, maxLength: 100 },
                { name: "middle", type: "text", label: "Middle Name", value: this.props.profile.middleName, onChange: this.handleMiddleChange, onBlur: this.handleSaveName, maxLength: 100 },
                { name: "last", type: "text", label: "Last Name", value: this.props.profile.lastName, onChange: this.handleLastChange, onBlur: this.handleSaveName, maxLength: 100 },
                { name: "headline", type: "text", label: "Personal Headline", value: this.props.profile.demographics.personalHeadline, onChange: this.handleHeadlineChange, onBlur: this.handleSaveHeadline, maxLength: 250 },
            ];
            return (
                <Section dark={this.props.dark} title="Name and Headline" handleSave={this.saveName} enableSave={this.state.nameChanged}>
                    <SectionFieldGroup dark={this.props.dark}>
                        {fields.map((item) => {
                            return (
                                <Box display="flex" sx={{ width: "100%" }}>
                                    <Input
                                        key={item.name}
                                        type={item.type}
                                        dark={this.props.dark}
                                        inputID={item.name}
                                        label={item.label}
                                        value={item.value}
                                        onBlur={item.onBlur}
                                        onChange={item.onChange}                                    
                                        maxLength={item.maxLength}                                   
                                        helpText={item.help}
                                    />
                                    <PublicAttribute {...this.props} attr={item.name} />
                                </Box>
                            )
                        })}
                    </SectionFieldGroup>
                </Section>)
        } 

        const PersonalInfo = () => {
            const fields = [
                { name: "gender", type: "select", label: "My Gender", value: this.props.profile.demographics.gender, onChange: this.handleGenderChange, items: this.props.profile.genderList, help: "" },
                { name: "relationshipstatus", type: "select", label: "My Relationship Status", value: this.props.profile.demographics.relationshipStatus, onChange: this.handleRelationshipChange, items: this.props.profile.relationshipStatusList, help: "" },
                { name: "sexualPreference", type: "select", label: "My Sexual/Relationship Preference", value: this.props.profile.demographics.sexualPreference, onChange: this.handleSexChange, items: this.props.profile.sexPrefList, help: "" },
                { name: "socialtype", type: "select", label: "My Social Type", value: this.props.profile.demographics.socialType, onChange: this.handleSocialChange, items: this.props.profile.socialList, help: "" },
                { name: "politics", type: "select", label: "My Politics", value: this.props.profile.demographics.politicalSlant, onChange: this.handlePoliticsChange, items: this.props.profile.politicsList, help: "" },
                { name: "religion", type: "select", label: "My Faith/Spirituality", value: this.props.profile.demographics.religiousViews, onChange: this.handleReligionChange, items: this.props.profile.religionList, help: "" },
            ];
            return (
                <Section dark={this.props.dark} title="Personal Information">
                    <SectionFieldGroup dark={this.props.dark}>
                        {fields.map((item) => {
                            return (
                                <Box display="flex" sx={{ width: "100%" }}>
                                    <Input
                                        key={item.name}
                                        type={item.type}
                                        dark={this.props.dark}
                                        label={item.label}
                                        value={item.value}
                                        onChange={item.onChange}
                                        listItems={item.items}
                                        helpText={item.help}
                                    />
                                    <PublicAttribute {...this.props} attr={item.name} />
                                </Box>
                                )
                        }) }
                    </SectionFieldGroup>
                </Section>)
        } 

        const profileImageUploader = () => {
            return (
                <ProfileImageUploader
                    {...this.props}
                    open={this.state.showPhotoUploader}
                    handleSave={this.handleUpdateProfile}
                    handleClose={() => this.setState({ showPhotoUploader: false })}
                    fullScreen={this.props.fullScreen}
  
                />
            )
        }

        const deleteConfirmation = () => {
            return (
                <ModalDialog {...this.props} show={this.state.showConfirmDelete}
                    title="Confirm Deletion"
                    subTitle={this.state.deleteType + " will be deleted.  Proceed?"}
                    handleClose={() => this.setState({ showConfirmDelete: false })}
                    handleSave={this.handleDelete}
                    fullWidth={false}
                    maxWidth="sm"
                    saveLabel="Delete" />
            )
        } 
       
        return (
            <>
                <SectionGrid key="pbasic" ckey="profilebasic" xs={12} md={6} height="calc(100% - 40px)">
                    {ProfilePic()}
                    {NameHeadline()}
                    {this.props.appIdentifier === 'LS' && ImportantDates()}
                    {this.props.appIdentifier === 'LS' && PersonalInfo()}
                </SectionGrid>
                {profileImageUploader()}
                {deleteConfirmation()}
            </>
        );
    }
}
